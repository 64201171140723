import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import Login from "../pages/Auth/Login/Login";
import { connect } from "react-redux";
import axios from "axios";
import { API_URL } from "../constants/constants";
import { useDispatch } from "react-redux";
import wishListService from "../services/wishlist-service";
import orderServices from "../services/order-services";
import {
  searchProductByCategory,
  getCartData,
  cartDataResponse,
  getWishlistData,
  wishlistDataResponse,
} from "../redux/actions/catagoryAction";
import cartService from "../services/cart-service";
import authService from "../services/auth.service";
import { toast } from "react-toastify";
import { login } from "../redux/actions/auth";

const Header = (props) => {
  const [optionList, setOptionList] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const [selectedCatagory, setSelectedCatagory] = useState("");
  const [category, setCatagory] = useState("");
  const [userT, setUserT] = useState(null);
  const [userLog, setUserLog] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isModal, setIsModal] = useState(true);
  const [show, setShow] = useState(true);

  const ref = useRef(null);

  const [user, setUserDetails] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [optionItem, setOptionItem] = useState([]);
  const { pathname } = useLocation();

  const styleIfFalse = {
    visibility: "hidden",
  };

  const styleIfTrue = {
    visibility: "visible",
  };

  const dropdowns = () => {
    const lists = document.querySelectorAll(".lists");
    const dropdownList = document.querySelectorAll("#dropdownList");
    lists.forEach((element) => {
      element.addEventListener("mouseover", (event) => {
        element.classList.add("selected");
        if (element.classList.contains("selected")) {
          lists.forEach((innerList) => {
            event.preventDefault();
            innerList.classList.remove("selected");
          });
          element.classList.add("selected");
          event.preventDefault();
        } else {
          element.classList.remove("selected");
        }
      });
    });
    dropdownList.addEventListener("mouseout", () => {
      lists.forEach((innerList) => {
        innerList.classList.remove("selected");
      });
    });
  };

  const dynamicStyle = isModal ? styleIfTrue : styleIfFalse;

  const showModel = () => {
    setIsModal(true);
  };

  const fetchData = () => {
    axios
      .get(API_URL + "/admin/category/getCategories")
      // .post(API_URL + "/admin/category/backendGetCategories")
      .then((response) => {
        const { data } = response;
        setCatagory(data?.categories);
        if (response.status === 200) {
          setOptionList(data);
          setOptionItem(data);
          setTimeout(dropdowns, 2000);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    let userId = localStorage.getItem("userId");
    setUserLog(userId);
    let userType = localStorage.getItem("userType");
    setUserT(userType);
    if (userId) {
      orderServices
        .getUserDetailById(userId)
        .then((res) => {
          if (res?.status === 200) {
            let uType = res?.userDetails[0]?.userId?.userType;
            localStorage.setItem("userType", uType);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const getCartData = async () => {
    props.getCartData();
    await cartService
      .getProductFromCart()
      .then((response) => {
        if (response?.status === 200) {
          const responseData = response?.cart?.map((item) => {
            return {
              id: item.productId,
              name: item.productName,
              brand: item.brand,
              description: item.description,
              discountAmount: item.discountAmount,
              discountPercent: item.discountPercent,
              discountedPrice: item.discountedPrice,
              image: item.image,
              price: item.price,
              quantity: item.quantity,
              length: item?.length ?? 0,
              width: item?.width ?? 0,
              height: item?.height ?? 0,
              weight: item?.weight ?? 0,
            };
          });

          props.cartDataResponse(responseData);
        }
      })
      .catch((error) => console.log(error));
  };

  // const getProductByCatagories = (e) => {
  //     setSelected()
  //     axios
  //         .get(`https://PpmRacingProducts-QA.chetu.com/api/admin/product/getProductsByCategory/${e}`)
  //         .then((response) => {
  //             const { data } = response;
  //             if (response.status === 200) {
  //                 setProductCatagory(data)
  //                 setProductDropdownHidden(false)
  //             }
  //         })
  //         .catch((error) => console.log(error));

  // }

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...user,
      [name]: value,
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let userId = localStorage.getItem("userId");
    const postData = {};
    postData.userId = userId;
    if (!searchedText) {
      toast.error("Please write something to search");
      return;
    }
    const trimText = searchedText.trim();
    localStorage.setItem("searchString", trimText);
    postData.searchString = trimText;
    if (selectedCatagory) {
      postData.categoryId = selectedCatagory;
    }

    axios
      .post(API_URL + "/admin/product/searchProductByName", postData)
      .then((response) => {
        const { data } = response;

        if (response.status === 200) {
          dispatch(
            searchProductByCategory({
              searchedProduct: response.data,
              searchString: postData,
            })
          );
          setSearchedText("");
          navigate("/productListing");
          setIsModal(false);
        }
      })
      .catch((error) => console.log(error));
  };

  const getCartNumber = () => {
    const cartData = props.cartData || [];
    let cartNumber = 0;
    cartData.map(({ quantity }) => {
      cartNumber = cartNumber + quantity;
    });
    return cartNumber;
  };
  const getWishlistNumber = () => {
    const token = JSON.parse(localStorage.getItem("userInfo"));
    if (!token) {
      return 0;
    }
    const wishData = props.wishlistData || [];
    let wishNumber;
    wishNumber = wishData.length;
    return wishNumber;
  };

  const getWishlistData = async () => {
    props.getWishlistData();
    await wishListService
      .getProductFromWishlist()
      .then((response) => {
        if (response?.status === 200) {
          const responseData = response?.wishlist?.map((item) => {
            return {
              id: item.productId,
              name: item.productName,
              brand: item.brand,
              image: item.image,
              price: item.price,
            };
          });
          props.wishlistDataResponse(responseData);
        }
      })
      .catch((error) => console.log(error));
  };

  const visitorCount = async () => {
    const response = await axios.get(API_URL);
  };

  useEffect(() => {
    fetchData();
    // getWishlistData();
  }, []);

  useEffect(() => {
    getCartData();
    getWishlistData();
    visitorCount();
  }, []);

  const validateForm = (values) => {
    const error = {};
    const regex = /^[^\s+@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      error.email = "Email is required";
    } else if (!regex.test(values.email)) {
      error.email = "Please enter a valid email address";
    }
    if (!values.password) {
      error.password = "Password is required";
    }
    return error;
  };

  const loginHandler = (e) => {
    setFormErrors({});
    e.preventDefault();

    const validate = validateForm(user);

    if (validate.email || validate.password) {
      setFormErrors(validate);
      return;
    }
    setIsSubmit(true);
    // setLoading(true)
    authService
      .login(user)
      .then((response) => {
        localStorage.setItem("userType", response?.userData?.userType);
        if (response.status === 200) {
          swal("Success!", response.message, "success");
          if (pathname == "/") {
            window.location.reload();
          }
          navigate("/");
          setUserDetails({ ...user, email: "", password: "" });
          dispatch(login(response.userData));
        } else if (response.status === 401) {
          swal("Error!", response.message, "error");
        } else if (response.status === 404) {
          swal("Error!", response.message, "error");
        } else {
          swal("Error!", response.message, "error");
        }
      })
      .catch((err) => {
        swal("Error!", "Something went wrong!", "error");
      });
  };

  const hideDiv = () => {
    setIsModal(false);
  };
  
  const hideBrands = () => {
    setIsModal(false);
   location.reload();
  };

  const currentUrl = location.pathname;
  useEffect(() => {
    const catPageUrl = currentUrl.includes("categoryDetail");
    const productPageUrl = currentUrl.includes("products");
    const brandPageUrl = currentUrl.includes("brandDetail");
    const subCategoryDetail = currentUrl.includes("subCategoryDetail");
    const productDetail = currentUrl.includes("productDetail");
    const producSearch = currentUrl.includes("productListing");
    setTimeout(() => {
      if (!catPageUrl && !productDetail) {
        localStorage.removeItem("paginationArrCat");
        localStorage.removeItem("secondLastPageCat");
        localStorage.removeItem("isVisited");
      }
      if (!productPageUrl && !productDetail) {
        localStorage.removeItem("paginationArr");
        localStorage.removeItem("secondLastPage");
      }
      if (!brandPageUrl && !productDetail) {
        localStorage.removeItem("paginationArrBrand");
        localStorage.removeItem("secondLastPageBrand");
        localStorage.removeItem("isBrandVisited");
      }
      if (!subCategoryDetail && !productDetail) {
        localStorage.removeItem("paginationArrSubCat");
        localStorage.removeItem("secondLastPageSubCat");
        localStorage.removeItem("isSubCatVisited");
      }
      if (!producSearch && !productDetail) {
        localStorage.removeItem("searchString");
        localStorage.removeItem("ProductListingArr");
        localStorage.removeItem("ProductListingPageCount");
        localStorage.removeItem("isProductListingVisited");
      }
    }, 1500);
  }, [currentUrl]);

  return (
    <header>
      <div className="headerTop"></div>
      <div className="headerMiddle">
        <div className="container-fluid">
          <div className="row">
            <div className="pdt d-flex justify-content-between align-items-center">
              <div className="col-lg-3 ">
                <div className="logo">
                  <Link to="/">
                    <img src="../images/newppm_logo.svg" />
                  </Link>
                  {/* <a href="#"><img src="images/headerLogo.png" /></a> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ppmSearch">
                  <div className="searchBar">
                    <div className="searchInput">
                      <input
                        placeholder="Find Your Product "
                        onChange={(e) => setSearchedText(e.target.value)}
                        value={searchedText}
                      />
                    </div>
                    {/* <div className="allCategories">
                    <select
                      disabled={false}
                      value={selectedCatagory}
                      onChange={(e) => setSelectedCatagory(e.target.value)}
                    >
                      <option value="">All Categories </option>
                      {optionList?.categories?.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div> */}
                    <button className="searchBtn" onClick={handleSearch}>
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="container">
                  <div className="row">
                    <div className="middleRight">
                      {props.isLoggedIn !== true && (
                        <div className="dropdown">
                          <div
                            className="user dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="fa-solid fa-user"></i>
                          </div>
                          <div
                            className="dropdown-menu userDropdown"
                            id="userDropdown1"
                          >
                            <Login />
                          </div>
                        </div>
                      )}
                      <NavLink
                        className="middleRightMenu"
                        to="/wishlist"
                        title="Wishlist"
                      >
                        <span className="cartNum">{getWishlistNumber()}</span>
                        <i className="fa-solid fa-heart"></i>
                      </NavLink>
                      <NavLink
                        className="middleRightMenu"
                        to="/cart"
                        title="Cart"
                      >
                        <span className="cartNum">{getCartNumber()}</span>{" "}
                        <i className="fa-solid fa-cart-shopping"></i>
                      </NavLink>

                      {props.isLoggedIn === true && (
                        <>
                          <NavLink
                            className="middleRightMenu"
                            to="/my-account"
                            style={{ backgroundColor: userT }}
                            title="Profile"
                          >
                            <i className="fas fa-user-circle"></i>
                          </NavLink>
                          {/* <button className="middleRightMenu" onClick={() => authService.logout()}><i className="fa-solid fa-sign-out"></i></button> */}
                        </>
                      )}

                      <div
                        className="middleRightMenu hide navbar-toggler"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasDarkNavbar"
                        aria-controls="offcanvasDarkNavbar"
                        onClick={showModel}
                      >
                        <span>
                          <i className="fa-solid fa-bars"></i>
                        </span>
                      </div>

                      <div>
                        <div
                          className="offcanvas offcanvas-end text-bg-dark"
                          tabIndex="-1"
                          id="offcanvasDarkNavbar"
                          aria-labelledby="offcanvasDarkNavbarLabel"
                          style={dynamicStyle}
                        >
                          <>
                            <div className="offcanvas-header" id="hideDiv">
                              {/* <h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel">Dark offcanvas</h5> */}

                              <div className="searchBar2">
                                <form
                                  onSubmit={handleSearch}
                                  className="d-flex"
                                >
                                  <div className="searchInput">
                                    <input
                                      placeholder="Find Your Product in mobile view"
                                      type="search"
                                      autoComplete="off"
                                      onChange={(e) =>
                                        setSearchedText(e.target.value)
                                      }
                                      value={searchedText}
                                    />
                                  </div>
                                  <button className="searchBtn">
                                    <i className="fa-solid fa-magnifying-glass" />
                                  </button>
                                </form>
                              </div>

                              <button
                                type="button"
                                className="btn-close btn-close-white"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              />
                            </div>
                            <div className="offcanvas-body">
                              <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                <li className="nav-item dropdown">
                                  <div
                                    className="d-flex justify-content-between align-items-center dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <a
                                      className="nav-link w-100"
                                      href="#"
                                      role="button"
                                    >
                                      Shop By Categories
                                    </a>
                                    <span>
                                      <i className="fa-solid fa-angle-down" />
                                    </span>
                                  </div>

                                  <ul className="dropdown-menu dropdown-menu-dark">
                                    {Array.isArray(category)
                                      ? category.map((x) => {
                                          return (
                                            <>
                                              <li>
                                                {/* <Link
                                            className="dropdown-item"
                                            to={`/categoryDetail/${x._id}`}
                                            onClick={hideDiv}
                                          > */}
                                                <>
                                                  <li className="nav-item dropdownsub">
                                                    <div
                                                      className="d-flex justify-content-between align-items-center dropdown-toggle"
                                                      role="button"
                                                      id="dropdownMenuButton2"
                                                      data-bs-toggle="dropdown"
                                                      aria-expanded="false"
                                                      ref={ref}
                                                    >
                                                      <a
                                                        className="nav-link w-100"
                                                        href={`/categoryDetail/${x._id}`}
                                                      >
                                                        {x?.name}
                                                      </a>
                                                      <span>
                                                        <i className="fa-solid fa-angle-down" />
                                                      </span>
                                                    </div>
                                                    <ul
                                                      className="dropdown-menu-dark"
                                                      id="dropdownList"
                                                      aria-labelledby="dropdownMenuButton2"
                                                    >
                                                      {x?.subCategory
                                                        ?.sort((a, b) => {
                                                          if (
                                                            a.subCategoryId
                                                              ?.name <
                                                            b.subCategoryId
                                                              ?.name
                                                          ) {
                                                            return -1;
                                                          }
                                                          if (
                                                            a.subCategoryId
                                                              ?.name >
                                                            b.subCategoryId
                                                              ?.name
                                                          ) {
                                                            return 1;
                                                          }
                                                          return 0;
                                                        })
                                                        .map((subCat) => {
                                                          // console.log(
                                                          //   "subItem ",
                                                          //   subCat
                                                          // );
                                                          return (
                                                            <li
                                                              onClick={hideDiv}
                                                            >
                                                              <a
                                                                href={`/subCategoryDetail/${subCat?.subCategoryId}`}
                                                                className="dropdown-item"
                                                              >
                                                                {
                                                                  subCat?.subCategory
                                                                }
                                                              </a>
                                                            </li>
                                                          );
                                                        })}
                                                    </ul>
                                                  </li>
                                                </>
                                                {/* </Link> */}
                                              </li>
                                            </>
                                          );
                                        })
                                      : null}
                                  </ul>
                                </li>

                                <li className="nav-item" onClick={hideBrands}>
                                  <NavLink
                                    className="nav-link active"
                                    to="/brands"
                                  >
                                    Brands
                                  </NavLink>
                                </li>
                                {/* <li className="nav-item">
                          <a className="nav-link" href="catalogCategories.html">
                            Catalog Categories
                          </a>
                        </li> */}
                                <li className="nav-item dropdown">
                                  <div
                                    className="d-flex justify-content-between align-items-center dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <a
                                      className="nav-link w-100"
                                      href="#"
                                      role="button"
                                    >
                                      Tech Resources
                                    </a>
                                    <span>
                                      <i className="fa-solid fa-angle-down" />
                                    </span>
                                  </div>
                                  <ul className="dropdown-menu dropdown-menu-dark">
                                    <li onClick={hideDiv}>
                                      <Link
                                        to="/whitepages"
                                        className="dropdown-item"
                                      >
                                        PDF Whitepages
                                      </Link>
                                    </li>
                                    <li onClick={hideDiv}>
                                      <Link
                                        to="/videos"
                                        className="dropdown-item"
                                      >
                                        How To's & Videos
                                      </Link>
                                    </li>
                                    <li onClick={hideDiv}>
                                      <Link
                                        to="/clickableSchmatics"
                                        className="dropdown-item"
                                      >
                                        Clickable Schematics
                                      </Link>
                                    </li>
                                    <li onClick={hideDiv}>
                                      <Link
                                        to="/chassisMap"
                                        className="dropdown-item"
                                      >
                                        Chassis Maps
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                                <li className="nav-item" onClick={hideBrands}>
                                  <NavLink
                                    to="/distributorTools"
                                    className="nav-link"
                                  >
                                    Distributor Tools
                                  </NavLink>
                                </li>
                                {/* <li className="nav-item">
                          <a className="nav-link" href="#">
                            Promos
                          </a>
                        </li> */}
                                {!userLog && (
                                  <li className="nav-item dropdown">
                                    <div
                                      className="d-flex justify-content-between align-items-center dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <a
                                        className="nav-link w-100"
                                        href="#"
                                        role="button"
                                      >
                                        Login / Register
                                      </a>
                                      <span>
                                        <i className="fa-solid fa-angle-down" />
                                      </span>
                                    </div>
                                    <ul className="dropdown-menu dropdown-menu-dark p-3">
                                      <h2 className="text-center mb-3">
                                        SIGN IN
                                      </h2>

                                      <form onSubmit={loginHandler}>
                                        <div className="row">
                                          <div className="form-floating mb-3">
                                            <input
                                              type="email"
                                              className="form-control"
                                              id="email"
                                              placeholder="Enter email"
                                              name="email"
                                              onChange={changeHandler}
                                              value={user.email
                                                .trim()
                                                .toLowerCase()}
                                            />
                                            <label htmlFor="floatingInput">
                                              Email
                                            </label>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="form-floating mb-3">
                                            <input
                                              type="password"
                                              name="password"
                                              className="form-control"
                                              id="floatingInput"
                                              placeholder="Enter password"
                                              onChange={changeHandler}
                                              value={user.password}
                                            />
                                            <label htmlFor="floatingInput">
                                              Password
                                            </label>
                                          </div>
                                        </div>

                                        <div className="fpLink">
                                          <NavLink to="/forgotPassword">
                                            Forgot Password?
                                          </NavLink>
                                        </div>

                                        <div className="text-center">
                                          <input
                                            type="submit"
                                            className="ppmBtn mb-3"
                                          />
                                        </div>
                                      </form>

                                      {/* <p className="dText">OR</p>
                            <div className="DsocialLoginBtn mb-4">
                              <a href="#">
                                <div className="me-3">
                                  <img src="images/facebook.png" />
                                </div>
                                <div>Register with Facebook</div>
                              </a>
                            </div>
                            <div className="DsocialLoginBtn">
                              <a href="#">
                                <div className="me-3">
                                  <img src="images/google.png" />
                                </div>
                                <div>Register with Gmail</div>
                              </a>
                            </div> */}
                                      <div className="newRegister">
                                        <span>New Member?</span>

                                        <NavLink to="/register">
                                          Register Now
                                        </NavLink>
                                      </div>
                                    </ul>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                    <div className="headerTopRight">
                      <div className="followUs">
                        <span className="follow-text">Follow Us</span>
                        <a
                          href="https://www.facebook.com/ppmracingproducts"
                          target="_blank"
                        >
                          <i
                            style={{ color: "black" }}
                            className="fa-brands fa-facebook-f"
                          ></i>
                        </a>
                        <a
                          href="https://www.instagram.com/team_ppm/"
                          target="_blank"
                        >
                          <i
                            style={{ color: "black" }}
                            className="fa-brands fa-instagram  "
                          ></i>
                        </a>
                        <a href="https://twitter.com/Team_PPM" target="_blank">
                          {/* <i className="fa-brands fa-twitter"></i> */}
                          {/* <i className="fa-brands fa-x-twitter"></i> */}

                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 1200 1227"
                            fill="none"
                            style={{ color: "black" }}
                          >
                            <g clip-path="url(#clip0_1_2)">
                              <path
                                d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                                fill="black"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1_2">
                                <rect width="1200" height="1227" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </a>
                        <a
                          href="https://www.youtube.com/channel/UCbKyStptRvf3Bq3sRTFyRrA"
                          target="_blank"
                        >
                          <i
                            style={{ color: "black" }}
                            className="fa-brands fa-youtube"
                          ></i>
                        </a>
                      </div>
                      <div className="contact-no">
                        TollFree - (877)-560-0619
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="headerBottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <div className="shopBy">
                <div ux-btn>
                  <span>
                    <i className="fa-solid fa-list-ul"></i>
                  </span>
                  <span>&nbsp; shop by</span>
                </div>

                <div className="dropdownContent ux-f1-dropdown menuDrop-down">
                  <ul className="dropdown-1" id="dropdownList">
                    {optionList?.categories?.map((item, index) => (
                      <>
                        <li key={index} className="lists">
                          <Link to={`/categoryDetail/${item._id}`}>
                            {item.name}
                            <span>
                              <i className="fa-solid fa-angle-right"></i>
                            </span>
                          </Link>
                          <div className="dropdownContentSubCategory ux-f2-dropdown">
                            <ul>
                              {item?.subCategory?.map((subItem) => {
                                return (
                                  <Link
                                    to={`/subCategoryDetail/${subItem?.subCategoryId}`}
                                  >
                                    <li style={{ color: "#000" }}>
                                      {subItem?.subCategory}
                                    </li>
                                  </Link>
                                );
                              })}
                            </ul>
                          </div>
                        </li>
                      </>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12">
              <nav className="navBar customize">
                <ul>
                  <li>
                    <NavLink to="/brands">Brands</NavLink>
                  </li>
                  {/* <li><a href="#">catalog categories</a></li>
                   */}
                  {/* <li>
                    <NavLink to="/catagory-catalog">catalog categories</NavLink>
                  </li> */}
                  <li>
                    <a href="#">
                      tech resources
                      <span className="ms-3">
                        <i className="fa-solid fa-angle-down"></i>
                      </span>
                    </a>
                    <div className="dropdownContent d-none">
                      <ul>
                        <li>
                          <Link to="/whitepages">
                            PDF Whitepages
                            <span className="ms-3">
                              <i className="fa-solid"></i>
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/videos">
                            How To's & Videos
                            <span className="ms-3">
                              <i className="fa-solid"></i>
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/clickableSchmatics">
                            Clickable Schematics
                            <span className="ms-3">
                              <i className="fa-solid"></i>
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/chassisMap">
                            Chassis Maps
                            <span className="ms-3">
                              <i className="fa-solid"></i>
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <NavLink to="/distributorTools">Distributor Tools</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contactus">Contact Us</NavLink>
                  </li>
                  <li>
                    <NavLink to="/requestcatalog">Request Catalog </NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="#!">Contact Us</NavLink>
                  </li>
                  <li>
                    <NavLink to="#!">Request Cetalog</NavLink>
                  </li> */}
                  {/* <li>
                    <NavLink to="/promos">promos</NavLink>
                  </li> */}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state?.auth?.isLoggedIn,
    numberCart: state?.catagoryReducer?.numberCart,
    cartData: state?.catagoryReducer?.CartData?.data,
    wishlistData: state?.catagoryReducer?.Wishlists,
    carts: state?.catagoryReducer?.Carts,
    numberWishlist: state?.catagoryReducer?.numberWishlist,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCartData: () => dispatch(getCartData()),
    cartDataResponse: (data) => dispatch(cartDataResponse(data)),
    getWishlistData: () => dispatch(getWishlistData()),
    wishlistDataResponse: (data) => dispatch(wishlistDataResponse(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
