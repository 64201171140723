
import {
  GET_ALL_CATEGORIES,
  // GET_PRODUCT_BY_CATEGORY_ID,
  SEARCH_PRODUCT_BY_CATEGORY,
  GET_NUMBER_CART,
  ADD_CART,ADD_WISHLIST, UPDATE_CART, DELETE_CART, 
  INCREASE_QUANTITY, DECREASE_QUANTITY,DELETE_WISHLIST,
  CART_DATA_RESPONSE,CART_DATA_REQUEST,WISHLIST_DATA_REQUEST,WISHLIST_DATA_RESPONSE, EMPTY_CART, GET_SUBCATEGORY_BY_CATEGORY_ID

} from "./actionTypes";

export const searchProductByCategory = (data) => (dispatch) => {
  dispatch({
    type: SEARCH_PRODUCT_BY_CATEGORY,
    payload: data,
  });
};

export const getAllCategories = (categories) => (dispatch) => {
  dispatch({
    type: GET_ALL_CATEGORIES,
    payload: categories,
  });
};

export const getSubcategoriesByCategoryId = (subCategories) => (dispatch) => {
  dispatch({
    type: GET_SUBCATEGORY_BY_CATEGORY_ID,
    payload: subCategories,
  });
};

export function GetNumberCart() {
  return {
    type: GET_NUMBER_CART
  }
};

export function AddCart(payload) {
  return {
    type: ADD_CART,
    payload
  }
}

// export const AddCart = (id) => async (dispatch) => {
//   try {
//     await cartService.addProductToCart(id);
//     dispatch({
//       type: ADD_CART,
//       payload: { id }
//     })
//   } catch (error) {
//     console.log(error);
//   }
// }

export function UpdateCart(payload) {
  return {
    type: UPDATE_CART,
    payload
  }
}
export function DeleteCart(payload) {
  return {
    type: DELETE_CART,
    payload
  }
}

export function IncreaseQuantity(payload) {
  return {
    type: INCREASE_QUANTITY,
    payload
  }
}
export function DecreaseQuantity(payload) {
  return {
    type: DECREASE_QUANTITY,
    payload
  }
}

export function getCartData() {
  return {
    type: CART_DATA_REQUEST  
  }
}

export function emptyCart() {
  return {
    type: EMPTY_CART
  }
}



export function cartDataResponse(payload) {
  return {
    type: CART_DATA_RESPONSE,
    payload
  }
}
export function AddWishlist(payload) {
  return {
    type: ADD_WISHLIST,
    payload
  }
}
export function DeleteWishlist(payload) {
  return {
    type: DELETE_WISHLIST,
    payload
  }
}
export function getWishlistData() {
  return {
    type: WISHLIST_DATA_REQUEST  
  }
}
export function wishlistDataResponse(payload) {
  return {
    type: WISHLIST_DATA_RESPONSE,
    payload
  }
}