import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../constants/constants";
import { usePromiseTracker } from "react-promise-tracker";
import { trackPromise} from 'react-promise-tracker';
import LoadingSpinerComponent from "../components/LoadingSpinerComponent";

const CategoryCatalog = () => {
  const [data, setData] = useState([]);
  const getData = async () => {
    const { data } = await axios.get(
      API_URL + "/admin/uploadfile/getCatalogCatagories"
    );
    setData(data?.PDF);
  };

  useEffect(() => {
    trackPromise(
    getData());
  }, []);
  return (
    <section>
      <nav class="breadcrumb">
        <div class="container">
          <div class="row">
            <ul>
              <li>
                <Link to="/">
                  Home
                  <span className="ms-3">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </Link>
              </li>
              {/* <li><a href="#">Tech Resources<span class="ms-3"><i class="fa-solid fa-angle-right"></i></span></a></li> */}
              <li>
                <Link to="/catagory-catalog">Catalog Categories</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="mainContent">
        <div class="container bgContent">
          <div class="row">
            <div class="pageTitle">
              <h1>Catalog Categories</h1>
              <p>Catalog Categories</p>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="categoryMain mt-5">
          {/* <LoadingSpinerComponent /> */}
          {data?.map((item, index) => (
            <div class="categoryListing mb-4" key={index}>
              <a href={API_URL+ `/` +`${item?.pdf?.url}`} class="categoryListing mb-5" target="_blank">
                <div class="categoryImage">
                  <img src={API_URL+ `/` +`${item?.image?.url}`}height={200} width={200} />
                </div>
                <div class="categorybottomDiv">
                  <p class="categoryTitle">{item.name}</p>
                  <div className="pdfBtns justify-content-around">
                  <a target="_blank" rel="noopener" href={API_URL+ `/` +`${item?.pdf?.url}`} className="p-2 ppmBtn text-center">View PDF</a>
                    {/* <a download={API_URL+ `/` +`${item?.file?.url}`+`.pdf`} className="ppmBtn" >Download</a> */}
                  </div>
                </div>
              </a>
            </div>
 ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CategoryCatalog;
