import React from 'react'
import { Link } from 'react-router-dom';
import error from '../img/error.png';

const PageNotFound = () => {
  return (
    <div className="pageNotFund">
        <img src={error} alt="errorimage" />
            <p style={{textAlign:"center"}}>
              <Link to="/">Go to Home </Link>
            </p>
      </div>
  )
}

export default PageNotFound