import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../constants/constants";

const Whitepages = () => {
  const [data, setData] = useState([]);

  const getData = async () => {
    const { data } = await axios.get(
      API_URL + "/admin/uploadfile/getPdfWhitePages"
    );
    setData(data?.PDF);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <section>
      <nav className="breadcrumb">
        <div className="container">
          <div className="row">
            <ul>
              <li>
                <Link to="/">
                  Home
                  <span className="ms-3">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </Link>
              </li>
              <li>
                <Link to={{}}>
                  Tech Resources
                  <span classNameName="ms-3">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/whitepages">PDF Whitepages</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="mainContent">
        {/* <div className="container bgContent">
          <div className="row">
            <div className="pageTitle">
              <h1>PDF Whitepages</h1>
              <p>PDF Whitepages</p>
            </div>
          </div>
        </div> */}

        <div className="container bgContent">
          <div className="row">
            {/* <div className="pageTitle">
              <h1>Products</h1>
              <p>Products</p>
            </div> */}
          </div>
          <p
            style={{
              textAlign: "center",
              padding: "50px",
              position: "relative",
            }}
          >
            <span className="cate-heading">PDF Whitepages</span>
            <img
              src="../images/textbanner.png"
              alt=""
              className="cate-image"
            ></img>
          </p>
        </div>

        <div className="container">
          <div className="pdfMain m-2">
            {data?.map((item, index) => {
              let str = item.pdf.url;

              let flag = str.includes(".pdf");

              if (flag) {
                return (
                  <div className="pdfListing m-2" key={index}>
                    <div className="pdfImage">
                      <img src="images/pdfIcon.png" alt="pdfIcon" />
                    </div>
                    <div className="pdfbottommapDiv">
                      <p className="pdfTitle">{item.name}</p>
                      <div className="pdfBtns">
                        <a
                          target="_blank"
                          rel="noopener"
                          href={API_URL + `/` + `${item.pdf.url}`}
                          className="ppmBtn"
                        >
                          View
                        </a>
                        <a
                          href={API_URL + `/` + `${item.pdf.url}`}
                          className="ppmBtn"
                        >
                          Download
                        </a>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="pdfListing m-2" key={index}>
                    <div className="pdfImage">
                      <img src="images/pdfIcon.png" alt="pdfIcon" />
                    </div>
                    <div className="pdfbottommapDiv">
                      <p className="pdfTitle">{item.name}</p>
                      <div className="pdfBtns">
                        <a
                          target="_blank"
                          rel="noopener"
                          href={API_URL + `/` + `${item.pdf.url}` + `.pdf`}
                          className="ppmBtn"
                        >
                          View
                        </a>
                        <a
                          href={API_URL + `/` + `${item.pdf.url}` + `.pdf`}
                          className="ppmBtn"
                        >
                          Download
                        </a>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Whitepages;
