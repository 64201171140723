import React, { useEffect } from "react";
import BreadCrumb from "../components/BreadCrumb";
import { NavLink } from "react-router-dom";

// useEffect(()=>{
//   window.scrollTo(0, 0);
// },[])

const PrivacyPolicy = () => {
  return (
    <>
      {/* <BreadCrumb/> */}
      <nav className="breadcrumb">
        <div className="container">
          <div className="row">
            <ul>
              <li>
                <NavLink to="/">
                  Home
                  <span className="ms-3">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/privacyPolicy">Privacy Policy</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="mainContent">
        <div className="container bgContent">
          <div className="row">
            {/* <div className="pageTitle">
              <h1>Products</h1>
              <p>Products</p>
            </div> */}
          </div>
          <p
            style={{
              textAlign: "center",
              padding: "50px",
              position: "relative",
            }}
          >
            <span className="cate-heading">Privacy Policy</span>
            <img
              src="../images/textbanner.png"
              alt=""
              className="cate-image"
            ></img>
          </p>
        </div>
        <div className="container">
          <div className="row mt-5">
            <p className="txtJustify">
              <>
                <p>
                  <strong>
                    <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                      Privacy Policy
                    </span>
                  </strong>
                </p>
                <p>
                  <span style={{ fontSize: "10.0pt", lineHeight: "107%" }}>
                    As a company, PPM Racing Products, respects&nbsp; your
                    privacy.&nbsp; Consistent with our commitment to providing
                    you a rewarding, satisfying and helpful shopping experience,
                    PPM Racing Products has found that collecting personal data
                    (defined below) through ppmracingproducts.com helps us
                    better understand our customers, services, and products that
                    are most helpful to you.&nbsp; Our Privacy Policy describes
                    how we use the personal data from the information collected
                    to better our customer service experience. Please read this
                    information thoroughly.
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: "10.0pt", lineHeight: "107%" }}>
                    If at any time you do not agree to all Terms of the Privacy
                    Policy, do not use this website and refrain from purchasing
                    from or browsing ppmracingproducts.com.
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: "10.0pt", lineHeight: "107%" }}>
                    We understand that a selection of our products are also
                    available for sale within many third party retailers;
                    however, data is not collected on the behalf of PPM Racing
                    Products. If you have any questions about other store
                    privacy policies, please contact the appropriate person
                    within their organization, specific to the store.
                  </span>
                </p>
                <p>
                  <strong>
                    <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                      Use of Information Collected by Us
                    </span>
                  </strong>
                </p>
                <p>
                  <span style={{ fontSize: "10.0pt", lineHeight: "107%" }}>
                    PPM Racing Products may collect two types of information
                    about its users: “Personal Information” (such as name, email
                    address, mailing address, phone number, credit card number
                    and the items you purchase.) and “ Aggregate Information”
                    (information such as how many different users&nbsp; log on
                    to ppmracingproducts.com on a daily basis or frequency of
                    customer visits to our online stores). Our main goal is to
                    provide the best virtual shopping experience and to
                    communicate with you about our products, promotions,
                    contests, and services.
                  </span>
                </p>
                <p>
                  <strong>
                    <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                      Personal Information Collected Online by
                      PPMRacingProducts.com
                    </span>
                  </strong>
                </p>
                <p>
                  <span style={{ fontSize: "10.0pt", lineHeight: "107%" }}>
                    Personal information is collected online by us or our
                    service providers on our behalf. Information is collected
                    when users voluntarily register with ppmracingproducts.com
                    to purchase items, use our community and communication
                    services, receive electronic newsletters, answer surveys,
                    enter contests and other promotional opportunities provided
                    on ppmracingproducts.com. A “user profile” is created to
                    maintain a record of your product interests and purchases
                    online. We also may receive personal usage and demographic
                    information from our third parties.
                  </span>
                </p>
                <p>
                  <strong>
                    <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                      Aggregate Information Collected Online by
                      PPMRacingProducts.com
                    </span>
                  </strong>
                </p>
                <p>
                  <span style={{ fontSize: "10.0pt", lineHeight: "107%" }}>
                    Aggregate Information is non-personally identifiable
                    anonymous information, such as gender, age, types of
                    products purchased, the site pages you access most
                    frequently, frequency of online visits, or the keywords you
                    enter. Aggregate information is used in a collective manner,
                    and no single person can be identified by that compiled
                    information.
                  </span>
                </p>
                <p>
                  <strong>
                    <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                      Order-Related and Promotional Email
                    </span>
                  </strong>
                </p>
                <p>
                  <span style={{ fontSize: "10.0pt", lineHeight: "107%" }}>
                    You agree that ppmracingproducts.com is authorized to
                    transmit email to you for the purpose of informing you about
                    your particular transaction.
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: "10.0pt", lineHeight: "107%" }}>
                    Also, ppmracingproducts.com and its affiliates may offer
                    promotional emails and electronic newsletters to those who
                    have accepted this option during our customer registration,
                    or directly from our email-sign up form. To unsubscribe and
                    no longer receive email blasts or marketing materials,
                    please follow the instructions included in each of the
                    promotional emails provided.
                  </span>
                </p>
                <p>
                  <strong>
                    <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                      Sharing Information with Third Parties
                    </span>
                  </strong>
                </p>
                <p>
                  <span style={{ fontSize: "10.0pt", lineHeight: "107%" }}>
                    PPMRacingProducts.com does not share, sell or trade with
                    third parties your Personal Information gathered online,
                    such as email addresses or survey data, except with your
                    consent or as described in this Privacy Policy.
                    PPMRacingProducts.com does not contribute to or participate
                    in cooperative databases which gives other companies access
                    to consumers’ Personal Information. The Personal Information
                    and Aggregate Information collected from
                    PPMRacingProducts.com may be shared with reputable
                    third-party organizations to contact consumers for marketing
                    and promotional purposes only on the behalf of PPM Racing
                    Products. Third party contacts to our customers may be
                    initiated only through postal mail.
                  </span>
                </p>
                <p>
                  <span style={{ fontSize: "10.0pt", lineHeight: "107%" }}>
                    PPMRacingProducts.com may divulge Personal Information if
                    such action is required for PPM Racing Products, or any of
                    our service providers or consultants to comply with any
                    valid legal process, such as a subpoena, search warrant,
                    statute or court order, or any valid administrative request
                    from a law enforcement agency. In addition, we may divulge
                    Personal Information if we or any of our service providers
                    or consultants believe that such disclosure is necessary to
                    address any unlawful act or acts that may endanger the
                    health or safety of another customer or general public.
                  </span>
                </p>
                <p>
                  <strong>
                    <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                      Service Providers
                    </span>
                  </strong>
                </p>
                <p>
                  <span style={{ fontSize: "10.0pt", lineHeight: "107%" }}>
                    PPM Racing Products may share Aggregate Information and
                    Personal Information with our consultants, service providers
                    and affiliates for our internal business purposes. The type
                    of information we collect includes products viewed and/or
                    purchased, your location (state/zip code if you place on
                    order) and Internet Protocol (IP) address of your computer.
                    An IP address is a number that is automatically assigned to
                    your computer whenever you surf the web. The computers,
                    which deliver the web pages to you, automatically identify
                    your computer by its IP address. When you request a page
                    from our site, our servers log your IP address. By
                    supplementing our records, this information helps us learn
                    things like what pages are most attractive to our visitors,
                    which of our products most interest our customers, and what
                    kinds of offers our customers like to see.
                  </span>
                </p>
              </>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
