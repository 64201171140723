import { connect } from "react-redux";
import React, { useState } from "react";

const SubCategoryFilter = (props) => {
  const all_sub_categories = props.getSubCategoryByCategoryId || [];

  let allSubCat = JSON.parse(localStorage.getItem("filteredData"));

  return (
    <>
      <div className="leftSideFilter">
        <div className="filterTitle">
          <div className="searchDiv">
            <p>Sub categories</p>
            {/* <div className="plSearch">
              <i className="fa-solid fa-magnifying-glass"></i>
            </div> */}
          </div>
          <div className="searchInput d-none">
            <input type="search" placeholder="Search for Categories" />
          </div>
        </div>
        {!!all_sub_categories.length &&
          all_sub_categories
            .slice(0, 4)
            .sort((a, b) => {
              if (a.subCategoryId?.name < b.subCategoryId?.name) {
                return -1;
              }
              if (a.subCategoryId?.name > b.subCategoryId?.name) {
                return 1;
              }
              return 0;
            })
            .map((sub_catagory, index) => {
              return (
                <div className="filterCategories" key={index}>
                  <input
                    className="me-3"
                    type="checkbox"
                    id="subcategory"
                    value={sub_catagory?.subCategoryId?._id}
                    onClick={(e) => props.onSubCategoryChecked(e)}
                    checked={
                      allSubCat?.subCategoryId.filter(
                        (ct) => ct == sub_catagory?.subCategoryId?._id
                      ).length > 0
                    }
                  />
                  <label>{sub_catagory.subCategoryId?.name}</label>
                </div>
              );
            })}

        <div className="filterCategories dropdown justify-content-center">
          <a
            className="dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            data-backdrop="static"
            data-keyboard="false"
          >
            +More
          </a>
          <div className="filterModal dropdown-menu">
            <div className="modalHeader">
              {/* <div className="searchInput"><input placeholder="Find Your Product" type="search" /></div> */}
              <div className="modalClose">
                <i className="fa-solid fa-xmark"></i>
              </div>
            </div>
            <div className="modalBody">
              <div className="row">
                {!!all_sub_categories &&
                  all_sub_categories
                    .sort((a, b) => {
                      if (a.subCategoryId?.name < b.subCategoryId?.name) {
                        return -1;
                      }
                      if (a.subCategoryId?.name > b.subCategoryId?.name) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((sub_catagory, index) => {
                      return (
                        <div
                          className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12"
                          key={index}
                        >
                          <div className="alphabetDiv mb-5">
                            <div className="searchDiv">
                              <p>
                                {/* {JSON.stringify(
                                sub_catagory?.subCategoryId?.name
                              )[1].toUpperCase()} */}
                              </p>
                            </div>
                            <div className="filterCategories">
                              <input
                                className="me-3"
                                type="checkbox"
                                value={sub_catagory?.subCategoryId?._id}
                                onClick={(e) => props.onSubCategoryChecked(e)}
                                checked={
                                  allSubCat?.subCategoryId.filter(
                                    (ct) =>
                                      ct == sub_catagory?.subCategoryId?._id
                                  ).length > 0
                                }
                              />
                              <label>{sub_catagory?.subCategoryId?.name}</label>
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    products: state?.catagoryReducer?.products,
    allproducts: state?.productReducer?.products,
    allCategories: state?.catagoryReducer?.categories,
    allBrands: state?.brandReducer?.brands,
    getSubCategoryByCategoryId: state?.catagoryReducer?.subCategories,
  };
}

export default connect(mapStateToProps)(SubCategoryFilter);
