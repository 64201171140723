import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import productService from "../services/product-catagorie-service";
import brandService from "../services/brand-service";
import { AddCart, AddWishlist } from "../redux/actions/catagoryAction";
import { getAllProducts, getProductById } from "../redux/actions/productAction";
import { getAllBrands, getAllSideBrands } from "../redux/actions/brandAction";
import { getSubcategoriesByCategoryId } from "../redux/actions/catagoryAction";
import FilterCategoryDetail from "../components/filter/FilterCategoryDetail";
import Filter from "../components/filter/Filter";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import cartService from "../services/cart-service";
import { API_URL } from "../constants/constants";
import DropdownFilter from "../components/filter/DropdownFilter";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import wishListService from "../services/wishlist-service";
import ReactPaginate from "react-paginate";
import axios from "axios";
import ReactSlider from "react-slider";
import isUrl from "is-url";

const CategoryDetail = (props) => {
  const navigate = useNavigate();
  let { id } = useParams();
  const cartDataStore = props.cartData || [];
  const [pageCount, setpageCount] = useState(0);
  const wishListDataStore = props.wishlistData || [];
  const all_products = props.allproducts || [];
  const [products, setProducts] = useState([]);
  // const [brands, setBrands] = useState([])
  const [categories, setCategories] = useState([]);
  const [numDays, setNumDays] = useState(0);

  const [brands, setBrands] = useState([]);
  const dispatch = useDispatch();
  let limit = 24;
  // const [currentPage, setCurrentPage] = useState(0);
  const [currentPage, setCurrent] = useState(1);
  const [filter, setFilter] = useState("");
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(10000);
  const [categoryName, setCategoryName] = useState("");
  const [filterTrue, setFilterTrue] = useState(false);
  // let currentPage;
  let [loading, setLoading] = useState(true);

  const params = useParams();

  const { id: Uid } = params;

  let previousPage = localStorage.getItem("secondLastPageCat");

  useEffect(() => {
    //Runs only on the first render

    setpageCount(Math.ceil(all_products?.productsCount / limit));
  }, [all_products]);

  useEffect(() => {
    const initialArr = JSON.parse(localStorage.getItem("paginationArrCat"));

    if (initialArr && initialArr.length > 0) {
      let lastElement = initialArr[initialArr.length - 1];
      setTimeout(async () => {
        let payload = JSON.parse(localStorage.getItem("filteredData"));
        let userId = localStorage.getItem("userId");
        payload.userId = userId;

        let newPayload = { ...payload, categoryId: [Uid] };

        const nextBrands = await fetchProducts(lastElement, newPayload);
        setCategoryName(nextBrands[0]?.category);
        setProducts(nextBrands);
        localStorage.setItem("isVisited", false);
      }, 1000);
    } else {
      productService
        .getProductsByCategory(Uid, 1)
        .then((res) => {
          setCategoryName(res?.products?.[0]?.category);
          dispatch(getAllProducts(res));
          const total = res?.productsCount;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    productService
      .getBrandsByCategory(Uid)
      .then((res) => {
        // console.log(res);
        dispatch(getAllSideBrands(res?.categoryBrands?.brand));

        //  setCurrent(1)
        // window.location.reload()
        // setCategoryName(res?.products?.[0]?.category);
        //  dispatch(getAllProducts(res));
        // //  setProducts(res.products);
        // const total = res?.productsCount;
        // setpageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
      });

    productService
      .getSubcategoriesByCategoryID(Uid)
      .then((res) => {
        // setCategoryName(res?.products?.[0]?.category);
        dispatch(getSubcategoriesByCategoryId(res?.category?.subCategory));
        // setProducts(res.products);
        // const total = res?.productsCount;
        // setpageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
      });

    // setProducts(all_products?.products)
    setpageCount(1);
    setFilterTrue(!filterTrue);
    // clearFields()
  }, [Uid]);

  const AddCartHandler = async (product) => {
    props.AddCart(product);
    const addedProduct = cartDataStore.find(
      (cartRecord) => cartRecord.id === product?._id
    );
    const cartData = [
      {
        productId: addedProduct?.id,
        quantity: addedProduct?.quantity,
      },
    ];
    await cartService
      .addProductToCart(cartData)
      .then((res) => {
        toast.success(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchProductById = (id) => {
    localStorage.setItem("isVisited", true);
    productService
      .getProductsById(id)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getProductById(res.product));
          navigate(`/productDetail/${id}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchProducts = async (currentPage, payload) => {
    const res = await axios.post(
      `${API_URL}/admin/product/searchProductByCategoryFilter?limit=${limit}&page=${currentPage}`,
      payload
    );
    dispatch(getAllProducts(res?.data));

    const data = await res?.data?.products;
    return data;
  };

  const handlePageClick = async (data) => {
    localStorage.setItem("isVisited", false);
    let arr = [1];
    arr.push(data.selected + currentPage);
    const pageNumber = data.selected + currentPage;
    const existArr = JSON.parse(localStorage.getItem("paginationArrCat"));
    if (existArr) {
      const findIndex = existArr.findIndex((v) => v == pageNumber);
      if (findIndex == -1) {
        existArr.push(data.selected + 1);
        localStorage.setItem("paginationArrCat", JSON.stringify(existArr));
      } else {
        localStorage.setItem("paginationArrCat", JSON.stringify(existArr));
      }
    } else {
      localStorage.setItem("paginationArrCat", JSON.stringify(arr));
    }
    localStorage.setItem("secondLastPageCat", data.selected + currentPage);

    //Till here

    let payload = JSON.parse(localStorage.getItem("filteredData"));
    let userId = localStorage.getItem("userId");
    payload.userId = userId;

    let newPayload = { ...payload, categoryId: [Uid] };

    // currentPage = data.selected + 1;

    // setCurrentPage(data.selected);

    const nextBrands = await fetchProducts(
      data.selected + currentPage,
      newPayload
    );

    setProducts(nextBrands);
    // scroll to the top
    window.scrollTo(0, 0);
  };

  //useEffect page hold

  useEffect(() => {
    addEventListener("popstate", (e) => {
      (async () => {
        const initialArr = JSON.parse(localStorage.getItem("paginationArrCat"));
        e.preventDefault();
        const isVisited = localStorage.getItem("isVisited");
        if (isVisited === "false") {
          if (initialArr.length > 1) {
            navigate(`/categoryDetail/${Uid}`);
            setTimeout(async () => {
              let payload = JSON.parse(localStorage.getItem("catFilteredData"));
              const arrLength = initialArr.length;
              const secondLastPage = initialArr[arrLength - 2];
              initialArr.pop();
              localStorage.setItem("secondLastPageCat", secondLastPage);
              let userId = localStorage.getItem("userId");
              payload.userId = userId;
              localStorage.setItem(
                "paginationArrCat",
                JSON.stringify(initialArr)
              );
              const nextBrands = await fetchProducts(secondLastPage, payload);
              setCategoryName(nextBrands[0]?.category);
              setProducts(nextBrands);
              window.scrollTo(0, 0);
            }, 500);
          } else {
            localStorage.removeItem("paginationArrCat");
            localStorage.removeItem("secondLastPageCat");
            localStorage.removeItem("isVisited");
            navigate("/");
          }
        }
      })();
    });
  }, [Uid]);

  //Add wishlist
  const WishlistHandler = async (product) => {
    props.AddWishlist(product);
    const addedWishlistProduct = wishListDataStore.find(
      (wishlistRecord) => wishlistRecord.id === product?._id
    );
    const wishlistData = [
      {
        productId: addedWishlistProduct?.id,
      },
    ];
    await wishListService
      .addProductToWishlist(wishlistData)
      .then((res) => {
        toast.success(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleFilter = (x) => {
    setFilter(x);
    let Filteredproducts;
  };

  const [brandData, setBrandData] = useState([]);
  const onBrandChecked = (e) => {
    const brData = e.target.value;
    if (!e.target.checked) {
      const removedCheck = brandData.filter((find) => find !== brData);
      setBrandData([...removedCheck]);
    } else {
      setBrandData([...brandData, brData]);
    }
  };

  let allSelectedBrand = JSON.parse(localStorage.getItem("catFilteredData"));
  const filterdAPI = () => {
    const filteredData = {
      categoryId: [id],
      brandId: brandData,
      minPrice: min,
      maxPrice: max,
    };
    localStorage.setItem("catFilteredData", JSON.stringify(filteredData));
  };

  useEffect(() => {
    filterdAPI();
  }, [brandData.length > 0, min, max, Uid]);

  const clearFields = () => {
    setBrandData([]);
    setMax(10000);
    setMin(0);
    $("input[type=checkbox]").prop("checked", false);

    $("#ReactSliderShow").hide();
    $("#ReactSliderHide").show();
  };

  const handleLowHighFilter = async (x) => {
    let payload = JSON.parse(localStorage.getItem("catFilteredData"));
    let limit = 24;
    if (!x) {
      localStorage.setItem("sortingCatArr", "");
    }
    if (x == "4") {
      localStorage.setItem("sortingCatArr", x);
      payload.sortBy = "lowToHigh";

      const res = await axios.post(
        `${API_URL}/admin/product/getProducts?limit=${limit}&page=1`,
        payload
      );
      const data = await res?.data;
      setProducts(data.products);
      const total = data?.productsCount;
      setpageCount(Math.ceil(total / limit));
    } else if (x == "3") {
      localStorage.setItem("sortingCatArr", x);
      payload.sortBy = "highToLow";

      let userId = localStorage.getItem("userId");
      payload.userId = userId;

      const res = await axios.post(
        `${API_URL}/admin/product/getProducts?limit=${limit}&page=1`,
        payload
      );
      const data = await res?.data;
      setProducts(data.products);
      const total = data?.productsCount;
      setpageCount(Math.ceil(total / limit));
    }
  };

  const getDays = () => {
    brandService
      .getNoofDays()
      .then((res) => {
        setNumDays(res?.newTagDays?.days);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    //Runs only on the first render
    getDays();
  }, []);

  const date = new Date();

  return (
    <section>
      <nav className="breadcrumb">
        <div className="container">
          <div className="row">
            <ul>
              <li>
                <NavLink to="/">
                  Home
                  <span className="ms-3">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/products">Products</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="mainContent">
        <div className="container bgContent">
          <div className="row">
            {/* <div className="pageTitle">
              <h1>Products</h1>
              <p>Products</p>
            </div> */}
          </div>
          <p
            style={{
              textAlign: "center",
              padding: "50px",
              position: "relative",
            }}
          >
            <span className="cate-heading">{categoryName?.toUpperCase()}</span>
            <img
              src="../images/textbanner.png"
              alt=""
              className="cate-image"
            ></img>
          </p>
        </div>
        <div className="container">
          <div className="row mt-5">
            {/* <FilterCategoryDetail /> */}
            <Filter filterTrue={filterTrue} />
            <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              {/* <div className="row justify-content-end">
                <div className="form-floating sortListing mb-5">
                  <select
                    className="form-select"
                    id="floatingSelect"
                    name="filter"
                    aria-label="Floating label select example"
                    onChange={(e) => handleLowHighFilter(e.target.value)}
                  >
                    <option defaultValue="1"></option>
                    <option value="3">Price High to Low</option>
                    <option value="4">Price Low to High</option>
                  </select>
                  <label className="dropdownLabel" htmlFor="floatingSelect">
                    Sort By
                  </label>
                </div>
              </div> */}
              {all_products?.products?.length === 0 ? (
                <Spinner />
              ) : (
                <div className="pListingMain">
                  {!!all_products?.products?.length &&
                    all_products?.products?.map((product) => {
                      let oldDate = new Date(product?.createdAt);

                      let Difference_In_Time =
                        date?.getTime() - oldDate?.getTime();

                      // To calculate the no. of days between two dates
                      let Difference_In_Days =
                        Difference_In_Time / (1000 * 3600 * 24);
                      return (
                        <>
                          <div className="productListing">
                            <a href={`/productDetail/${product?._id}`}>
                              <div className="plImage">
                                {product?.image[0]?.url ? (
                                  // product.image.map((img, index) => (
                                  //   <>
                                  //     {isUrl(img.url) ? (
                                  //       <img
                                  //         key={img.id}
                                  //         src={img?.url}
                                  //         width="100%"
                                  //         alt="Product image coming soon"
                                  //       ></img>
                                  //     ) : (
                                  //       <img
                                  //         key={img.id}
                                  //         src={API_URL + "/" + img?.url}
                                  //         width="100%"
                                  //         alt="Product image coming soon"
                                  //       ></img>
                                  //     )}
                                  //   </>
                                  // ))
                                  <>
                                    <img
                                      src={
                                        isUrl(product?.image[0]?.url)
                                          ? product?.image[0]?.url
                                          : product?.image[0]?.url == null ||
                                            product?.image[0]?.url == "N/A"
                                          ? "./images/dummyProduct.jpg"
                                          : API_URL +
                                            "/" +
                                            product?.image[0]?.url
                                      }
                                      width="100%"
                                      alt="Product image coming soon"
                                    ></img>
                                  </>
                                ) : (
                                  <img
                                    src="../images/dummyProduct.jpg"
                                    alt="Product image coming soon"
                                  ></img>
                                )}
                                {Difference_In_Days < numDays && (
                                  <span>New</span>
                                )}
                                {/* <span>New</span> */}
                              </div>
                            </a>

                            <div className="plInfo">
                              <div className="reviews">
                                {(() => {
                                  const arr = [];
                                  let rating = Math?.ceil(product?.rating);
                                  let remaining = 5 - rating;
                                  for (let j = 0; j < rating; j++) {
                                    arr.push(
                                      <i className="fa-solid fa-star"></i>
                                    );
                                  }
                                  for (let j = 0; j < remaining; j++) {
                                    arr.push(
                                      <i className="fa-regular fa-star"></i>
                                    );
                                  }
                                  return arr;
                                })()}
                              </div>
                              <p>{product?.name}</p>
                              <div className="dealsPrice mb-3">
                                <span>
                                  {/* ${product?.oldPrice ? product.oldPrice : product.price} */}
                                  {product?.oldPrice
                                    ? "$" + product?.oldPrice
                                    : null}
                                </span>
                                <span>${product?.price}</span>
                              </div>
                            </div>
                            <div className="plBtns">
                              <button
                                className="atcBtn"
                                onClick={() => AddCartHandler(product)}
                              >
                                Add to Cart
                              </button>
                              <button
                                className="productBtn"
                                onClick={() => WishlistHandler(product)}
                                title="Add to wishlist"
                              >
                                <i className="fa-solid fa-heart"></i>
                              </button>
                              <button
                                className="productBtn"
                                onClick={() => fetchProductById(product._id)}
                                title="Product detail"
                              >
                                <i className="fa-solid fa-eye"></i>
                              </button>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              )}

              {/* <div className="row">
              {/* <div className="row">
              {/* <div className="row">
              {/* <div className="row">
              {/* <div className="row">
              {/* <div className="row">
              {/* <div className="row">
              {/* <div className="row">
              {/* <div className="row">
              {/* <div className="row">
              {/* <div className="row">
              {/* <div className="row">
              {/* <div className="row">
                        </div> */}

              {/* <div className="ppmpagination">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-end">
                    <li className="page-item disabled">
                      <a className="page-link">Previous</a>
                    </li>
                    <li className="page-item"><a className="page-link" href="/#">1</a></li>
                    <li className="page-item"><a className="page-link" href="/#">2</a></li>
                    <li className="page-item"><a className="page-link" href="/#">3</a></li>
                    <li className="page-item">
                      <a className="page-link" href="/#">Next</a>
                    </li>
                  </ul>
                </nav>
              </div> */}
              {all_products?.products?.length == 0 ? (
                ""
              ) : (
                <>
                  <div className="pagination_space">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      forcePage={
                        previousPage ? previousPage - 1 : currentPage - 1
                      }
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

function mapStateToProps(state) {
  return {
    cartData: state?.catagoryReducer?.Carts,
    wishlistData: state?.catagoryReducer?.Wishlists,
    products: state?.catagoryReducer?.products,
    allproducts: state?.productReducer?.products,
    allCategories: state?.catagoryReducer?.categories,
    allBrands: state?.brandReducer?.brands,
    isLoggedIn: state?.auth?.isLoggedIn,
    allSideBrands: state?.brandReducer?.sideBrands,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    AddCart: (product) => dispatch(AddCart(product)),
    AddWishlist: (product) => dispatch(AddWishlist(product)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDetail);
