// import axios from 'axios'
import React, { useState, useEffect } from 'react'
// import SearchBar from '../components/Search'
import brandService from '../services/brand-service'
import productService from '../services/product-catagorie-service'
import ProductCatagory from './ProductCatagory'
import Container from '../components/Container'
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getAllProducts } from "../redux/actions/productAction"
import { getAllBrands } from "../redux/actions/brandAction"


const Home = (props) => {
    const all_products = props.allproducts;
    const all_brands = props.allBrands;
    const [brands, setBrands] = useState([])
    const [products, setProducts] = useState([])
    const [categories, setCategories] = useState([])
    const dispatch = useDispatch();

    useEffect(() => {
        brandService.getBrands(brands).then((res) => {
            dispatch(getAllBrands(res.brands))
            setBrands(res.brands)
        }).catch((err) => {
            console.log(err);
        })

        productService.getProducts(products).then((res) => {
            dispatch(getAllProducts(res.products))
            setProducts(res.products)
        }).catch((err) => {
            console.log(err);
        })

        productService.getCategories(categories).then((res) => {
            setCategories(res.categories)
        }).catch((err) => {
            console.log(err);
        })
    }, [])


    return (
        <>
            <Container />
            {/* <Header /> */}
        </>
    )
}

function mapStateToProps(state) {
    return {
        allproducts: state?.productReducer?.products,
        allBrands: state?.brandReducer?.brands,
    }
}

export default connect(mapStateToProps)(Home)