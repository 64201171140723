import React, { useState, useEffect } from "react";
import BreadCrumb from "../components/BreadCrumb";
import swal from "sweetalert";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../constants/constants";

const VerifyEmail = () => {
// const [token, setToken]=useState('')
  useEffect(() => { 
    let urlPath=window.location.pathname
    const myArray = urlPath.split("/");
  const token=myArray[2];
    // setToken(myArray[2])
    axios
  .get(`${API_URL}/user/signup/verifyEmail/${token}`)
  .then(function (response) {
    swal("Success!", response.data.message, "success")
  });

    }, []); 
  
  return (
    <div>
      {/* <BreadCrumb /> */}
      <nav className="breadcrumb">
        <div className="container">
          <div className="row">
            <ul>
              <li>
                <NavLink to="/">
                  Home
                  <span className="ms-3">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/verifyEmail/1">Verify Email</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="mainContent">
        <div className="container bgContent">
          <div className="row">
            {/* <div className="pageTitle">
              <h1>Verify Email</h1>
              <p>Your email has been verified</p>
            </div> */}
          </div>

          <div className="container">
            <div className="row contactUs">
              <h1>Verify Email</h1>
              <p>Your email has been verified</p>
              <Link to="/">
                <h2
                  style={{ color: "red", textAlign: "center", marginTop: "30px" }}
                >
                  <u>Go back to Home page</u>
                </h2>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
