import React from 'react'

const Faq = () => {
  return (
    <>
      <div className="mainContent">
        {/* <div className="container bgContent">
          <div className="row">
            <div className="pageTitle">
              <h1>FAQ's</h1>
              <p>FAQ's</p>
            </div>
          </div>
        </div> */}
         <div className="container bgContent">
              <div className="row">
                {/* <div className="pageTitle">
              <h1>Products</h1>
              <p>Products</p>
            </div> */}
              </div>
              <p
                style={{
                  textAlign: "center",
                  padding: "50px",
                  position: "relative",
                }}
              >
                <span className="cate-heading">FAQ</span>
                <img
                  src="../images/textbanner.png"
                  alt=""
                  className="cate-image"
                ></img>
              </p>
            </div>
        <div className="container">
          <div className="row mt-5">
            <div className="faq mb-5">
              <h4>Shipping and Delivery</h4>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <span className="aplus" />
                      <span className="plus-minus-toggle">
                        How does our shipping promotion work?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Good on pre-paid, online, retail orders that meet the minimum
                      purchase requirement.
                      <br />
                      Standard ground shipments only in the contiguous U.S.
                      <br />
                      NO air, COD or truck shipments.
                      <br />
                      Additional charges may be applied for oversized items, or
                      special order items.
                      <br />
                      We reserve the right to ship via truck on large orders at our
                      discretion.
                      <br />
                      May not be combined with other offers.
                      <br />
                      Does not apply to wholesale program.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="faq mb-5">
              <h4>Delivery Time</h4>
              <div className="accordion" id="accordionExample1">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne1">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne1"
                      aria-expanded="true"
                      aria-controls="collapseOne1"
                    >
                      <span className="aplus" />
                      <span className="plus-minus-toggle">
                        NEXT DAY DELIVERY-(RED LABEL)
                      </span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne1"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne1"
                    data-bs-parent="#accordionExample1"
                  >
                    <div className="accordion-body">
                      We offer Next Day delivery which is available in the U.S.,
                      Hawaii and Alaska. The cost of this service is
                      <br />
                      substantial compared to normal ground shipping charges. We will
                      ship all Next Day orders taken before 4:00 PM EST, Monday thru
                      Friday the same day.
                      <br />
                      Oversized shipments are billed out based on shipping standards.
                      This is called dimensional weight.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne2">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne2"
                      aria-expanded="true"
                      aria-controls="collapseOne2"
                    >
                      <span className="aplus" />
                      <span className="plus-minus-toggle">
                        2ND DAY AIR DELIVERY-(BLUE LABEL)
                      </span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne2"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne2"
                    data-bs-parent="#accordionExample1"
                  >
                    <div className="accordion-body">
                      We offer 2nd day delivery which is available in the U.S.,
                      Hawaii, and Alaska. The cost of this service may be substantial
                      compared normal ground shipping charges. We will ship all 2nd
                      day orders taken before 3:00 PM EST, Monday thru Friday the same
                      day.
                      <br />
                      Oversized shipments are billed out based on shipping standards.
                      This is called dimensional weight.
                      <br />
                      <br />
                      Package travel times are based on business days! This means that
                      packages travel on Monday through Friday. If you place your
                      order before 4:00 PM EST on Friday and your shipment has a
                      scheduled delivery time of two days it will arrive on Tuesday.
                      If you place your order before 4:00 PM CST on Saturday, Sunday,
                      or Monday and your shipment has a scheduled delivery time of two
                      days it will arrive on Wednesday.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne3"
                      aria-expanded="true"
                      aria-controls="collapseOne3"
                    >
                      <span className="aplus" />
                      <span className="plus-minus-toggle">OVERSIZE</span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne3"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne3"
                    data-bs-parent="#accordionExample1"
                  >
                    <div className="accordion-body">
                      All items which are considered oversize can be shipped ground
                      but require a “dimensional weight” shipment charge. There are
                      items on this site which are oversized, but may not be indicated
                      as such. We reserve the right to charge an oversize fee on any
                      order which contains a product that is oversized, even though it
                      may not be indicated on the site.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne4">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne4"
                      aria-expanded="true"
                      aria-controls="collapseOne4"
                    >
                      <span className="aplus" />
                      <span className="plus-minus-toggle">DIMENSIONAL WEIGHT</span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne4"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne4"
                    data-bs-parent="#accordionExample1"
                  >
                    <div className="accordion-body">
                      Dimensional weight is used to figure the cost of shipping many
                      ground packages and all Next-day or Second- day air packages. A
                      package weighing 10lbs could cost you a 30lb rate because of its
                      dimensions. This is all based on the size of the box that we
                      will need to pack your order in. Your shipping amount will be
                      calculated using this method.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne5">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne5"
                      aria-expanded="true"
                      aria-controls="collapseOne5"
                    >
                      <span className="aplus" />
                      <span className="plus-minus-toggle">C.O.D. Orders</span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne5"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne5"
                    data-bs-parent="#accordionExample1"
                  >
                    <div className="accordion-body">
                      We require payment by CERTIFIED CHECK OR MONEY ORDER upon
                      delivery of your order. We do NOT ACCEPT CASH OR PERSONAL
                      CHECKS. PPM will add all appropriate shipping fees. The sum of
                      $12.00 per box will be added to all C.O.D orders. Oversize
                      and/or overweight boxes will be charged in accordance with the
                      current shipper’s published rates. PPM reserves the right to
                      change shipping rates in accordance with rate increases by our
                      shippers and freight companies. International and Canadian
                      shipments must be prepaid.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne6">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne6"
                      aria-expanded="true"
                      aria-controls="collapseOne6"
                    >
                      <span className="aplus" />
                      <span className="plus-minus-toggle">
                        FREIGHT TRUCK SHIPPING
                      </span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne6"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne6"
                    data-bs-parent="#accordionExample1"
                  >
                    <div className="accordion-body">
                      Some products are too large and must be shipped by motor
                      freight. All truck orders must be pre-paid with a credit or
                      debit card. Please include, whenever possible, a business or
                      commercial address for delivery. A $50 charge will be added to
                      truck shipments delivered to a residential
                      (non-business/non-commercial) address. Orders are shipped
                      “Pre-Paid”. You will be contacted with the amount for the
                      shipping via email or phone.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne7">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne7"
                      aria-expanded="true"
                      aria-controls="collapseOne7"
                    >
                      <span className="aplus" />
                      <span className="plus-minus-toggle">INTERNATIONAL ORDERS</span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne7"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne7"
                    data-bs-parent="#accordionExample1"
                  >
                    <div className="accordion-body">
                      For orders shipping International or Canada please contact us
                      <br />
                      <br />
                      1-865-354-9669
                      <a>
                        <i>sales@ppmracingproducts.com</i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="faq mb-5">
              <h4>Returns &amp; Replacements</h4>
              <p className="mb-3">
                We want you to be satisfied with your purchase. You may return the new
                unused item within 30 days after the invoice date for a credit or
                exchange.
              </p>
              <div className="accordion" id="accordionExample2">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne8"
                      aria-expanded="true"
                      aria-controls="collapseOne8"
                    >
                      <span className="aplus" />
                      <span className="plus-minus-toggle">
                        All returns require a Return Authorization Number (RA#)
                      </span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne8"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne8"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      All returns require a Return Authorization Number (RA#) and must
                      be shipped freight prepaid. Please contact our customer service
                      at 865-354-9669 for authorization. Returns must be accompanied
                      with a description of the defect.
                      <br />
                      <br />
                      We will credit or exchange for DEFECTIVE items only (or for any
                      shipping errors made by us). Please return the items in their
                      original packaging within 30 days to the return address on your
                      packing slip. Items returned without original packaging will NOT
                      be credited. Please feel free to contact us with any questions
                      you may have. All returns must be sent prepaid. All returns not
                      due to our error are subject to a 15% restocking charge plus any
                      shipping costs we incurred on the order.
                      <br />
                      <br />
                      DO NOT RETURN PRODUCTS WITHOUT A RETURN AUTHORIZATION NUMBER (RA
                      Number). THIS NUMBER MUST BE CLEARLY MARKED ON THE OUTSIDE OF
                      YOUR PACKAGE.
                      <br />
                      Please allow up to 2 weeks to receive your replacement or
                      refund. We are dedicated to carrying only the highest quality
                      products and maintaining excellent customer care. We strive to
                      provide fast and courteous service and 100% Customer
                      Satisfaction on Every Order.
                      <br />
                      (Special Order or Custom Made Items are Non Returnable and No
                      Refunds will be Issued).
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="faq mb-5">
              <h4>How To Order</h4>
              <p className="mb-3">Make you purchase in 5 easy steps!</p>
              <div className="accordion" id="accordionExample3">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne9"
                      aria-expanded="true"
                      aria-controls="collapseOne9"
                    >
                      <span className="aplus" />
                      <span className="plus-minus-toggle">
                        Step 1 - Production Selection:
                      </span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne9"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne9"
                    data-bs-parent="#accordionExample3"
                  >
                    <div className="accordion-body">
                      Choose the “PRODUCT” you want. Enter the “QUANTITIY” you want to
                      buy in the box, then click the “ADD TO CART” button to place the
                      item into your shopping cart. Either adjust the quantity before
                      adding the item, or adjust the quantity in your shopping cart.
                      Enter your “DISCOUNT CODES”, if are applicable. Continue
                      shopping or finalize your order, click “Checkout“, or you can
                      click “Checkout” when viewing your shopping cart.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne10"
                      aria-expanded="true"
                      aria-controls="collapseOne10"
                    >
                      <span className="aplus" />
                      <span className="plus-minus-toggle">
                        Step 2- Sign-In or New Customer Registration:
                      </span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne10"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne10"
                    data-bs-parent="#accordionExample3"
                  >
                    <div className="accordion-body">
                      If you are a new online customer and you do not want us to store
                      your address information, click the Guest Checkout “Continue”
                      button at the bottom of the display.
                      <br />
                      <br />
                      If you are a Return User and have set-up an account with us
                      previously, enter your email address and password to continue
                      with member access.
                      <br />
                      <br />
                      If you are not registered and would like us to store your
                      address information, choose “Register and Checkout” and then
                      “Continue button”. Fill in the “required information” fields as
                      indicated. Be sure to write your password down for future use.
                      After checkout, we will save your address information so next
                      time you order, we can find your info.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne11"
                      aria-expanded="true"
                      aria-controls="collapseOne11"
                    >
                      <span className="aplus" />
                      <span className="plus-minus-toggle">
                        Step 3- Billing and Shipping Information
                      </span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne11"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne11"
                    data-bs-parent="#accordionExample3"
                  >
                    <div className="accordion-body">
                      If you are a new customer, supply us with your billing
                      information. This information should match the billing
                      information for the credit card you are planning to use. Click
                      the “Continue” button to proceed with your checkout.
                      <br />
                      <br />
                      ***IMPORTANT***
                      <br />
                      Please verify that your shipping address is correct before
                      continuing with checkout.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne14"
                      aria-expanded="true"
                      aria-controls="collapseOne14"
                    >
                      <span className="aplus" />
                      <span className="plus-minus-toggle">Step 4- Payment</span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne14"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne14"
                    data-bs-parent="#accordionExample3"
                  >
                    <div className="accordion-body">
                      Verify that your address information is correct. “Double-check
                      your order” If you need to make any changes. Click on the
                      appropriate link and make the necessary changes.
                      <br />
                      <br />
                      When you are ready to finish. Choose the credit card type or
                      select PayPal, enter the number and expiration date, then click
                      the “Checkout” button to send us your order.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne15"
                      aria-expanded="true"
                      aria-controls="collapseOne15"
                    >
                      <span className="aplus" />
                      <span className="plus-minus-toggle">Step 5- Order Invoice</span>
                    </button>
                  </h2>
                  <div
                    id="collapseOne15"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne15"
                    data-bs-parent="#accordionExample3"
                  >
                    <div className="accordion-body">
                      After transmitting your order, you will receive an Order
                      Confirmation, please print for your records. You will also
                      receive an email order confirmation. Once your order has been
                      processed and shipped, you will be emailed a “Shipping
                      Confirmation”.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Faq