import { combineReducers } from "redux"
import auth from "./auth"
import catagoryReducer from './catagoryReducer'
import productReducer from './productReducer'
import brandReducer from './brandReducer'
import userReducer from "./userReducer"
import message from './message'

const reducers = combineReducers({
    auth,
    catagoryReducer,
    productReducer,
    brandReducer,
    userReducer,
    message,
});

export default reducers