import React from "react";
import { NavLink } from "react-router-dom";

const ShippingReturnPolicy = () => {
  return (
    <div>
      <nav className="breadcrumb">
        <div className="container">
          <div className="row">
            <ul>
              <li>
                <NavLink to="/">
                  Home
                  <span className="ms-3">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/shippingReturnPolicy">
                  Shipping & Return policy
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="mainContent">
        {/* <div className="container bgContent">
                    <div className="row">
                        <div className="pageTitle">
                            <h1>Shipping &amp; Return Policy</h1>
                            <p>Shipping &amp; Return Policy</p>
                        </div>
                    </div>
                </div> */}
        <div className="container bgContent">
          <div className="row">
            {/* <div className="pageTitle">
              <h1>Products</h1>
              <p>Products</p>
            </div> */}
          </div>
          <p
            style={{
              textAlign: "center",
              padding: "50px",
              position: "relative",
            }}
          >
            <span className="cate-heading">Shipping and Return Policy</span>
            <img
              src="../images/textbanner.png"
              alt=""
              className="cate-image"
            ></img>
          </p>
        </div>
        <div className="container">
          <div className="row mt-5">
            <>
              <p
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "8.0pt",
                  marginLeft: "0in",
                  lineHeight: "107%",
                  fontSize: 15,
                  fontFamily: '"Calibri",sans-serif',
                }}
              >
                <strong>
                  <span style={{ fontSize: 19, lineHeight: "107%" }}>
                    PPM Disclaimer/Warranty
                  </span>
                </strong>
              </p>
              <p
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "8.0pt",
                  marginLeft: "0in",
                  lineHeight: "107%",
                  fontSize: 15,
                  fontFamily: '"Calibri",sans-serif',
                }}
              >
                <span style={{ fontSize: 13, lineHeight: "107%" }}>
                  There is no warranty, guarantee, or liability either expressed
                  or implied written or oral applicable to PPM Inc. User assumes
                  all responsibility and liability arising from malfunction,
                  damage, or misuse. PPM Racing Products are for racing purposes
                  only.
                </span>
              </p>
              <p
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "8.0pt",
                  marginLeft: "0in",
                  lineHeight: "107%",
                  fontSize: 15,
                  fontFamily: '"Calibri",sans-serif',
                }}
              >
                <span style={{ fontSize: 13, lineHeight: "107%" }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "8.0pt",
                  marginLeft: "0in",
                  lineHeight: "107%",
                  fontSize: 15,
                  fontFamily: '"Calibri",sans-serif',
                }}
              >
                <span style={{ fontSize: 13, lineHeight: "107%" }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "8.0pt",
                  marginLeft: "0in",
                  lineHeight: "107%",
                  fontSize: 15,
                  fontFamily: '"Calibri",sans-serif',
                }}
              >
                <strong>
                  <span style={{ fontSize: 19, lineHeight: "107%" }}>
                    Return Policies
                  </span>
                </strong>
              </p>
              <p
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "8.0pt",
                  marginLeft: "0in",
                  lineHeight: "107%",
                  fontSize: 15,
                  fontFamily: '"Calibri",sans-serif',
                }}
              >
                <span style={{ fontSize: 13, lineHeight: "107%" }}>
                  All returns require an RMA (return authorization number).
                  Please call us for an authorization number before shipping the
                  product. &nbsp;A copy of the invoice needs to accompany the
                  item when it is returned. Any item returned must be in
                  sellable condition, and not be used or damaged. PPM reserves
                  the right to charge a 15% restocking fee from the credit
                  issued for the returned item. Please package item properly
                  when returning. Exceptions to this policy will be items sent
                  in our error, defective items, or custom-made items. No credit
                  will be given for items purchased more than 30 days before
                  return date, or on special order items. Freight on returned
                  items must be prepaid.
                </span>
              </p>
              <p
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "8.0pt",
                  marginLeft: "0in",
                  lineHeight: "107%",
                  fontSize: 15,
                  fontFamily: '"Calibri",sans-serif',
                }}
              >
                <span style={{ fontSize: 13, lineHeight: "107%" }}>&nbsp;</span>
              </p>
              <p
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "8.0pt",
                  marginLeft: "0in",
                  lineHeight: "107%",
                  fontSize: 15,
                  fontFamily: '"Calibri",sans-serif',
                }}
              >
                <strong>
                  <span style={{ fontSize: 19, lineHeight: "107%" }}>
                    Shipping
                  </span>
                </strong>
              </p>
              <p
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "8.0pt",
                  marginLeft: "0in",
                  lineHeight: "107%",
                  fontSize: 15,
                  fontFamily: '"Calibri",sans-serif',
                }}
              >
                <span style={{ fontSize: 13, lineHeight: "107%" }}>
                  All PPM Orders are shipped via FedEx Ground, USPS, or FedEx
                  Freight, unless otherwise arranged. &nbsp;If you need help
                  with a delivery time, we would be glad to help. &nbsp; Your
                  order can be shipped NEXT DAY or 2 DAY delivery for an
                  additional cost. &nbsp;Most order received by 3:00 PM EST
                  typically are shipped that day. &nbsp;
                </span>
              </p>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingReturnPolicy;
