import React, { useState } from "react";
import authService from "../../../services/auth.service";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const sendMailer = () => {
    authService.verifyMail(email)
      .then((res) => {
        if (res.status === 200) {
          swal("Success!", res.message, "success")
          setEmail('')
          navigate("/")
          // window.location.reload()
        } else {
          swal("Error!", res.message, "error")
        }
      }).catch((err) => {
        swal("Error!", "Something went wrong", "error")
      })
  }

  return (
    <>
      <div className="sdMainContent pt-5">
        <div className="container bgContent">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <img className="img-fluid" src="images/forgotPassword.png" alt="forgotPassword" />
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 forgotPassword">
              <div className="row">
                <h1 className="mb-3 text-center">Forgot Password</h1>
                <p>Enter your email address, and we will send you a link to reset your password.</p>
                <div className="form-floating mb-3">
                  <input type="text" className="form-control" id="floatingInput" placeholder="name@example.com"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email.trim().toLowerCase()} />
                  <label htmlFor="floatingInput">Email</label>
                </div>
                <div className="mt-2"><button className="ppmBtn" id="reset"
                  onClick={sendMailer}
                >Send</button>
                </div>
              </div>
              {/* <hr className="mt-5" />
              <p className="dText align mt-3">OR</p>
              <div className="row">
                <div className="socialLinks">
                  <div><img src="images/facebookFtr.png" alt="forgotPassword" /></div>
                  <div><img src="images/google.png" alt="forgotPassword" /></div>
                  <div><img src="images/twitterFtr.png" alt="forgotPassword" /></div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword