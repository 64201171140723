import React, { useState } from "react";
import basestyle from "../Base.module.css";
import swal from "sweetalert";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import authService from "../../../services/auth.service";

import { useDispatch } from "react-redux";
import { login } from "../../../redux/actions/auth";

const Login = () => {
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const [user, setUserDetails] = useState({
    email: "",
    password: "",
  });
  const { pathname } = useLocation();
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...user,
      [name]: value,
    });
  };

  const validateForm = (values) => {
    const error = {};
    const regex = /^[^\s+@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      error.email = "Email is required";
    } else if (!regex.test(values.email)) {
      error.email = "Please enter a valid email address";
    }
    if (!values.password) {
      error.password = "Password is required";
    }
    return error;
  };

  const loginHandler = (e) => {
    setFormErrors({});
    e.preventDefault();
    const validate = validateForm(user);

    if (validate.email || validate.password) {
      setFormErrors(validate);
      return;
    }
    setIsSubmit(true);
    // setLoading(true)
    authService
      .login(user)
      .then((response) => {
        localStorage.setItem("userType", response?.userData?.userType);
        if (response.status === 200) {
          swal("Success!", response.message, "success");
          if (pathname == "/") {
            window.location.reload();
          }
          navigate("/");
          setUserDetails({ ...user, email: "", password: "" });
          dispatch(login(response.userData));
        } else if (response.status === 401) {
          swal("Error!", response.message, "error");
        } else if (response.status === 404) {
          swal("Error!", response.message, "error");
        } else {
          swal("Error!", response.message, "error");
        }
      })
      .catch((err) => {
        swal("Error!", "Something went wrong!", "error");
      });
  };

  const handleClickShowPassword = (inputValue) => {
    inputValue.preventDefault();

    if (inputValue == "password") {
      setShowPassword(!showPassword);
      if (showPassword === false) {
        $("#fa-eye-slash").show();
        $("#fa-eye").hide();
      }
      if (showPassword === true) {
        $("#fa-eye").show();
        $("#fa-eye-slash").hide();
      }
    }
  };

  return (
    <>
      <h2>SIGN IN</h2>
      <form onSubmit={loginHandler}>
        <div className="row">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="floatingInputEmail"
              placeholder="Enter Email"
              // type="email"
              name="email"
              onChange={changeHandler}
              value={user.email.trim().toLowerCase()}
            />
            {user?.email == "" ? (
              <p className={basestyle.error}>{formErrors.email}</p>
            ) : (
              <></>
            )}

            <label htmlFor="floatingInputEmail">Email</label>
          </div>
        </div>
        <div className="row">
          <div className="form-floating mb-3">
            <input
              className="form-control"
              id="password"
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Password"
              onChange={changeHandler}
              value={user.password}
            />
            <label htmlFor="floatingInput">Password</label>
            {/* <span
              className="logineye"
              onClick={() => handleClickShowPassword("password")}
            >
              <span className="fa-solid fa-eye" id="fa-eye"></span>
              <span
                className="fa-solid fa-eye-slash"
                id="fa-eye-slash"
                style={{ display: "none" }}
              ></span>
            </span> */}
            {user?.password == "" ? (
              <p className={basestyle.error}>{formErrors.password}</p>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="fpLink">
          <NavLink to="/forgotPassword">Forgot Password?</NavLink>
        </div>
        <div className="text-center">
          <button className="p-2 ppmBtn" type="submit">
            Sign In
          </button>
        </div>
      </form>
      <div className="newRegister">
        <span>New Member?</span>
        <NavLink to="/register">Register Now</NavLink>
      </div>
    </>
  );
};
export default Login;
