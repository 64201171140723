import axios from "axios";
import { API_URL } from "../constants/constants";

const orderServices = {
  placeOrder: async (userDetail) => {
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    const response = await axios
      .post(API_URL + "/user/dashboard/placeOrder", userDetail, {
        headers: { Authorization: token },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },

  getOrdres: async () => {
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    const response = await axios
      .get(API_URL + "/user/dashboard/getOrder", {
        headers: { Authorization: token },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },

  getAllUserCoupon: async () => {
    const token = await JSON.parse(localStorage.getItem("userInfo"));

    const response = await axios
      .get(API_URL + "/user/dashboard/getAllUserCoupon", {
        headers: { Authorization: token },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },

  getOrderById: async (id) => {
    const response = await axios
      .get(API_URL + `/user/dashboard/getOrderById/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },

  getUserDetailById: async (id) => {
    let newId = id.replace(/['"]+/g, "");
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    const response = await axios
      .get(API_URL + `/user/dashboard/getUserDetailsById/${newId}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },

  getOrderStatus: async (bodyParameters) => {
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    const fedExToken = localStorage.getItem("fedex_token");

    const response = await axios
      .post(API_URL + "/user/dashboard/orderStatus", bodyParameters, {
        headers: {
          Authorization: token,
          auth: fedExToken,
        },
      })
      .then((response) => {
        // return
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },
};

export default orderServices;
