import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import cartService from "../services/cart-service";
import brandService from "../services/brand-service";
import {
  getAllCategories,
  AddWishlist,
  AddCart,
  searchProductByCategory,
} from "../redux/actions/catagoryAction";
import { getAllBrands } from "../redux/actions/brandAction";
import productService from "../services/product-catagorie-service";
import { getProductById } from "../redux/actions/productAction";
import { useDispatch } from "react-redux";
import Filter from "../components/filter/Filter";
import { toast } from "react-toastify";
import wishListService from "../services/wishlist-service";
import { API_URL } from "../constants/constants";
import ReactPaginate from "react-paginate";
import axios from "axios";
import isUrl from "is-url";

const ProductListing = (props) => {
  const _searchedData = props.searchedData;
  const cartDataStore = props.cartData || [];
  const _products = props?.products?.products || [];
  const prod_count = props?.products?.productsCount || 0;
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [prod, setProd] = useState([]);
  // const [prod, setProd] = useState([]);
  const wishListDataStore = props.wishlistData || [];
  // const [cartProduct, setCartProduct] = useState([])
  // const [products, setProducts] = useState('');
  const [pageCount, setpageCount] = useState(0);
  const [currentPage, setCurrent] = useState(1);
  const [numDays, setNumDays] = useState(0);
  let limit = 24;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let previousPage = localStorage.getItem("ProductListingPageCount");

  useEffect(() => {
    productService
      .getCategories(categories)
      .then((res) => {
        dispatch(getAllCategories(res.categories));
        setCategories(res.categories);
      })
      .catch((err) => {
        console.log(err);
      });

    brandService
      .getBrands(brands)
      .then((res) => {
        dispatch(getAllBrands(res.brands));
        setBrands(res.brands);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const total = prod_count || 0;

    setpageCount(Math.ceil(total / limit));
    setProd(_products);
  }, [_searchedData?.searchString]);

  const fetchProductById = (id) => {
    localStorage.setItem("isProductListingVisited", true);
    productService
      .getProductsById(id)
      .then((res) => {
        if (res.status === 200) {
          // dispatch(getProductById(res.product))
          navigate(`/productDetail/${id}`);
          window.location.reload(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Add wishlist

  const WishlistHandler = async (product) => {
    props.AddWishlist(product);
    const addedWishlistProduct = wishListDataStore.find(
      (wishlistRecord) => wishlistRecord.id === product?._id
    );
    const wishlistData = [
      {
        productId: addedWishlistProduct?.id,
      },
    ];
    await wishListService
      .addProductToWishlist(wishlistData)
      .then((res) => {
        toast.success(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Add to cart
  const AddCartHandler = async (product) => {
    props.AddCart(product);
    const addedProduct = cartDataStore.find(
      (cartRecord) => cartRecord.id === product?._id
    );
    const cartData = [
      {
        productId: addedProduct?.id,
        quantity: addedProduct?.quantity,
      },
    ];
    await cartService
      .addProductToCart(cartData)
      .then((res) => {
        toast.success(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchProducts = async (currentPage) => {
    const res = await axios.post(
      `${API_URL}/admin/product/searchProductByName?limit=${limit}&page=${currentPage}`,
      _searchedData
    );

    const data = await res?.data?.products;
    setProd(data);
    return data;
  };

  const searchString = localStorage.getItem("searchString");

  const fetchProductsByBackButton = async (currentPage, payload) => {
    const res = await axios.post(
      `${API_URL}/admin/product/searchProductByName?limit=${limit}&page=${currentPage}`,
      payload
    );

    const data = await res?.data;
    setProd(data?.products);
    return data;
  };

  const handlePageClick = async (data) => {
    const postData = _searchedData;
    let currentPage = data.selected + 1;

    localStorage.setItem("isProductListingVisited", false);
    let arr = [1];
    arr.push(data.selected + 1);
    const pageNumber = data.selected + 1;
    const existArr = JSON.parse(localStorage.getItem("ProductListingArr"));
    if (existArr) {
      const findIndex = existArr.findIndex((v) => v == pageNumber);
      if (findIndex == -1) {
        existArr.push(data.selected + 1);
        localStorage.setItem("ProductListingArr", JSON.stringify(existArr));
      } else {
        localStorage.setItem("ProductListingArr", JSON.stringify(existArr));
      }
    } else {
      localStorage.setItem("ProductListingArr", JSON.stringify(arr));
    }
    localStorage.setItem("ProductListingPageCount", data.selected + 1);

    const nextProducts = await fetchProducts(currentPage);
    dispatch(
      searchProductByCategory({
        searchedProduct: nextProducts,
        searchString: postData,
      })
    );

    // setBrands(nextBrands);
    // scroll to the top
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    addEventListener("popstate", (e) => {
      (async () => {
        const initialArr = JSON.parse(
          localStorage.getItem("ProductListingArr")
        );
        e.preventDefault();
        const isProductListingVisited = localStorage.getItem(
          "isProductListingVisited"
        );
        if (isProductListingVisited === "false") {
          if (initialArr.length > 1) {
            navigate(`/productListing`);
            setTimeout(async () => {
              const arrLength = initialArr.length;
              const secondLastPage = initialArr[arrLength - 2];
              initialArr.pop();
              localStorage.setItem("ProductListingPageCount", secondLastPage);
              localStorage.setItem(
                "ProductListingArr",
                JSON.stringify(initialArr)
              );
              let userId = localStorage.getItem("userId");
              const payload = {
                searchString: searchString,
                userId: userId,
              };
              const nextProducts = await fetchProductsByBackButton(
                secondLastPage,
                payload
              );

              dispatch(
                searchProductByCategory({
                  searchedProduct: nextProducts?.products,
                  searchString: searchString,
                })
              );
              const total = nextProducts?.productsCount || 0;
              setpageCount(Math.ceil(total / limit));
              window.scrollTo(0, 0);
            }, 500);
          } else {
            localStorage.removeItem("ProductListingArr");
            localStorage.removeItem("ProductListingPageCount");
            localStorage.removeItem("isProductListingVisited");
            navigate("/");
          }
        }
      })();
    });
  }, []);

  useEffect(() => {
    const initialArr = JSON.parse(localStorage.getItem("ProductListingArr"));
    let userId = localStorage.getItem("userId");
    (async () => {
      let lastElement = 1;
      if (initialArr && initialArr.length > 0) {
        lastElement = initialArr[initialArr.length - 1];
      }
      if (searchString) {
        const payload = {
          searchString: searchString,
          userId: userId,
        };
        setTimeout(async () => {
          const res = await axios.post(
            `${API_URL}/admin/product/searchProductByName?limit=${limit}&page=${lastElement}`,
            payload
          );

          const data = await res?.data?.products;
          const total = res?.data?.productsCount || 0;
          localStorage.setItem("isProductListingVisited", false);
          setpageCount(Math.ceil(total / limit));
          setProd(data);
          return data;
        }, 1000);
      }
    })();
  }, []);

  const handleFilter = (x) => {
    //  const { data } = response;
    let products = props?.products?.products || [];
    if (products.length > 0) {
      if (x == "4") {
        products = products.sort((a, b) => {
          if (b.price > a.price) return -1;
          else if (a.price > b.price) return 1;
          else return 0;
        });
      } else if (x == "3") {
        products = products.sort((a, b) => {
          if (b.price < a.price) return -1;
          else if (a.price < b.price) return 1;
          else return 0;
        });
      }
    }
    let productLength = products.length;

    dispatch(
      searchProductByCategory({
        searchedProduct: {
          status: 200,
          products: products,
          productsCount: productLength,
        },
        searchString: _searchedData,
      })
    );
  };

  const getDays = () => {
    brandService
      .getNoofDays()
      .then((res) => {
        setNumDays(res?.newTagDays?.days);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    //Runs only on the first render

    getDays();
  }, []);
  const date = new Date();

  return (
    <>
      <nav className="breadcrumb">
        <div className="container">
          <div className="row">
            <ul>
              <li>
                <NavLink to="/">
                  Home
                  <span className="ms-3">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/products">Products</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="mainContent">
        {/* <div className="container bgContent">
          <div className="row">
            <div className="pageTitle">
              <h1>Products</h1>
              <p>Products</p>
            </div>
          </div>
        </div> */}

        <div className="container bgContent">
          <div className="row">
            {/* <div className="pageTitle">
              <h1>Products</h1>
              <p>Products</p>
            </div> */}
          </div>
          <p
            style={{
              textAlign: "center",
              padding: "50px",
              position: "relative",
            }}
          >
            <span className="cate-heading">Searched Products</span>
            <img
              src="../images/textbanner.png"
              alt=""
              className="cate-image"
            ></img>
          </p>
        </div>

        {/* {console.log( _products?.map((nm)=>{
                    <p>{nm.name}</p>
                }))} */}
        <div className="container">
          <div className="row searchResults">
            <p className="text-center">
              Search Result: "
              {searchString ? searchString : _searchedData?.searchString}"
            </p>
          </div>
        </div>
        <div className="container">
          <div className="row mt-5 ustify-content-center">
            {/* <Filter /> */}
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-9 col-sm-9 col-xs-12">
              {/* {prod?.length > 0 ? (
                <div className="row justify-content-end">
                  <div className="form-floating sortListing mb-5">
                    <select
                      className="form-select"
                      id="floatingSelect"
                      aria-label="Floating label select example"
                      onChange={(e) => handleFilter(e.target.value)}
                    >
                      <option defaultValue="1"></option>
                      <option value="3">Price High to Low</option>
                      <option value="4">Price Low to High</option>
                    </select>
                    <label className="dropdownLabel" htmlFor="floatingSelect">
                      Sort By
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )} */}
              {prod?.length === 0 ? (
                <div style={{ textAlign: "center" }}>
                  <p>Sorry there are no products for this requirement!</p>
                  <Link className="btn btn-secondary" to="/products">
                    Shop Now
                  </Link>
                  <button></button>
                </div>
              ) : (
                <div className="pListingMain">
                  {/* {!_products.length && <div>No Product Found</div>} */}
                  {!!prod?.length &&
                    prod?.map((product, index) => {
                      let oldDate = new Date(product?.createdAt);

                      let Difference_In_Time =
                        date?.getTime() - oldDate?.getTime();

                      // To calculate the no. of days between two dates
                      let Difference_In_Days =
                        Difference_In_Time / (1000 * 3600 * 24);

                      return (
                        <>
                          <div className="productListing" key={index}>
                            <a href={`/productDetail/${product?._id}`}>
                              <div className="plImage">
                                {product?.image?.length ? (
                                  // product.image.map((img) => (
                                  //   <>
                                  //     {isUrl(img.url) ? (
                                  //       <img
                                  //         key={img.id}
                                  //         src={img.url}
                                  //         width="100%"
                                  //         alt={img.name}
                                  //       ></img>
                                  //     ) : (
                                  //       <img
                                  //         key={img.id}
                                  //         src={API_URL + "/" + product.image[0].url}
                                  //         width="100%"
                                  //         alt={img.name}
                                  //       ></img>
                                  //     )}
                                  //   </>
                                  // ))

                                  <>
                                    <img
                                      src={
                                        isUrl(product?.image[0]?.url)
                                          ? product?.image[0]?.url
                                          : product?.image[0]?.url == null
                                          ? "./images/dummyProduct.jpg"
                                          : API_URL +
                                            "/" +
                                            product?.image[0]?.url
                                      }
                                      width="100%"
                                      alt="Product image coming soon"
                                    ></img>
                                  </>
                                ) : (
                                  <img
                                    src="./images/productListing.png"
                                    alt="dummyimage"
                                  ></img>
                                )}
                                {Difference_In_Days < numDays && (
                                  <span>New</span>
                                )}
                                {/* <span>New</span> */}
                              </div>
                            </a>
                            <div className="plInfo">
                              <div className="reviews">
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                                <i className="fa-solid fa-star"></i>
                              </div>
                              <p>{product?.name}</p>
                              {/* <p>$ {product?.price}</p> */}
                              <div className="dealsPrice mb-3">
                                <span>
                                  {/* ${product?.oldPrice ? product.oldPrice : product.price} */}
                                  {product?.oldPrice
                                    ? "$" + product?.oldPrice
                                    : null}
                                </span>
                                <span>${product?.price}</span>
                              </div>
                            </div>
                            <div className="plBtns">
                              <button
                                className="atcBtn"
                                onClick={() => AddCartHandler(product)}
                              >
                                Add to Cart
                              </button>
                              <button
                                className="productBtn"
                                onClick={() => WishlistHandler(product)}
                              >
                                <i className="fa-solid fa-heart"></i>
                              </button>
                              <button
                                className="productBtn"
                                onClick={() => fetchProductById(product._id)}
                              >
                                <i className="fa-solid fa-eye"></i>
                              </button>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              )}
              {/* <div className="ppmpagination">
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-end">
                                        <li className="page-item disabled">
                                            <a className="page-link">Previous</a>
                                        </li>
                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">Next</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div> */}

              {prod?.length == 0 ? (
                ""
              ) : (
                <>
                  <div className="pagination_space">
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      forcePage={
                        previousPage ? previousPage - 1 : currentPage - 1
                      }
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    cartData: state?.catagoryReducer?.Carts,
    searchedData: state?.catagoryReducer?.products?.searchString,
    wishlistData: state?.catagoryReducer?.Wishlists,
    products: state?.catagoryReducer?.products?.searchedProduct,
    allproducts: state?.productReducer?.products,
    isLoggedIn: state?.auth?.isLoggedIn,
    allCategories: state?.catagoryReducer?.categories,
    allBrands: state?.brandReducer?.brands,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    AddCart: (product) => dispatch(AddCart(product)),
    AddWishlist: (product) => dispatch(AddWishlist(product)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductListing);
