import axios from "axios";
import { API_URL } from "../constants/constants";
import StateCode from "../../src/StateCode.json";
import {
  grant_type,
  client_id,
  client_secret,
  CITY,
  STATE,
  POSTALCODE,
  COUNTRYCODE,
  ACCOUNTNUMBER,
  STREETLINES,
  COMPANYNAME,
  PHONENUMBER,
  PERSONNAME,
  WEIGHTUNIT,
  HEIGHTUNIT,
} from "../constants/constants";

// Function to calculate the volume of a package
function calculateVolume(length, width, height, quantity) {
  return length * width * height * quantity;
}

let fit = {};
const boxSizeArr = [
  { length: 8, width: 6, height: 4 },
  { length: 12, width: 6, height: 6 },
  { length: 4, width: 4, height: 48 },
  { length: 20, width: 20, height: 2 },
  { length: 14, width: 14, height: 6 },
  { length: 13, width: 3, height: 30 },
  { length: 15, width: 15, height: 5 },
  { length: 26, width: 8, height: 8 },
  { length: 6, width: 6, height: 48 },
  { length: 12, width: 12, height: 12 },
  { length: 6, width: 6, height: 72 },
  { length: 20, width: 20, height: 7 },
  { length: 30, width: 5, height: 24 },
  { length: 20, width: 20, height: 9 },
  { length: 20, width: 20, height: 11 },
  { length: 31, width: 7, height: 23 },
  { length: 20, width: 20, height: 13 },
  { length: 60, width: 10, height: 10 },
  { length: 26, width: 18, height: 16 },
  { length: 14, width: 14, height: 48 },
  { length: 54, width: 8, height: 28 },
  { length: 22, width: 22, height: 36 },
];

function findPackageWithHighestVolume(packages) {
  // let bestFitSize = null;
  let maxHeight = 0;
  let maxLength = 0;
  let maxWidth = 0;
  let totalWeight = 0;
  if (packages && packages.length > 0 && packages[0].dimensions) {
    maxHeight = packages[0].dimensions?.height;
    maxLength = packages[0].dimensions?.length;
    maxWidth = packages[0].dimensions?.width;
  }

  packages.map((a) => {
    totalWeight += a?.weight?.value;
    let th = a.dimensions?.height;
    let tl = a.dimensions?.length;
    let tw = a.dimensions?.width;
    if (th > maxHeight) maxHeight = th;
    if (tl > maxLength) maxLength = tl;
    if (tw > maxWidth) maxWidth = tw;
  });

  let i = 0;
  while (i < boxSizeArr.length) {
    let a = boxSizeArr[i];
    if (a.length >= maxLength && a.width >= maxWidth && a.height >= maxHeight) {
      fit = a;
      break;
    }
    i++;
  }
  if (Object.keys(fit).length === 0 && fit.constructor === Object) {
    // fit = boxSizeArr[boxSizeArr.length - 1];
    fit = { length: 20, width: 20, height: 11 };
  }

  const newObj = {
    groupPackageCount: 1,
    weight: {
      value: totalWeight,
      units: "LB",
    },
    dimensions: {
      length: fit?.length,
      width: fit?.width,
      height: fit?.height,
      units: "IN",
    },
  };
  return newObj;
}

const fedexServices = {
  getFedexToken: async () => {
    const params = new URLSearchParams();
    params.append("grant_type", grant_type);
    // params.append("client_id", client_id);
    // params.append("client_secret", client_secret);

    const token = await JSON.parse(localStorage.getItem("userInfo"));
    const response = await axios
      .post(API_URL + "/user/dashboard/fedexApiAuth", params, {
        headers: { Authorization: token },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },
  getFedexStatus: async () => {
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    const response = await axios
      .get(API_URL + "/admin/admindashboard/getFedexPermission", {
        headers: { Authorization: token },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },

  fedexAddessVerification: async (userDetails, cartData) => {
    const token = localStorage.getItem("fedex_token");

    const { state, shipState, city, zip, shipZip } = userDetails;

    let newZip = shipZip || zip;
    let newShip = shipState || state;

    if (newZip.length <= 4) {
      return false;
    }

    const responseData = cartData?.map((item) => {
      return {
        groupPackageCount: item.quantity,
        weight: {
          value: item?.weight || 1,
          units: "LB",
        },
        dimensions: {
          length: item?.length || 2,
          width: item?.width || 2,
          height: item?.height || 2,
          units: "IN",
        },
      };
    });

    // Find the package with the highest volume
    const packageWithHighestVolume = findPackageWithHighestVolume(responseData);

    // console.log(
    //   "129 Package with the highest volume: packages",
    //   packageWithHighestVolume
    // );

    let stateCodes = Object.keys(StateCode).filter(
      (key) => StateCode[key] === newShip
    );
    stateCodes = stateCodes.length ? stateCodes[0] : "";

    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    // This arrangement can be altered based on how we want the date's format to appear.
    let currentDate = `${year}-${day}-${month}`;
    // console.log(currentDate); // "17-6-2022"

    let data = {
      carrierCode: "FDXG",
      countryCode: COUNTRYCODE,
      stateOrProvinceCode: stateCodes,
      postalCode: newZip,
      shipDate: "2023-04-27",
      accountNumber: {
        value: ACCOUNTNUMBER,
      },
      requestedShipment: {
        shipper: {
          address: {
            stateOrProvinceCode: STATE,
            postalCode: POSTALCODE,
            countryCode: COUNTRYCODE,
            residential: false,
          },
        },
        recipient: {
          address: {
            stateOrProvinceCode: stateCodes,
            postalCode: newZip,
            countryCode: COUNTRYCODE,
            residential: true,
          },
        },
        pickupType: "DROPOFF_AT_FEDEX_LOCATION",
        packagingType: "YOUR_PACKAGING",
        serviceType: "",
        rateRequestType: ["LIST", "ACCOUNT"],
        requestedPackageLineItems: [packageWithHighestVolume],
      },
    };

    const response = await axios
      .post(API_URL + "/user/dashboard/fedexPostalCodeVerification", data, {
        headers: { auth: token },
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },
};

export default fedexServices;
