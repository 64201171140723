import { connect } from "react-redux";
import React from "react";

const BrandFilter = (props) => {
  const all_brands = [...props?.allSideBrands?.sort()] || [];
  //   const all_brands = props.allBrands || [];
  let allBrand = JSON.parse(localStorage.getItem("filteredData"));

  return (
    <>
      <div className="leftSideFilter">
        <div className="filterTitle">
          <div className="searchDiv">
            <p>brand</p>
            {/* <div className="plSearch">
                            <i className="fa-solid fa-magnifying-glass"></i>
                        </div> */}
          </div>
          <div className="searchInput d-none">
            <input type="search" placeholder="Search for Categories" />
          </div>
        </div>
        {!!all_brands.length &&
          all_brands.slice(0, 4).map((brand, index) => {
            return (
              <div className="filterCategories" key={index}>
                <input
                  className="me-3"
                  type="checkbox"
                  value={brand?._id}
                  onClick={(e) => props.onBrandChecked(e)}
                  checked={
                    allBrand?.brandId.filter((ct) => ct === brand._id).length >
                    0
                  }
                />
                <label>{brand.name}</label>
              </div>
            );
          })}
        <div className="filterCategories dropdown justify-content-center">
          <a
            className="dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            +More
          </a>
          <div className="filterModal dropdown-menu">
            <div className="modalHeader">
              {/* <div className="searchInput"><input placeholder="Find Your Product" type="search" /></div> */}
              <div className="modalClose">
                <i className="fa-solid fa-xmark"></i>
              </div>
            </div>
            <div className="modalBody">
              <div className="row">
                {!!all_brands &&
                  all_brands
                    // .sort(function (a, b) {
                    //   if (a.name[1].toUpperCase() > b.name[1].toUpperCase()) {
                    //     return -1;
                    //   }
                    //   if (a.name[1].toUpperCase() < b.name[1].toUpperCase()) {
                    //     return 1;
                    //   }
                    //   return 0;
                    // })
                    .map((brand, index) => {
                      return (
                        <div
                          className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12"
                          key={index}
                        >
                          <div className="alphabetDiv mb-5">
                            <div className="searchDiv">
                              <p>
                                {JSON.stringify(brand?.name)[1].toUpperCase()}
                              </p>
                            </div>
                            <div className="filterCategories">
                              <input
                                className="me-3"
                                type="checkbox"
                                value={brand._id}
                                onClick={(e) => props.onBrandChecked(e)}
                                checked={
                                  allBrand?.brandId.filter(
                                    (ct) => ct === brand._id
                                  ).length > 0
                                }
                              />
                              <label>{brand.name}</label>
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    products: state?.catagoryReducer?.products,
    allproducts: state?.productReducer?.products,
    allCategories: state?.catagoryReducer?.categories,
    allBrands: state?.brandReducer?.brands,
    allSideBrands: state?.brandReducer?.sideBrands,
  };
}

export default connect(mapStateToProps)(BrandFilter);
