import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../constants/constants";

const DistributorTools = () => {
  const [data, setData] = useState([]);

  const getData = async () => {
    const { data } = await axios.get(
      API_URL + "/admin/uploadfile/getDistributerTools"
    );
    setData(data?.PDF);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <section>
      <nav className="breadcrumb">
        <div className="container">
          <div className="row">
            <ul>
              <li>
                <Link to="/">
                  Home
                  <span className="ms-3">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </Link>
              </li>
              <li>
              <Link to={{}}>Distributor’s Tools</Link>
              </li>
            </ul>
          </div> 
        </div>
      </nav>
      <div className="mainContent">
        {/* <div className="container bgContent">
          <div className="row">
            <div className="pageTitle">
              <h1>Distributor’s Tools</h1>
              <p>Distributor’s Tools</p>
            </div>
          </div>
        </div> */}

<div className="container bgContent">
              <div className="row">
                {/* <div className="pageTitle">
              <h1>Products</h1>
              <p>Products</p>
            </div> */}
              </div>
              <p
                style={{
                  textAlign: "center",
                  padding: "50px",
                  position: "relative",
                }}
              >
                <span className="cate-heading">Distributor Tools</span>
                <img
                  src="../images/textbanner.png"
                  alt=""
                  className="cate-image"
                ></img>
              </p>
            </div>

        <div className="container">
          <div className="dPdfMain mt-5">
            {data?.map((item, index) => (
              <div className="pdfListing mb-5" key={index}>
                <div className="pdfImage">
                  <img src="images/pdfIcon.png" />
                </div>
                <div className="pdfbottomDiv dTools">
                  <p className="pdfTitle">{item?.name}</p>
                  <div className="pdfBtns cardbtn">
                    <a
                      target="_blank"
                      rel="noopener"
                      href={API_URL + `/` + `${item?.pdf?.url}`}
                      className="ppmBtn"
                    >
                      View
                    </a>
                    {/* <button className="ppmBtn">Download</button> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DistributorTools;
