import React, { useEffect, useState } from "react";
import basestyle from "../Base.module.css";
import swal from "sweetalert";
import "react-toastify/dist/ReactToastify.css";
import authService from "../../../services/auth.service";
import { useNavigate, NavLink } from "react-router-dom";
import Cities from "../../../Cities.json";
import axios from "axios";

const Register = () => {
  const navigate = useNavigate();
  const [country, setCountry] = useState([]);
  const [state, setState] = useState("");
  const [city, setCity] = useState('');
  const [newcity, setNewCity] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [user, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    addressLine1: "",
    addressLine2: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    file: null,
    isMannualSignup: true,
  });

  // const [isMannualSignup, setIsMannualSignup] = useState(false)

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...user,
      [name]: value,
    });
  };

  const countries = [{ id: "1", name: "USA" }];

  const handleCountry = (countryName) => {
    setUserDetails({ ...user, country: countryName });
    setCountry(countryName);
  };
  const stateUS = Object.keys(Cities).sort();

  const handleState = (stateName) => {
    setUserDetails({ ...user, state: stateName });
    setState(stateName);
    let sortedCities = Cities[stateName].sort();
    setCity(sortedCities);
  };
  let cityName;
  const handleCity = (cityName) => {
    setNewCity(cityName);
    setUserDetails({ ...user, city: cityName });
  };

  const validateForm = (values) => {
    const error = {};
    const emailRegex = /^[^\s+@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const postalCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    const phoneRegex = /^[0-9\b]+$/;
    if (!values.firstName.trim()) {
      error.fname = "First Name is required";
    } else if (!values.firstName.match(/^[a-zA-Z ]*$/)) {
      error.fname = "Please enter valid first name";
    } else if (values.firstName.length > 16) {
      error.fname = "First name value cannot exceed more than 16 characters";
    }
    if (!values.lastName.trim()) {
      error.lname = "Last Name is required";
    } else if (!values.lastName.match(/^[a-zA-Z ]*$/)) {
      error.lname = "Please enter valid last name";
    } else if (values.lastName.length > 16) {
      error.lname = "Last name value cannot exceed more than 16 characters";
    }
    if (!values.email) {
      error.email = "Email is required";
    } else if (!emailRegex.test(values.email)) {
      error.email = "This is not a valid email format";
    }
    if (!values.password) {
      error.password = "Password is required";
    } else if (values.password.length < 8) {
      error.password = "Password must be more than 8 characters";
    } else if (values.password.length > 16) {
      error.password = "Password cannot exceed more than 16 characters";
    }
    // if (!values.cpassword) {
    //     error.cpassword = "Confirm Password is required";
    // } else if (values.cpassword !== values.password) {
    //     error.cpassword = "Confirm password and password should be same";
    // }
    if (values.country == "Select" || values.country == "") {
      error.country = "Country is required";
    }
    if (state == "Select" || !state) {
      error.state = "State is required";
    }
    if (city == "Select" || !city) {
      error.city = "City is required";
    }

    if (!values.phone) {
      error.phone = "Phone no. is required";
    } else if (!/^[0-9\b]+$/.test(values.phone) || values.phone.length !== 10) {
      error.phone = "Please enter a 10-digit phone no.";
    }
    if (!values.addressLine1) {
      error.addressLine1 = "Address Line1 required";
    }
    // if (!values.country) {
    //     error.country = "Country required";
    // }
    // if (!values.state) {
    //     error.state = "State required";
    // }
    // if (!values.city) {
    //     error.city = "City is required";
    // }
    if (!values.zip) {
      error.zip = "Zip Code required";
    } else if (!postalCodeRegex.test(values.zip)) {
      error.zip = "Please enter a 5-digit zip code.";
    }
    return error;
  };
  const signupHandler = async (e) => {
    e.preventDefault();
    const validate = validateForm(user);
    setFormErrors(validate);
    if (!!Object.keys(validate).length) {
      setFormErrors(validate);
      return;
    }
    setIsSubmit(true);
    // setIsMannualSignup(true)
    authService
      .signup(user)
      .then((res) => {
        if (res.status == 200) {
          swal("Success!", res.message, "success");
          navigate("/");
        } else if (res.status == 403) {
          swal("Error!", res.message, "error");
        } else {
          swal("Error!", res.message, "error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // console.log(user);
    }
  }, [formErrors]);

  useEffect(() => {
    setCountry(countries);
  }, []);


  const [myState, setMyState] = useState("");
  const [zipData, setZipData] = useState("");

  const zipCodeLookUp = async () => {
    try {
      console.log('user.zip', user.zip);
      const zipResponse = await axios.get(
        `https://api.zippopotam.us/us/${user.zip}`,
        true
      );
      let stateCode = zipResponse.data.places[0];

      setMyState(stateCode?.state);
      setZipData(zipResponse.data.places[0]);
      setState(stateCode?.state)
      setCity(stateCode["place name"])
      setUserDetails({
        ...user,
        state: stateCode?.state,
        city: stateCode["place name"],
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let zip = user?.zip;
    if (zip?.length == 5) {
      zipCodeLookUp();
    }
  }, [user.zip, myState]);

  return (
    <section>
      {/* <BreadCrumb /> */}
      <nav className="breadcrumb">
        <div className="container">
          <div className="row">
            <ul>
              <li>
                <NavLink to="/">
                  Home
                  <span className="ms-3">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/register">Registration</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="mainContent">
        <div className="container bgContent">
          {/* <div className="row">
                        <div className="socialRegisteration">
                            <div className="socialLoginBtn">
                                <a href="#">
                                    <div className="me-3"><img src="images/facebook.png" /></div><div>Register with Facebook</div>
                                </a>
                            </div>
                            <div className="socialLoginBtn">
                                <a href="#">
                                    <div className="me-3"><img src="images/google.png" /></div><div>Register with Gmail</div>
                                </a>
                            </div>
                        </div>
                        <p className="slText">OR</p>
                    </div> */}
          <div className="row u_btn">
            {/* <input type="file" id="actual-btn" className="upload_btn"  name='file' onChange={changeHandler} /> */}
            {/* <input
              type="file"
              id="actual-btn"
              className="w-auto"
              onChange={changeHandler}
              name="file"
            /> */}
            {/* <label className="upload_btn" htmlFor="actual-btn">Upload Photo</label> */}
          </div>
          <div className="row detailsTitle">
            <p>Personal Details </p>
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="formDetails mb-3">
                  <label className="form-label">
                    First Name <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter First Name"
                    name="firstName"
                    id="firstName"
                    onChange={changeHandler}
                    value={user.firstName}
                  />
                  <p className={basestyle.error}>{formErrors.fname}</p>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="formDetails mb-3">
                  <label className="form-label">
                    Last Name <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter Last Name"
                    name="lastName"
                    id="lastName"
                    onChange={changeHandler}
                    value={user.lastName}
                  />
                  <p className={basestyle.error}>{formErrors.lname}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="formDetails mb-3">
                  <label className="form-label">
                    Email Address <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    // type="email"
                    placeholder="Enter Email Address"
                    name="email"
                    id="email"
                    onChange={changeHandler}
                    value={user.email.trim().toLowerCase()}
                  />
                  <p className={basestyle.error}>{formErrors.email}</p>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="formDetails mb-3">
                  <label className="form-label">
                    Password <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="password"
                    placeholder="Enter password"
                    name="password"
                    id="password"
                    onChange={changeHandler}
                    value={user.password}
                  />
                  <p className={basestyle.error}>{formErrors.password}</p>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="formDetails mb-3">
                  <label className="form-label">
                    Phone Number <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Enter Phone Number"
                    name="phone"
                    id="phone"
                    onChange={changeHandler}
                    value={user.phone}
                  />
                  <p className={basestyle.error}>{formErrors.phone}</p>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row detailsTitle ">
            <p>Address</p>
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="formDetails mb-3">
                  <label className="form-label">
                    Address Line 1 <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Address Line 1"
                    name="addressLine1"
                    id="addressLine1"
                    onChange={changeHandler}
                    value={user.addressLine1}
                  />
                  <p className={basestyle.error}>{formErrors.addressLine1}</p>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="formDetails mb-3">
                  <label className="form-label">Address Line 2</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Address Line 2"
                    name="addressLine2"
                    id="addressLine2"
                    onChange={changeHandler}
                    value={user.addressLine2}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="form-floating mb-3">
                  <select
                    className="form-select"
                    aria-label="Floating label select example"
                    onChange={(e) => handleCountry(e.target.value)}
                    name="country"
                    id="country"
                  >
                    <option>Select</option>
                    {countries.map((ctr, index) => {
                      return (
                        <option key={index} value={ctr.name}>
                          {ctr.name}
                        </option>
                      );
                    })}
                  </select>
                  <label className="dropdownLabel" htmlFor="floatingSelect">
                    Country <span>*</span>
                  </label>
                  <p className={basestyle.error}>{formErrors.country}</p>
                </div>
              </div>
              {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="form-floating mb-3">
                  <select
                    className="form-select"
                    aria-label="Floating label select example"
                    onChange={(e) => handleState(e.target.value)}
                    name="state"
                    id="state"
                  >
                    <option selected="">Select</option>
                    {stateUS && stateUS !== undefined
                      ? stateUS.map((ctr, index) => {
                        return (
                          <option key={index} value={ctr}>
                            {ctr}
                          </option>
                        );
                      })
                      : "No State"}
                  </select>
                  <label className="dropdownLabel" htmlFor="floatingSelect">
                    State <span>*</span>
                  </label>
                  <p className={basestyle.error}>{formErrors.state}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="form-floating mb-3">
                  <select
                    className="form-select"
                    aria-label="Floating label select example"
                    onChange={(e) => handleCity(e.target.value)}
                    name="city"
                    id="city"
                  >
                    <option selected="">Select</option>
                    {city && city !== undefined
                      ? city?.map((ctr, index) => {
                        return (
                          <option key={index} value={ctr}>
                            {ctr}
                          </option>
                        );
                      })
                      : "No City"}
                  </select>
                  <label className="dropdownLabel" htmlFor="floatingSelect">
                    City<span>*</span>
                  </label>
                  <p className={basestyle.error}>{formErrors.city}</p>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="formDetails  mb-3">
                  <label className="form-label">
                    Zip Code <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Zip Code"
                    name="zip"
                    id="zip"
                    onChange={changeHandler}
                    value={user.zip}
                  />
                  <p className={basestyle.error}>{formErrors.zip}</p>
                </div>
              </div> */}
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Zip Code"
                    name="zip"
                    id="zip"
                    onChange={changeHandler}
                  />
                  <p className={basestyle.error}>{formErrors.zip}</p>
                  <label htmlFor="floatingInput">
                    Zip Code <span style={{ color: "red" }}>*</span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="state"
                      id="state"
                      value={user.state}
                      onChange={changeHandler}
                    />
                    <label
                      className="dropdownLabel"
                      htmlFor="floatingSelect"
                    >
                      State <span>*</span>
                    </label>
                    <p className={basestyle.error}>{formErrors.state}</p>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      id="city"
                      value={user.city}
                      onChange={changeHandler}
                    />
                    <label
                      className="dropdownLabel"
                      htmlFor="floatingSelect"
                    >
                      City <span>*</span>
                    </label>
                    <p className={basestyle.error}>{formErrors.city}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12"></div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <button className="btn btn-dark mt-3" onClick={signupHandler}>
                  Register
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Register;
