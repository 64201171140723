import axios from "axios";
import { API_URL } from "../constants/constants";

// const API_URL = "https://PpmRacingProducts-QA.chetu.com/api/admin";
// const token=localStorage.getItem("userInfo", JSON.stringify(response.data.userData.token))
// const token=localStorage.getItem("userInfo");
const brandService = {
  getBrands: async (index) => {
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    const response = await axios
      .get(API_URL + `/admin/brand/getBrands?limit=${index}&page=1`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },

  SideBrands: async () => {
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    const response = await axios
      .get(API_URL + "/admin/brand/getAllBrands", {
        headers: { Authorization: token },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },

  getBrandsById: async (id) => {
    const response = await axios
      .get(API_URL + `/admin/brand/getBrandById/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },

  getProductByBrandId: async (id) => {
    const response = await axios
      .get(API_URL + `/admin/product/getProductsByBrand/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },

  getNoofDays: async () => {
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    const response = await axios
      .get(API_URL + "/admin/admindashboard/getNewTagDays", {
        headers: { Authorization: token },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },
};

export default brandService;
