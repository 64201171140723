const TotalAmount = (cartDataproduct) => {
  let price = 0;
  let discountAmount = 0;
  let discountedPrice = 0;
  cartDataproduct.forEach((item) => {
    price += item?.quantity * item.price;
    discountAmount += item?.quantity * item?.discountAmount;
    discountedPrice += item?.quantity * item?.discountedPrice;
  });
  
  return {
    price,
    discountAmount,
    discountedPrice,
  };
};

export default TotalAmount;
