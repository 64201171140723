export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SEARCH_PRODUCT_BY_CATEGORY = "SEARCH_PRODUCT_BY_CATEGORY";

export const INCREASE_QUANTITY = 'INCREASE_QUANTITY';
export const DECREASE_QUANTITY = 'DECREASE_QUANTITY';
export const GET_NUMBER_CART = 'GET_NUMBER_CART';
export const ADD_CART = 'ADD_CART' ;
export const UPDATE_CART = 'UPDATE_CART';
export const DELETE_CART = 'DELETE_CART';
export const DELETE_WISHLIST = 'DELETE_WISHLIST';

export const GET_NUMBER_WISHLIST = 'GET_NUMBER_WISHLIST';
export const ADD_WISHLIST = 'ADD_WISHLIST' ;
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';
export const MOVE_TO_CART = 'MOVE_TO_CART';

export const GET_ALL_PRODUCT_REQUEST = 'GET_ALL_PRODUCT_REQUEST';
export const GET_ALL_PRODUCT_SUCCUSS = 'GET_ALL_PRODUCT_SUCCUSS';
export const GET_ALL_PRODUCT_FAILED= 'GET_ALL_PRODUCT_FAILED';
export const GET_PRODUCT_BY_ID='GET_PRODUCT_BY_ID'

export const GET_ALL_BRAND_REQUEST = 'GET_ALL_BRAND_REQUEST';
export const GET_ALL_SIDE_BRAND_REQUEST = 'GET_ALL_SIDE_BRAND_REQUEST';
export const GET_BRAND_BY_ID='GET_BRAND_BY_ID';

export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';
export const GET_PRODUCT_BY_CATEGORY_ID='GET_PRODUCT_BY_CATEGORY_ID';
export const GET_SUBCATEGORY_BY_CATEGORY_ID='GET_SUBCATEGORY_BY_CATEGORY_ID';

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const CART_DATA_REQUEST ="CART_DATA_REQUEST"
export const CART_DATA_RESPONSE = 'CART_DATA_RESPONSE'
export const WISHLIST_DATA_REQUEST = 'WISHLIST_DATA_REQUEST'
export const WISHLIST_DATA_RESPONSE= "WISHLIST_DATA_RESPONSE"

export const GET_USER_BY_ID= "GET_USER_BY_ID"
export const UPDATE_USER = 'UPDATE_USER';
export const EMPTY_CART='EMPTY_CART'