import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, NavLink, Link } from "react-router-dom";
import {
  AddCart,
  DeleteCart,
  DecreaseQuantity,
  IncreaseQuantity,
  getCartData,
  cartDataResponse,
  getWishlistData,
  wishlistDataResponse,
} from "../redux/actions/catagoryAction";
import cartService from "../services/cart-service";
import { toast } from "react-toastify";
import GlobalConstants from "../utils/enums";
import TotalAmount from "../utils/cartAmountHelper";
import wishListService from "../services/wishlist-service";
import { API_URL } from "../constants/constants";
import orderServices from "../services/order-services";
import isUrl from "is-url";

const Cart = (props) => {
  const navigate = useNavigate();
  const navigateTo = () => navigate("/checkout");
  const navigateToProducts = () => navigate("/products");
  const [cartProducts, setCartProducts] = useState([]);
  const [cartProductsData, setCartProductsData] = useState([]);
  let productsFromCart = props.cartProducts || [];
  const cartDataproduct = props.cartProductData.data || [];
  const [count, setCount] = useState(1);
  const [isChecked, setIsChecked] = useState(false);
  const [utype, setUtype] = useState("");
  const [couponVal, setCouponVal] = useState("");
  const [couponId, setCouponId] = useState("");
  const [couponList, setCouponList] = useState(null);
  const [couponAmount, setCouponAmount] = useState(0);
  const [couponType, setCouponType] = useState("");
  const [showDiscount, setShowDiscount] = useState(false);
  const [isBackOrder, setIsBackOrder] = useState(false);

  useEffect(() => {
    const isBckOrder = productsFromCart.find(
      (item) => item?.quantityOnHand < item?.quantity
    );
    if (isBckOrder) {
      setIsBackOrder(true);
    } else {
      setIsBackOrder(false);
    }
  }, [productsFromCart]);

  const getTotal = TotalAmount(cartDataproduct);

  const token = JSON.parse(localStorage.getItem("userInfo"));

  const increment = (product) => {
    incDecCartHandler(product, GlobalConstants.QuantityConstant.Increment);
    setCount(count + 1);
    handleWarning();
  };

  const decrement = (product) => {
    if (product.quantity > 0) {
      incDecCartHandler(product, GlobalConstants.QuantityConstant.Decrement);
      setCount(count - 1);
      handleWarning();
    }
  };

  const RemoveCoupon = () => {
    // if (couponAmount > 0 || couponType === "Free Shipping") {
    //   setShowDiscount(!showDiscount);
    // } else {
    //   toast.error("Please hit the check button to apply the coupon");
    // }
    localStorage.removeItem("camount");
    localStorage.removeItem("couponType");
    localStorage.removeItem("couponId");
    window.location.reload();
  };

  const handleSubmit = async () => {
    localStorage.setItem("couponId", couponId);
    const res = await cartService.checkUserCoupon(couponId);

    let price = getTotal?.price;

    if (res?.coupons?.minCartAmount > price) {
      return toast.error(
        "There is a lower cart amount, please choose another coupon code."
      );
    }

    if (res?.coupons?.dollarAmount > 0) {
      setCouponAmount(res?.coupons?.dollarAmount);
      localStorage.setItem("camount", res?.coupons?.dollarAmount);
    }
    if (res?.coupons?.discountPercent > 0) {
      let calculatedPrice =
        (res?.coupons?.discountPercent * getTotal?.price) / 100;
      setCouponAmount(calculatedPrice.toFixed(2));
      localStorage.setItem("camount", calculatedPrice.toFixed(2));
    }

    if (res?.coupons?.couponType === "Free Shipping") {
      setCouponAmount(0);
      localStorage.setItem("camount", 0);
    }

    setCouponType(res?.coupons?.couponType);
    localStorage.setItem("couponType", res?.coupons?.couponType);
    setShowDiscount(!showDiscount);
  };

  const getAllCoupons = async () => {
    const res = await orderServices.getAllUserCoupon();

    setCouponList(res?.coupons);
  };

  useEffect(() => {
    getAllCoupons();
    let camount = localStorage.getItem("camount");
    setCouponAmount(camount);
    const cType = localStorage.getItem("couponType");
    if (cType) {
      setShowDiscount(true);
    }

    setCouponType(cType);
    handleWarning();
  }, []);

  const handleChange = (e) => {
    if (e.target.checked) {
      const cval = e.target.getAttribute("def");
      setCouponVal(cval);
      setCouponId(e.target.id);
    } else {
      setCouponVal("");
      setCouponId("");
      setCouponAmount(0);
      setCouponType("N/A");
    }
  };

  const getCartData = async () => {
    props.getCartData();
    await cartService
      .getProductFromCart()
      .then((response) => {
        if (response.status === 200) {
          const responseData = response.cart.map((item) => {
            return {
              id: item.productId,
              name: item.productName,
              brand: item.brand,
              description: item.description,
              discountAmount: item.discountAmount,
              discountPercent: item.discountPercent,
              discountedPrice: item.discountedPrice,
              image: item.image,
              price: item.price,
              quantity: item.quantity,
              yellowDealerPrice: item.yellowDealerPrice,
              blueDealerPrice: item?.blueDealerPrice,
              length: item?.length ?? 0,
              width: item?.width ?? 0,
              height: item?.height ?? 0,
              weight: item?.weight ?? 0,
              quantityOnHand: item?.quantityOnHand ?? 0,
            };
          });

          props.cartDataResponse(responseData);
        }
      })
      .catch((error) => console.log(error));
  };

  const getWishlistData = async () => {
    props.getWishlistData();
    await wishListService
      .getProductFromWishlist()
      .then((response) => {
        if (response.status === 200) {
          const responseData = response?.wishlist?.map((item) => {
            return {
              _id: item.productId,
              name: item.productName,
              brand: item.brand,
              image: item.image,
              price: item.price,
            };
          });
          props.wishlistDataResponse(responseData);
        }
      })
      .catch((error) => console.log(error));
  };

  const onCartProductCheck = (e) => {
    productsFromCart = 0;
    const crtData = {
      productId: e.target.value,
    };
    if (!e.target.checked) {
      const removedCheck = cartProductsData.filter(
        (find) => find.productId !== crtData.productId
      );
      setCartProductsData([...removedCheck]); //array of objects
    } else {
      setCartProductsData([...cartProductsData, crtData]);
    }
  };

  //   {
  //     "productId": "63b849daab251c9e2a11a396"
  // },
  // {
  //     "productId": "63b849daab251c9e2a11a398"
  // }

  const removeProductFromCart = async (product) => {
    const removeProduct = [
      {
        productId: product.id,
      },
    ];

    await cartService
      .removeProductFromCart(removeProduct)
      .then((res) => {
        getCartData();
        props.DeleteCart(product);
        toast.warn(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const incDecCartHandler = async (product, actionType) => {
    const cartData = {
      productId: product?.id,
    };
    if (actionType === GlobalConstants.QuantityConstant.Increment) {
      await cartService
        .increamentProductQuantityCart(cartData)
        .then((res) => {
          getCartData();
          props.IncreaseQuantity(product);
          toast.success(res.message);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await cartService
        .decreamentProductQuantityCart(cartData)
        .then((res) => {
          getCartData();
          props.DecreaseQuantity(product);
          toast.success(res.message);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const WishlistHandler = async () => {
    // props.getWishlistData()
    if (cartProductsData.length != 0 || isChecked) {
      const mycartProductsData = productsFromCart.map((element) => {
        return {
          productId: element.id,
        };
      });
      let newData;
      if (cartProductsData.length > 0) {
        newData = cartProductsData;
        mycartProductsData.length = 0;
      } else {
        newData = mycartProductsData;
        cartProductsData.length = 0;
      }
      await wishListService
        .addProductToWishlist(newData)
        .then((res) => {
          getCartData();
          getWishlistData();
          toast.success(res.message);
        })
        .catch((err) => {
          console.log(err);
        });
      await cartService
        .removeProductFromCart(newData)
        .then((res) => {
          getCartData();
          getWishlistData();
        })
        .catch((err) => {
          console.log(err);
        });
      navigate("/wishlist");
    } else {
      toast.warn("Please select the items to remove");
    }
  };

  const RemoveHandler = async () => {
    if (cartProductsData.length != 0 || isChecked) {
      const mycartProductsData = productsFromCart.map((element) => {
        return {
          productId: element.id,
        };
      });
      let newData;
      if (cartProductsData.length > 0) {
        newData = cartProductsData;
        mycartProductsData.length = 0;
      } else {
        newData = mycartProductsData;
        cartProductsData.length = 0;
      }
      await cartService
        .removeProductFromCart(newData)
        .then((res) => {
          getCartData();
          // props.DeleteCart(product)
          toast.warn(res.message);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.warn("Please select the items to remove");
    }
  };

  useEffect(() => {
    let uType = localStorage.getItem("userType");
    if (!uType) {
      productsFromCart = [];
    }
    setUtype(uType);
    cartService
      .getProductFromCart(cartProducts)
      .then((res) => {
        setCartProducts(res.cart);
        // props.cartDataResponse()
        getCartData();
        getWishlistData();
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
    setCartProductsData([]);
    if (!isChecked) {
    }
  };

  const handleWarning = () => {
    //   productsFromCart.find((e)=>{
    //     console.log("userinfo",e?.quantityOnHand > e?.quantity)
    //     if(e?.quantityOnHand > e?.quantity){
    //       alert(true)
    //       setShowWarning(true)
    //     }else(
    //       setShowWarning(false)
    //     )
    //   })
  };

  return (
    <>
      <section>
        <nav className="breadcrumb">
          <div className="container">
            {isBackOrder ? (
              <div className="note-card">
                <span style={{ color: "red" }}>
                  {
                    "Note: This Order Will Be Partially Shipped Due To Your Order Quantity Being Higher Than Our Inventory Quantity. For Any Questions Or Concerns, Call (877)560-0619."
                  }
                </span>
              </div>
            ) : (
              ""
            )}
            <div className="row">
              <ul>
                <li>
                  <NavLink to="/">
                    Home
                    <span class="ms-3">
                      <i class="fa-solid fa-angle-right"></i>
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/cart">Cart</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="sdMainContent">
          <div className="container">
            <div className="row cart gx-5">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                <div className="row bgContent">
                  <div className="d-flex justify-content-between p-0">
                    <div className="d-flex align-items-center">
                      {productsFromCart.length == 0 && token ? (
                        <>
                          <p style={{ margin: "10px" }}>
                            No items in the cart{" "}
                          </p>
                          <Link
                            style={{ marginLeft: "30px" }}
                            className="btn btn-secondary"
                            to="/products"
                          >
                            Shop Now
                          </Link>
                        </>
                      ) : !token ? (
                        <>
                          {" "}
                          <Link
                            style={{ marginLeft: "30px" }}
                            className="btn btn-secondary"
                            to="/"
                          >
                            Home page
                          </Link>
                          <p style={{ marginLeft: "30px", marginTop: "20px" }}>
                            Please login to add products in the cart or view
                            cart
                          </p>
                        </>
                      ) : (
                        <>
                          <input
                            className="me-3"
                            type="checkbox"
                            checked={isChecked}
                            name="allselected"
                            onChange={handleOnChange}
                          />
                          <span>
                            {isChecked
                              ? productsFromCart.length
                              : cartProductsData.length}
                            /{productsFromCart.length} items selected
                          </span>
                        </>
                      )}
                    </div>
                    {productsFromCart.length != 0 ? (
                      <>
                        <div className="options">
                          <a
                            className="me-3 pe-3"
                            onClick={() => RemoveHandler()}
                          >
                            remove
                          </a>
                          <a onClick={() => WishlistHandler()}>
                            move to wishlist
                          </a>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                {!!productsFromCart &&
                  productsFromCart.map((cartProduct) => {
                    return (
                      <>
                        <div className="row pt-0 pb-3 bgContent">
                          <div className="cartPListing">
                            <div className="d-flex">
                              <div
                                className="cartPImage me-2 position-relative"
                                style={{ width: "30%" }}
                              >
                                <Link to={`/productDetail/${cartProduct.id}`}>
                                  {cartProduct?.image?.length ? (
                                    cartProduct?.image.map((img) => (
                                      <img
                                        key={img?.id}
                                        src={
                                          isUrl(img.url)
                                            ? img.url
                                            : (img.url == null || img.url == "N/A")
                                            ? "./images/dummyProduct.jpg"
                                            : API_URL + "/" + img.url
                                        }

                                        // src={
                                        //   img?.url != "N/A" || img?.url != null
                                        //     ? API_URL + "/" + img?.url
                                        //     : "../images/dummyProduct.jpg"
                                        // }
                                        // // src={stateImage ? 'one image' : "../images/dealsOfferImage3.png"}
                                        width="100%"
                                        height="100%"
                                        alt={img?.name}
                                      ></img>
                                    ))
                                  ) : (
                                    <img
                                      src="../images/dummyProduct.jpg"
                                      width="100%"
                                      height="100%"
                                      alt="Product image coming soon"
                                    ></img>
                                  )}
                                </Link>
                                {!isChecked ? (
                                  <input
                                    className="me-3"
                                    type="checkbox"
                                    value={cartProduct.id}
                                    onClick={(e) => onCartProductCheck(e)}
                                  />
                                ) : (
                                  <>
                                    <input type="checkbox" checked />
                                  </>
                                )}
                              </div>
                              <div className="productDetails">
                                <h5>{cartProduct.brand}</h5>
                                <h6>{cartProduct.name}</h6>
                                <div className="price">
                                  <span className="me-3">
                                    {/* $ {cartProduct?.discountedPrice} */}$
                                    {utype == "yellow"
                                      ? cartProduct?.yellowDealerPrice
                                      : utype == "blue"
                                      ? cartProduct?.blueDealerPrice
                                      : cartProduct?.price}
                                  </span>
                                  <span className="me-3">
                                    $ {cartProduct.discountedPrice}
                                  </span>
                                  {/* <span>{cartProduct.discountPercent} %Off</span> */}
                                </div>
                                {/* <div className="qty">
                                  <span>QTY :</span>
                                  <section className="container">
                                    <div className="row">
                                      <div className="qtyCount">
                                        <button
                                          className="minus"
                                          id="minus"
                                          onClick={() => decrement(cartProduct)}
                                        >
                                          -
                                        </button>
                                        <input
                                          className="count"
                                          id="count"
                                          type="number"
                                          value={cartProduct.quantity}
                                        />
                                        <button
                                          className="plus"
                                          id="plus"
                                          onClick={() => increment(cartProduct)}
                                        >
                                          +
                                        </button>
                                      </div>
                                    </div>
                                  </section>
                                </div> */}
                                <div className="qty">
                                  <div className="qtyCount">
                                    <span>QTY :</span>
                                    <button
                                      className="minus"
                                      id="minus"
                                      onClick={() => decrement(cartProduct)}
                                    >
                                      -
                                    </button>
                                    <input
                                      className="count"
                                      id="count"
                                      type="number"
                                      value={cartProduct.quantity}
                                    />
                                    <button
                                      className="plus"
                                      id="plus"
                                      onClick={() => increment(cartProduct)}
                                    >
                                      +
                                    </button>
                                  </div>
                                  <div className="backOrderCart">
                                    {/* <p>
                                      Backorder Quanity:{" "}
                                      {cartProduct?.quantity -
                                        cartProduct?.quantityOnHand}
                                    </p> */}
                                    {console.log(
                                      "562 ",
                                      cartProduct?.quantity,
                                      cartProduct?.quantityOnHand
                                    )}
                                    {cartProduct?.quantityOnHand >=
                                    cartProduct?.quantity ? (
                                      ""
                                    ) : (
                                      <div className="backOrderCart">
                                        <p>
                                          Backorder Quantity:{" "}
                                          <span>
                                            {cartProduct?.quantity -
                                              cartProduct?.quantityOnHand}
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {/* {console.log(
                                  "562 ",
                                  cartProduct?.quantity,
                                  cartProduct?.quantityOnHand
                                )}
                                {cartProduct?.quantityOnHand >=
                                cartProduct?.quantity ? (
                                  ""
                                ) : (
                                  <div className="backOrderCart">
                                  <p>
                                    Backorder Quanity:{" "}
                                    {cartProduct?.quantity -
                                      cartProduct?.quantityOnHand}
                                  </p>
                                </div>
                                )} */}
                              </div>
                            </div>
                            <div className="modalClose">
                              <p
                                onClick={() =>
                                  removeProductFromCart(cartProduct)
                                }
                              >
                                <i className="fa-solid fa-xmark"></i>
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>

              {productsFromCart.length != 0 ? (
                <>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                    <div className="row bgContent cmt">
                      <div className="coupon d-flex justify-content-between align-items-center p-0">
                        <div>
                          <i className="fa-solid fa-tag me-3"></i>
                          <span>Apply Coupon</span>
                        </div>
                        <button
                          className="applyBtn mb-3"
                          data-bs-toggle="modal"
                          data-bs-target="#couponModal"
                        >
                          APPLY
                        </button>
                      </div>
                      <p className="mt-3 p-0">Price Details </p>
                      <div className="pDetailscart p-0">
                        <div>
                          <p>Total MRP </p>
                          <p>$ {getTotal?.price.toFixed(2)}</p>
                        </div>
                        {/* <div>
                          <p>Discount on MRP</p>
                          <p>$ {getTotal?.discountAmount}</p>
                        </div>*/}
                        {couponType ? (
                          <>
                            <div>
                              <p>
                                {couponType === "Gift Card"
                                  ? "Gift Card Discount"
                                  : "Coupon Discount"}
                              </p>
                              <p>$ {couponAmount}</p>
                            </div>
                            <div>
                              <p>Coupon Type</p>
                              <p>{couponType}</p>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {/* <div>
                          <p>Convenience Fee</p>
                          <p>$ 00.00</p>
                        </div> */}
                      </div>
                      <div className="tAmount p-0">
                        <p className="pt-3">Total Amount</p>
                        <p className="pt-3">
                          {" "}
                          ${" "}
                          {getTotal?.price.toFixed(2) - Number(couponAmount) < 0
                            ? 0
                            : getTotal?.price.toFixed(2) - Number(couponAmount)}
                        </p>
                      </div>
                      <div className="btns p-0">
                        <button
                          className="me-3 mb-3 mt-3"
                          onClick={navigateToProducts}
                        >
                          continue shopping
                          <span className="ms-2">
                            <i className="fa-solid fa-angle-right"></i>
                          </span>
                        </button>
                        <button className="ppmBtn" onClick={navigateTo}>
                          Proceed To Checkout
                          <span className="ms-2">
                            <i className="fa-solid fa-angle-right"></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal fade applyCoupon"
        id="couponModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modalHeader">
              <p className="modal-title fs-5" id="exampleModalLabel">
                APPLY COUPON
              </p>
              <div
                className="modalClose btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></div>
            </div>
            <div className="modalBody bdrbottom">
              <div className="container">
                <div className="row">
                  <div className="input-group p-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Coupon Code"
                      aria-label="Enter Coupon Code"
                      aria-describedby="button-addon2"
                      value={couponVal}
                      onChange={(e) => setCouponVal(e.target.value)}
                    />

                    <input
                      className="btn btn-outline-secondary"
                      type="submit"
                      id="button-addon2"
                      value="APPLY"
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modalBody">
              <div className="filterCategories">
                {couponList?.length > 0 ? (
                  couponList?.map((x) => (
                    <>
                      <div className="mb-4">
                        <input
                          className="me-3 radioCheck"
                          type="radio"
                          name="coup"
                          id={x?._id}
                          onChange={(e) => handleChange(e)}
                          def={x?.code}
                        />
                        <span>{x?.code}</span>
                        {/**  const drink = dislikeCoke ? 'fanta' : likesCherry ? 'cherryCoke' : 'dietCoke'; */}

                        <p style={{ float: "right", marginLeft: "20px" }}>
                          {x?.couponType} ---{" "}
                          {x.dollarAmount
                            ? " $ " + x.dollarAmount
                            : x.discountPercent
                            ? x.discountPercent + " %"
                            : "N/A"}
                        </p>
                      </div>
                    </>
                  ))
                ) : (
                  <>
                    <p>There are no coupon found</p>
                  </>
                )}
              </div>
              {couponAmount && (
                <div>
                  <p>Maximum Savings:</p>
                  <p>$ {couponAmount}</p>
                  <p>Coupon Type:</p>
                  <p> {couponType}</p>
                </div>
              )}
            </div>

            {/* <div className="couponsBody"></div> */}
            <div className="modal-footer justify-content-between">
              {/* <div className="footerLeft"></div> */}
              {(couponAmount || couponAmount == 0) && (
                <>
                  <button className="ppmBtn" onClick={RemoveCoupon}>
                    Remove Coupon
                  </button>
                </>
              )}
              {/* <div className="cls-btn">Close</div> */}
              <div
                className="modalClose cls-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Close
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state?.auth?.isLoggedIn,
    cartProducts: state?.catagoryReducer?.CartData.data,
    cartProductData: state?.catagoryReducer?.CartData,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    AddCart: (product) => dispatch(AddCart(product)),
    DeleteCart: (product) => dispatch(DeleteCart(product)),
    IncreaseQuantity: (product) => dispatch(IncreaseQuantity(product)),
    DecreaseQuantity: (product) => dispatch(DecreaseQuantity(product)),
    getCartData: () => dispatch(getCartData()),
    getWishlistData: () => dispatch(getWishlistData()),
    cartDataResponse: (data) => dispatch(cartDataResponse(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
