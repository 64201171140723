import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import productService from "../services/product-catagorie-service";
import { getBrandById } from "../redux/actions/brandAction";
import brandService from "../services/brand-service";
import { API_URL } from "../constants/constants";
import { chooseRandom } from "../utils/chooseRandom";
import { useDispatch } from "react-redux";
import isUrl from "is-url";
import axios from "axios";
import Slider from "react-slick";
import Countdown from "react-countdown";
import CountdownTimer from "../pages/CountdownTimer";

const Container = (props) => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [productCatagory, setProductCatagory] = useState("");
  const all_products = props.allproducts || [];

  const [dataClick, setDataClick] = useState([]);
  const [dataChasis, setDataChasis] = useState([]);
  const [dataWhite, setDataWhite] = useState([]);
  const [dataYoutube, setDataYoutube] = useState([]);
  const [dataBanners, setDataBanners] = useState([]);
  const [weeklyDeal, setWeeklyDeal] = useState([]);
  const [dealTime, setDealTime] = useState("");
  const [weeklyDealText, setWeeklyDealText] = useState([]);
  const [shopNow, setShopNow] = useState("");
  const [shopNowBanner, setShopNowBanner] = useState("");
  const [numDays, setNumDays] = useState(0);

  const [countDownDays, setCountDownDays] = useState(0);

  const navigate = useNavigate();

  const getData = async () => {
    const { data } = await axios.get(
      API_URL + "/admin/uploadfile/getClickableSchematics"
    );
    setDataClick(data?.PDF);
  };

  const getBanners = async () => {
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    const data = await axios.get(API_URL + "/user/dashboard/getBanners");
    setDataBanners(data?.data?.banners);
  };
  const getDataChasis = async () => {
    const { data } = await axios.get(
      API_URL + "/admin/uploadfile/getChassisMaps"
    );

    setDataChasis(data?.file);
  };
  const getDataWhite = async () => {
    const { data } = await axios.get(
      API_URL + "/admin/uploadfile/getPdfWhitePages"
    );
    setDataWhite(data?.PDF);
  };

  const getDataYoutube = async () => {
    const { data } = await axios.get(
      API_URL + "/admin/uploadfile/getHowToAndVideos"
    );

    let videoData = data?.videos;
    const filteredData = videoData.filter((elem) => {
      return elem.url.includes("youtube");
    });

    setDataYoutube(filteredData);
  };

  const getDays = () => {
    brandService
      .getNoofDays()
      .then((res) => {
        setNumDays(res?.newTagDays?.days);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    //Runs only on the first render
    getDays();
  }, []);

  const date = new Date();

  const fetchBrandById = (id) => {
    brandService
      .getBrandsById(id)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getBrandById(res.brands));
          // setBrands(res)
          navigate(`/brandDetail/${id}`);
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
    getDataChasis();
    getDataWhite();
    getDataYoutube();
    getBanners();
  }, []);
  // const all_brands = props.allBrands || [];
  const settings = {
    dots: true,
    infinite: true,
    speed: 13000,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    // autoplaySpeed: 7000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    productService
      .getCategories(categories)
      .then((res) => {
        if (res.status === 200) {
          // dispatch(getProductById(res.product))
          // navigate(`/productDetail/${id}`)
          setCategories(res.categories);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    brandService
      .getBrands(100)
      .then((res) => {
        if (res.status === 200) {
          // dispatch(getProductById(res.product))
          // navigate(`/productDetail/${id}`)

          let arr = res?.brands;

          // let fiveBrands = chooseRandom(arr, 70);
          setBrands(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const settings1 = {
    dots: true,
    infinite: true,
    speed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    // autoplaySpeed: 5000,
  };

  //Daily deals
  async function randomProducts() {
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    let userId = localStorage.getItem("userId");
    userId = userId?.replace(/^"(.*)"$/, "$1");
    const response = await axios
      .post(
        API_URL + "/admin/product/getProductOfDealOfDay",
        { userId: userId },
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        setProducts(response.data.products);
        return response.data.products;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  //Deals of the Week/Month
  async function dealWeek() {
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    let userId = localStorage.getItem("userId");
    userId = userId?.replace(/^"(.*)"$/, "$1");

    const response = await axios
      .post(
        API_URL + "/admin/product/getProductOfDeals",
        { userId: userId },
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        setDealTime(response?.data?.products[0]?.updatedAt);
        setWeeklyDeal(response?.data?.products[0]);
        return response?.data?.products[0];
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  //Deals of the Week/Month Text Change
  async function dealText() {
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    let userId = localStorage.getItem("userId");
    userId = userId?.replace(/^"(.*)"$/, "$1");
    const response = await axios
      .get(
        API_URL + "/user/dashboard/getDealTypeText",
        { userId: userId },
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        // setWeeklyDeal(response?.data?.products[0]);
        setWeeklyDealText(response?.data?.dealTypeText?.[0]?.dealType);
        return response?.data?.dealTypeText?.[0]?.dealType;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  //Shop Now Link
  async function shopNowFn() {
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    const userId = localStorage.getItem("userId");
    const response = await axios
      .get(
        API_URL + "/user/dashboard/getShopNowLink",

        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        setShopNow(response?.data?.shopNowLink?.link);
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  //Shop Now Banner Link
  async function shopNowBannerFn() {
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    // const userId = localStorage.getItem("userId");
    const response = await axios
      .get(
        API_URL + "/admin/admindashboard/getShopNowBanners",

        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        setShopNowBanner(response?.data?.banners?.[0]?.file?.url);
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  //Countdown Timer

  async function countDownDaysFn() {
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    // const userId = localStorage.getItem("userId");
    const response = await axios
      .get(
        API_URL + "/admin/admindashboard/getCountDownDays",

        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        // setShopNow(response?.data?.shopNowLink?.link);
        setCountDownDays(response?.data?.newTagDays?.days);
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  }

  const fetchProductById = (id) => {
    navigate(`/productDetail/${id}`);
    window.location.reload(true);
  };

  useEffect(() => {
    randomProducts();
    dealWeek();
    dealText();
    shopNowFn();
    shopNowBannerFn();
    countDownDaysFn();
  }, []);

  const dispatch = useDispatch();

  const targetDate = new Date(dealTime);
  const SEVEN_DAYS_IN_MS = countDownDays * 24 * 60 * 60 * 1000;
  const NOW_IN_MS = targetDate.getTime();

  const dateTimeAfterSevenDays = NOW_IN_MS + SEVEN_DAYS_IN_MS;

  return (
    <>
      <section>
        <div className="banners slick-slider-banner sldr mb5">
          <div>
            <Slider {...settings1}>
              {dataBanners?.map((elem, index) => {
                return (
                  <div className="carousel-item" key={index}>
                    {elem?.link ? (
                      <a href={elem?.link} target="_blank">
                        <img
                          src={API_URL + `/${elem?.file[0]?.url}`}
                          className="d-block w-100"
                        />
                      </a>
                    ) : (
                      <a>
                        <img
                          src={API_URL + `/${elem?.file[0]?.url}`}
                          className="d-block w-100"
                        />
                      </a>
                    )}
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>

        <div className="dealsSection">
          <div className="container">
            <div className="row">
              <div className="col-xxl-3 col-xl-3 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="dealsLHeader">
                  <p>{weeklyDealText ? weeklyDealText : "Dummy Text"}</p>
                  {/* <div className="btnLR">
                    <i className="fa-solid fa-angle-left me-3"></i>
                    <i className="fa-solid fa-angle-right"></i>
                  </div> */}
                </div>
                <Link to={`/productDetail/${weeklyDeal?._id}`}>
                  <div className="dealsBody">
                    <div className="dealsImage mb-5">
                      {/* <img src="./images/deals.png" height={210} width={230} /> */}
                      {weeklyDeal?.image?.[0]?.url ? (
                        <img
                          key={weeklyDeal?._id}
                          // src={weeklyDeal?.image?.[0]?.url}
                          src={API_URL + "/" + weeklyDeal?.image?.[0]?.url}
                          width="100%"
                          // alt={img?.name}
                        ></img>
                      ) : (
                        <img
                          key={weeklyDeal?._id}
                          src="./images/deals.png"
                          width="100%"
                          // alt={img?.name}
                        ></img>
                      )}
                      {/* <div>
                      <p>30%</p>
                      <p>off</p>
                    </div> */}
                    </div>

                    <div className="dealsDetails">
                      <p>{weeklyDeal?.name}</p>
                      <div className="dealsPrice mb-3">
                        <span>${weeklyDeal?.oldPrice?.toFixed(2) ?? 10}</span>
                        <span>${weeklyDeal?.price?.toFixed(2)}</span>
                      </div>
                      {/* <div className="dealsInfo">
                      <span className="me-3">
                        Already Sold: <span>3</span>
                      </span>
                      <span>
                        Available: <span>{weeklyDeal?.quantityOnHand}</span>
                      </span>
                    </div> */}
                      <div className="dealsOffer mt-5">
                        <span>Hurry up!</span>
                        <span>Offers end in:</span>
                      </div>
                      <div className="dealsCountdown">
                        <section
                          id="countdown-container"
                          className="countdown-container"
                        >
                          <span style={{ fontSize: "30px" }}>
                            {/**This line  */}
                            <CountdownTimer
                              targetDate={dateTimeAfterSevenDays}
                            />{" "}
                            {/* {  weeklyDeal?.updatedAt} */}
                            {/* <Countdown date={Date.now() + 604800000} /> */}
                          </span>
                          <article id="js-countdown" className="countdown">
                            <section id="js-days" className="number"></section>
                            {/* <section id="js-separator" className="separator">
                              :
                            </section> */}
                            <section id="js-hours" className="number"></section>
                            {/* <section id="js-separator" className="separator">
                              :
                            </section> */}
                            <section
                              id="js-minutes"
                              className="number"
                            ></section>
                            {/* <section id="js-separator" className="separator">
                              :
                            </section> */}
                            <section
                              id="js-seconds"
                              className="number"
                            ></section>
                          </article>
                        </section>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-xxl-9 col-xl-9 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="dealsRHeader">
                  <span>Daily Deals! Get Our Best Prices.</span>
                  <ul
                    className="nav nav-tabs justify-content-end"
                    id="myTab"
                    role="tablist"
                  >
                    {/* <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="tabOne"
                        data-bs-toggle="tab"
                        data-bs-target="#tabOne-pane"
                        type="button"
                        role="tab"
                        aria-controls="tabOne-pane"
                        aria-selected="true"
                      >
                        30% Offer
                      </button>
                    </li> */}
                    {/* <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="tabTwo"
                        data-bs-toggle="tab"
                        data-bs-target="#tabTwo-pane"
                        type="button"
                        role="tab"
                        aria-controls="tabTwo-pane"
                        aria-selected="false"
                      >
                        50% Offer
                      </button>
                    </li> */}
                    {/* <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="tabThree"
                        data-bs-toggle="tab"
                        data-bs-target="#tabThree-pane"
                        type="button"
                        role="tab"
                        aria-controls="tabThree-pane"
                        aria-selected="false"
                      >
                        70% Offer
                      </button>
                    </li> */}
                  </ul>
                  <div className="tab-content mt-5" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="tabOne-pane"
                      role="tabpanel"
                      aria-labelledby="tabOne"
                      tabIndex="0"
                    >
                      <div className="dealsMain">
                        {products?.map((item, index) => {
                          let oldDate = new Date(item?.createdAt);

                          let Difference_In_Time =
                            date?.getTime() - oldDate?.getTime();

                          // To calculate the no. of days between two dates
                          let Difference_In_Days =
                            Difference_In_Time / (1000 * 3600 * 24);

                          return (
                            <>
                              {/* <Link to={`/productDetail/${item._id}`}> */}

                              <button
                                className="card-btn"
                                onClick={() => fetchProductById(item._id)}
                                // title="Product detail"
                              >
                                <div className="dealsProduct" key={index}>
                                  <div className="dealsProductImg">
                                    {item?.image[0]?.url ||
                                    item?.image[0]?.url == "N/A" ? (
                                      <img
                                        src={
                                          API_URL + `/${item?.image[0]?.url}`
                                        }
                                        className="w-100"
                                        alt="Product image is coming soon"
                                        height={200}
                                        width={200}
                                      />
                                    ) : (
                                      <img
                                        src="./images/dummyProduct.jpg"
                                        alt="dummy-image"
                                        height={200}
                                        width={200}
                                      ></img>
                                    )}
                                    {/* {Difference_In_Days < numDays && (
                                      <span>New</span>
                                    )} */}
                                  </div>
                                  <div className="dealsDetails">
                                    <p>{item?.name}</p>
                                    <div className="dealsPrice mb-3">
                                      <span>
                                        {item?.oldPrice
                                          ? "$" + item?.oldPrice
                                          : null}
                                      </span>
                                      <span>${item.price}</span>
                                    </div>
                                  </div>
                                </div>
                              </button>
                              {/* </Link> */}
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="tabTwo-pane"
                      role="tabpanel"
                      aria-labelledby="tabTwo"
                      tabIndex="0"
                    >
                      <div className="dealsMain">
                        <div className="dealsProduct">
                          <div className="dealsProductImg">
                            <img src="./images/dealsOfferImage1.png" />
                          </div>
                          <div className="dealsDetails">
                            <p>Ghoei Terminus RF</p>
                            <div className="dealsPrice mb-3">
                              <span>$590</span>
                              <span>$350</span>
                            </div>
                          </div>
                        </div>
                        <div className="dealsProduct">
                          <div className="dealsProductImg">
                            <img src="./images/dealsOfferImage2.png" />
                          </div>
                          <div className="dealsDetails">
                            <p>Ghoei Terminus RF</p>
                            <div className="dealsPrice mb-3">
                              <span>$590</span>
                              <span>$350</span>
                            </div>
                          </div>
                        </div>
                        <div className="dealsProduct">
                          <div className="dealsProductImg">
                            <img src="./images/dealsOfferImage3.png" />
                          </div>
                          <div className="dealsDetails">
                            <p>Ghoei Terminus RF</p>
                            <div className="dealsPrice mb-3">
                              <span>$590</span>
                              <span>$350</span>
                            </div>
                          </div>
                        </div>
                        <div className="dealsProduct">
                          <div className="dealsProductImg">
                            <img src="./images/dealsOfferImage4.png" />
                          </div>
                          <div className="dealsDetails">
                            <p>Ghoei Terminus RF</p>
                            <div className="dealsPrice mb-3">
                              <span>$590</span>
                              <span>$350</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="tabThree-pane"
                      role="tabpanel"
                      aria-labelledby="tabThree"
                      tabIndex="0"
                    >
                      <div className="dealsMain">
                        <div className="dealsProduct">
                          <div className="dealsProductImg">
                            <img src="./images/dealsOfferImage1.png" />
                          </div>
                          <div className="dealsDetails">
                            <p>Ghoei Terminus RF</p>
                            <div className="dealsPrice mb-3">
                              <span>$590</span>
                              <span>$350</span>
                            </div>
                          </div>
                        </div>
                        <div className="dealsProduct">
                          <div className="dealsProductImg">
                            <img src="./images/dealsOfferImage2.png" />
                          </div>
                          <div className="dealsDetails">
                            <p>Ghoei Terminus RF</p>
                            <div className="dealsPrice mb-3">
                              <span>$590</span>
                              <span>$350</span>
                            </div>
                          </div>
                        </div>
                        <div className="dealsProduct">
                          <div className="dealsProductImg">
                            <img src="./images/dealsOfferImage3.png" />
                          </div>
                          <div className="dealsDetails">
                            <p>Ghoei Terminus RF</p>
                            <div className="dealsPrice mb-3">
                              <span>$590</span>
                              <span>$350</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="newArrivals mb5">
          <div className="container">
            <div className="row">
              <div className="productListing">
                <div className="row">
                  <div className="naTitle">
                    <span>NEW ARRIVALS</span>
                    <Link to="/products">
                      <span>SEE ALL</span>
                    </Link>
                  </div>
                </div>
                {/* <div className="row align-items-center">
                  <div className="col-12 col-carousel">
                    <div className="owl-carousel carousel-main3">
                      {all_products.slice(0, 5).map((prod) => {
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12 text-center ms-3">
                          <div className="plImage">
                            <img src="images/productListing.png" />
                            {prod.image.url ? <img src={API_URL + `/api/${prod.image.url}`} alt="roduct Image"></img> : <img src="images/productListing.png" alt="dummy-image"></img>}
                            <span>New</span>
                          </div>
                          <div className="plInfo">
                            <div className="reviews">
                                                            <i className="fa-solid fa-star"></i>
                                                            <i className="fa-solid fa-star"></i>
                                                            <i className="fa-solid fa-star"></i>
                                                            <i className="fa-solid fa-star"></i>
                                                            <i className="fa-solid fa-star"></i>
                                                        </div>

                            <p>{prod.name}</p>
                            <p>{prod.price}</p>
                          </div>
                          <div className="plBtns">
                            <button className="atcBtn">Add to Cart</button>
                            <button className="productBtn">
                              <i className="fa-solid fa-heart"></i>
                            </button>
                            <button className="productBtn">
                              <i className="fa-solid fa-eye"></i>
                            </button>
                          </div>
                        </div>;

                       
                      })}
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="adBanner mb5">
          <div className="container">
            <div className="row">
              <div className="adImage shop-now-banner">
                <img src={API_URL + "/" + shopNowBanner} height={300} />
                <a href={`${shopNow}`}>
                  <button className="ppmBtn">SHOP NOW</button>
                </a>
              </div>
              <div className="adDetails">
                <div className="adinfo">
                  {/* <p>50% OFF</p>
                  <p>On All Items</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="shopByCategories mb5">
          <div className="container">
            <div className="row">
              <div className="dTitle text-center">
                <p>TOP FEATURED COLLECTIONS</p>
                <p>
                  <span className="dot me-3"></span>SHOP BY CATEGORIES
                  <span className="dot ms-3"></span>
                </p>
              </div>
            </div>
            <div className="ctgMain">
              {categories?.slice(0, 6).map((item, index) => (
                <>
                  <div className="ctgListing">
                    <div className="ctgImage" style={{ marginRight: "10px" }}>
                      {item?.image?.url ? (
                        <img
                          src={API_URL + `/${item?.image?.url}`}
                          width="100%"
                          alt="Category Image"
                        ></img>
                      ) : (
                        <img src="./images/ctg2.png" alt="dummy-image"></img>
                      )}
                    </div>
                    <div className="ctgDetails">
                      <div className="ctgTitle">{item?.name}</div>
                      <div>
                        <ul>
                          {item?.products?.slice(0, 5).map((p, index) => (
                            <>
                              <li>{p.slice(0, 25)}...</li>
                            </>
                          ))}
                        </ul>
                      </div>
                      <button></button>
                      <Link
                        className="ppmBtn"
                        to={`/categoryDetail/${item?._id}`}
                      >
                        View More
                      </Link>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
        <div className="shopByBrands mb5">
          <div className="container">
            <div className="row">
              <div className="dTitle text-center">
                <p>TOP QUALITY PARTNERS</p>
                <p>
                  <span className="dot me-3"></span>SHOP BY BRANDS
                  <span className="dot ms-3"></span>
                </p>
              </div>
            </div>
            <div className="row">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-12">
                    <div className="sliderImg">
                      <Slider {...settings}>
                        {brands?.slice(0, 1000).map((item, index) => (
                          <>
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => fetchBrandById(item._id)}
                            >
                              {isUrl(item?.image?.url) ? (
                                <>
                                  <img
                                    src={item?.image?.url}
                                    height={100}
                                    width={170}
                                    style={{ margin: "20px" }}
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    src={API_URL + "/" + item?.image?.url}
                                    width="100%"
                                    alt={item.image.name}
                                  ></img>
                                </>
                              )}
                            </a>
                          </>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="techResources">
          <div className="container">
            <div className="row">
              <div className="naTitle mb-3">
                <span>Tech Resources</span>
              </div>
              <div className="techResourcesTabs">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="videos"
                      data-bs-toggle="tab"
                      data-bs-target="#videos-pane"
                      type="button"
                      role="tab"
                      aria-controls="videos-pane"
                      aria-selected="true"
                    >
                      How To's & Videos
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pdfWhitepages"
                      data-bs-toggle="tab"
                      data-bs-target="#pdfWhitepages-pane"
                      type="button"
                      role="tab"
                      aria-controls="pdfWhitepages-pane"
                      aria-selected="false"
                    >
                      PDF Whitepages{" "}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="clickSchematics"
                      data-bs-toggle="tab"
                      data-bs-target="#clickSchematics-pane"
                      type="button"
                      role="tab"
                      aria-controls="clickSchematics-pane"
                      aria-selected="false"
                    >
                      Clickable Schematics
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="chassisMaps"
                      data-bs-toggle="tab"
                      data-bs-target="#chassisMaps-pane"
                      type="button"
                      role="tab"
                      aria-controls="chassisMaps-pane"
                      aria-selected="false"
                    >
                      Chassis Maps
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="videos-pane"
                    role="tabpanel"
                    aria-labelledby="videos"
                    tabIndex="0"
                  >
                    <div className="row">
                      <div className="container">
                        <div className="row align-items-center">
                          <div className="col-12">
                            <div>
                              <Slider {...settings}>
                                {dataYoutube?.map((item, index) => {
                                  return (
                                    <div
                                      className="videoListing mt-5"
                                      key={index}
                                    >
                                      <iframe
                                        src={item?.url}
                                        title={item?.name}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                      ></iframe>
                                      <p>{item?.name}</p>
                                    </div>
                                  );
                                })}
                              </Slider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pdfWhitepages-pane"
                    role="tabpanel"
                    aria-labelledby="pdfWhitepages"
                    tabIndex="0"
                  >
                    <div className="row">
                      <div className="container">
                        <div className="row align-items-center">
                          <div className="col-12">
                            <div className="">
                              <Slider {...settings}>
                                {dataWhite.map((item, index) => {
                                  let str = item.pdf.url;

                                  let flag = str.includes(".pdf");

                                  if (flag) {
                                    return (
                                      <div
                                        className="pdfListing m-2"
                                        key={index}
                                      >
                                        <div className="pdfImage">
                                          <img
                                            src="images/pdfIcon.png"
                                            alt="pdfIcon"
                                          />
                                        </div>
                                        <div className="pdfbottommapDiv">
                                          <p className="pdfTitle">
                                            {item.name}
                                          </p>
                                          <div className="pdfBtns">
                                            <a
                                              target="_blank"
                                              rel="noopener"
                                              href={
                                                API_URL +
                                                `/` +
                                                `${item.pdf.url}`
                                              }
                                              className="ppmBtn"
                                            >
                                              View
                                            </a>
                                            <a
                                              href={
                                                API_URL +
                                                `/` +
                                                `${item.pdf.url}`
                                              }
                                              className="ppmBtn"
                                            >
                                              Download
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div
                                        className="pdfListing m-2"
                                        key={index}
                                      >
                                        <div className="pdfImage">
                                          <img
                                            src="images/pdfIcon.png"
                                            alt="pdfIcon"
                                          />
                                        </div>
                                        <div className="pdfbottommapDiv">
                                          <p className="pdfTitle">
                                            {item.name}
                                          </p>
                                          <div className="pdfBtns">
                                            <a
                                              target="_blank"
                                              rel="noopener"
                                              href={
                                                API_URL +
                                                `/` +
                                                `${item.pdf.url}` +
                                                `.pdf`
                                              }
                                              className="ppmBtn"
                                            >
                                              View
                                            </a>
                                            <a
                                              href={
                                                API_URL +
                                                `/` +
                                                `${item.pdf.url}` +
                                                `.pdf`
                                              }
                                              className="ppmBtn"
                                            >
                                              Download
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                              </Slider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="clickSchematics-pane"
                    role="tabpanel"
                    aria-labelledby="clickSchematics"
                    tabIndex="0"
                  >
                    <div className="row">
                      <div className="container">
                        <div className="row align-items-center">
                          <div className="col-12">
                            <div className="">
                              <Slider {...settings}>
                                {dataClick?.map((item, index) => {
                                  let str = item?.file?.url;

                                  let flag = str?.includes(".pdf");

                                  if (!flag) {
                                    return (
                                      <div
                                        className="pdfListing third-slide mt-5 mb-5"
                                        key={index}
                                      >
                                        <div className="pdfImage">
                                          <img src="images/imageIcon.png" />
                                        </div>
                                        <div className="pdfbottommapDiv">
                                          <p className="pdfTitle">
                                            {item?.name}
                                          </p>
                                          <div className="pdfBtnss">
                                            <a
                                              target="_blank"
                                              rel="noopener"
                                              href={
                                                API_URL +
                                                `/` +
                                                `${item?.file?.url}`
                                              }
                                              className="ppmBtn"
                                            >
                                              View
                                            </a>

                                            {/* <button className="ppmBtn">
                                              Download
                                            </button> */}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div
                                        className="pdfListing third-slide mt-5 mb-5"
                                        key={index}
                                      >
                                        <div className="pdfImage">
                                          <img src="images/pdfIcon.png" />
                                        </div>
                                        <div className="pdfbottommapDiv">
                                          <p className="pdfTitle">
                                            {item?.name}
                                          </p>
                                          <div className="pdfBtnss">
                                            <a
                                              target="_blank"
                                              rel="noopener"
                                              href={
                                                API_URL +
                                                `/` +
                                                `${item?.file?.url}`
                                              }
                                              className="ppmBtn"
                                            >
                                              View
                                            </a>

                                            {/* <button className="ppmBtn">
                                              Download
                                            </button> */}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                              </Slider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="chassisMaps-pane"
                    role="tabpanel"
                    aria-labelledby="chassisMaps"
                    tabIndex="0"
                  >
                    <div className="row">
                      <div className="container">
                        <div className="row align-items-center">
                          <div className="col-12">
                            <div className="">
                              <Slider {...settings}>
                                {dataChasis?.map((item, index) => {
                                  let str = item?.file?.url;

                                  let flag = str?.includes(".pdf");

                                  if (flag) {
                                    return (
                                      <>
                                        <div
                                          className="pdfListing mt-5 mb-5"
                                          key={index}
                                        >
                                          <div className="pdfImage">
                                            <img src="images/pdfIcon.png" />
                                          </div>
                                          <div className="pdfbottommapDiv">
                                            <p className="pdfTitle">
                                              {item.name}
                                            </p>
                                            <div className="pdfBtnss">
                                              <a
                                                target="_blank"
                                                rel="noopener"
                                                href={
                                                  API_URL +
                                                  `/` +
                                                  `${item?.file?.url}`
                                                }
                                                className="ppmBtn"
                                              >
                                                View
                                              </a>
                                              {/* <button className="ppmBtn">Download</button> */}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  } else {
                                    return (
                                      <>
                                        <div
                                          className="pdfListing mt-5 mb-5"
                                          key={index}
                                        >
                                          <div className="pdfImage">
                                            <img src="images/imageIcon.png" />
                                          </div>
                                          <div className="pdfbottommapDiv">
                                            <p className="pdfTitle">
                                              {item.name}
                                            </p>
                                            <div className="pdfBtnss">
                                              <a
                                                target="_blank"
                                                rel="noopener"
                                                href={
                                                  API_URL +
                                                  `/` +
                                                  `${item?.file?.url}`
                                                }
                                                className="ppmBtn"
                                              >
                                                View
                                              </a>
                                              {/* <button className="ppmBtn">Download</button> */}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  }
                                })}
                              </Slider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {
    allproducts: state?.productReducer?.products,
    allBrands: state?.brandReducer?.brands,
  };
}

export default connect(mapStateToProps)(Container);
