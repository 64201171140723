import axios from "axios";
import { API_URL } from "../constants/constants";

// const API_URL = "https://ppmracingproducts-qa.chetu.com/api/admin/";
// const token=localStorage.getItem("userInfo", JSON.stringify(response.data.userData.token))
// const token=localStorage.getItem("userInfo");
const productService = {
  getProducts: async () => {
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    const userId = localStorage.getItem("userId");

    const response = await axios
      .post(
        API_URL + "/admin/product/getProducts?limit=24&page=1",
        { userId: userId },
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },

  getCategories: async () => {
    const response = await axios
      .get(API_URL + `/admin/category/getCategories`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },

  getProductsByCategory: async (id, pageCount) => {
    const userId = localStorage.getItem("userId");
    //  const modifiedUserId= userId.replace(/['"]+/g, '')

    const response = await axios
      .post(
        API_URL +
          `/admin/product/getProductsByCategory/${id}?limit=24&page=${pageCount}`,
        { userId: userId }
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },

  //////////////////

  getBrandsByCategory: async (id) => {
    const response = await axios
      .get(API_URL + `/admin/category/getCategoryBrandsById/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },

  //////////////

  getProductsBySubCategory: async (id, page) => {
    const userId = localStorage.getItem("userId");

    const response = await axios
      .post(
        API_URL +
          `/admin/product/getProductsBySubcategory/${id}?limit=24&page=${page}`,
        { userId: userId }
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },

  getSubcategoriesByCategoryID: async (id) => {
    const userId = localStorage.getItem("userId");

    const response = await axios
      .get(API_URL + `/admin/category/getCategoryById/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },

  getProductsById: async (id) => {
    const userId = localStorage.getItem("userId");
    const response = await axios
      .post(API_URL + `/admin/product/getProductById/${id}`, { userId: userId })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },

  getProductsByBrandId: async (id) => {
    const userId = await localStorage.getItem("userId");

    const response = await axios
      .post(
        API_URL + `/admin/product/getProductsByBrand/${id}?limit=12&page=1`,
        { userId: userId }
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },

  searchProductByName: async (data = null) => {
    const userId = localStorage.getItem("userId");
    data.userId = userId;
    const response = await axios
      .post(API_URL + `/admin/product/searchProductByName`, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },

  searchProductByCategoryFilter: async (data = null) => {
    const userId = localStorage.getItem("userId");
    data.userId = userId;
    const response = await axios
      .post(API_URL + `/admin/product/searchProductByCategoryFilter`, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },
};

export default productService;
