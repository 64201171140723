import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { API_URL } from "../constants/constants";
import basestyle from "./Auth/Base.module.css";
import swal from "sweetalert";
import axios from "axios";

const RequestCatalog = () => {
  const [catalogData, setCatalogData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    businessName: "",
    catalogRequired: "",
    comment: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCatalogData({ ...catalogData, [name]: value });
  };

  const validateForm = (values) => {
    const error = {};
    const emailRegex = /^[^\s+@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const postalCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    const phoneRegex = /^[0-9\b]+$/;
    if (!values.firstName.trim()) {
      error.fname = "First Name is required";
    } else if (!values.firstName.match(/^[a-zA-Z ]*$/)) {
      error.fname = "Please enter valid first name";
    } else if (values.firstName.length > 16) {
      error.fname = "First name value cannot exceed more than 16 characters";
    }
    if (!values.lastName.trim()) {
      error.lname = "Last Name is required";
    } else if (!values.lastName.match(/^[a-zA-Z ]*$/)) {
      error.lname = "Please enter valid last name";
    } else if (values.lastName.length > 16) {
      error.lname = "Last name value cannot exceed more than 16 characters";
    }
    if (!values.email) {
      error.email = "Email is required";
    } else if (!emailRegex.test(values.email)) {
      error.email = "This is not a valid email format";
    }

    // if (!values.cpassword) {
    //     error.cpassword = "Confirm Password is required";
    // } else if (values.cpassword !== values.password) {
    //     error.cpassword = "Confirm password and password should be same";
    // }

    if (!values.phone) {
      error.phone = "Phone no. is required";
    } else if (!phoneRegex.test(values.phone) || values.phone.length !== 10) {
      error.phone = "Please enter a 10-digit phone no.";
    }
    if (!values.addressLine1) {
      error.addressLine1 = "Address Line1 required";
    }
    if (!values.comment) {
      error.comment = "Comment is required";
    }
    if (!values.catalogRequired) {
      error.catalogRequired = "Please select any option";
    }
    // if (!values.country) {
    //   error.country = "Country is required";
    // }
    if (!values.state) {
      error.state = "State is required";
    }
    if (!values.city) {
      error.city = "City is required";
    }
    if (!values.zip) {
      error.zip = "Zip Code required";
    } else if (!postalCodeRegex.test(values.zip)) {
      error.zip = "Please enter a 5-digit zip code.";
    }
    return error;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validate = validateForm(catalogData);
    setFormErrors(validate);
    if (!!Object.keys(validate).length) {
      setFormErrors(validate);
      return;
    }

    setIsSubmit(true);

    try {
      const response = await axios.post(
        API_URL + "/user/dashboard/requestCatalog",
        {
          ...catalogData,
        }
      );

      swal("Success!", response?.data?.message, "success");

      if (response.data.status == 200) {
        setCatalogData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zip: "",
          country: "",
          businessName: "",
          catalogRequired: "",
          comment: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <nav className="breadcrumb">
        <div className="container">
          <div className="row">
            <ul>
              <li>
                <NavLink to="/">
                  Home
                  <span className="ms-3">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/RequestCatalog">Request Catalog</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="mainContent">
        <div className="container bgContent">
          {/* <div className="row">
            <div className="pageTitle">
              <h1>Request Catalog</h1>
              <p>Request Catalog</p>
            </div>
          </div> */}
          <div className="container bgContent">
            <div className="row">
              {/* <div className="pageTitle">
              <h1>Products</h1>
              <p>Products</p>
            </div> */}
            </div>
            <p
              style={{
                textAlign: "center",
                padding: "50px",
                position: "relative",
              }}
            >
              <span className="cate-heading">Request Catalog</span>
              <img
                src="../images/textbanner.png"
                alt=""
                className="cate-image"
              ></img>
            </p>
          </div>
        </div>
        <div className="container">
          <div className="catelogform-outer">
            <div className="row mt-3">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    value={catalogData.firstName}
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInput">
                    First Name
                    <span>*</span>
                  </label>
                  <p className={basestyle.error}>{formErrors.fname}</p>
                </div>
              </div>

              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    value={catalogData.lastName}
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInput">
                    Last Name
                    <span>*</span>
                  </label>
                  <p className={basestyle.error}>{formErrors.lname}</p>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="businessName"
                    name="businessName"
                    placeholder="Business Name"
                    value={catalogData.businessName}
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInput">
                    Business Name
                    {/* <span>*</span> */}
                  </label>
                  {/* <p className={basestyle.error}>{formErrors.name}</p> */}
                </div>
              </div>

              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    name="phone"
                    placeholder="Phone"
                    value={catalogData.phone}
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInput">
                    Phone
                    <span>*</span>
                  </label>
                  <p className={basestyle.error}>{formErrors.phone}</p>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="form-floating ">
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={catalogData.email}
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInput">
                    Email
                    <span>*</span>
                  </label>
                  <p className={basestyle.error}>{formErrors.email}</p>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <p className="ms-1 mb-2 catalog-address">Address</p>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="addressLine1"
                    name="addressLine1"
                    placeholder="Address Line1"
                    value={catalogData.addressLine1}
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInput">
                    Street Address
                    <span>*</span>
                  </label>
                  <p className={basestyle.error}>{formErrors.addressLine1}</p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="addressLine2"
                    name="addressLine2"
                    placeholder="Address Line2"
                    value={catalogData.addressLine2}
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInput">
                    Address Line 2{/* <span>*</span> */}
                  </label>
                  {/* <p className={basestyle.error}>{formErrors.name}</p> */}
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    placeholder="City"
                    value={catalogData.city}
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInput">
                    City
                    <span>*</span>
                  </label>
                  <p className={basestyle.error}>{formErrors.city}</p>
                </div>
              </div>

              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="state"
                    name="state"
                    placeholder="State"
                    value={catalogData.state}
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInput">
                    State / Province /Region
                    <span>*</span>
                  </label>
                  <p className={basestyle.error}>{formErrors.state}</p>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="zip"
                    name="zip"
                    placeholder="zip"
                    value={catalogData.zip}
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInput">
                    Zip/ Postal Code
                    <span>*</span>
                  </label>
                  <p className={basestyle.error}>{formErrors.zip}</p>
                </div>
              </div>

              {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="country"
                    name="country"
                    placeholder="country"
                    value={catalogData.country}
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInput">
                    Country
                    <span>*</span>
                  </label>
                  <p className={basestyle.error}>{formErrors.country}</p>
                </div>
              </div> */}

              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="form-floating mb-3">
                  <textarea
                    name="comment"
                    id="comment"
                    onChange={handleChange}
                    placeholder="Write down your comments here"
                    value={catalogData.comment}
                  />
                  {/* <label htmlFor="floatingInput">
                    Comments <span>*</span>
                  </label> */}
                  <p className={basestyle.error}>{formErrors.comment}</p>
                </div>
              </div>
              <div className="row">
                <div className="catalog-validation">
                  <p>
                    I would like a Catalog
                    <span style={{ color: "red" }}>*</span>
                  </p>
                  (Catalog free and only available in the United States.)
                  <div className="catalog-validation-inner">
                    <span>
                      <input
                        type="radio"
                        name="catalogRequired"
                        value="Yes"
                        checked={catalogData?.catalogRequired == "Yes"}
                        onChange={handleChange}
                      />
                      &nbsp; Yes
                    </span>
                    <span>
                      <input
                        type="radio"
                        name="catalogRequired"
                        value="No"
                        checked={catalogData?.catalogRequired == "No"}
                        onChange={handleChange}
                      />
                      &nbsp; No
                    </span>
                    <p className={basestyle.error}>
                      {formErrors.catalogRequired}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 p-0">
                  <div className="catalog-btn">
                    <input
                      type="submit"
                      name="ans"
                      value="Submit"
                      className="form-control"
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestCatalog;
