import React, { useState, useEffect } from "react";
import BreadCrumb from "../components/BreadCrumb";
import swal from "sweetalert";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../constants/constants";
import basestyle from "../pages/Auth/Base.module.css";

const Contact = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // console.log(user);
    }
  }, [formErrors]);

  const validateForm = (values) => {
    const error = {};
    const emailRegex = /^[^\s+@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const phoneRegex = /^[0-9\b]+$/;
    if (!values.name) {
      error.name = "Name is required";
    } else if (!values.name.match(/^[a-zA-Z ]*$/)) {
      error.name = "Please enter valid  name";
    } else if (values.name.length > 16) {
      error.name = "Name value cannot exceed more than 16 characters";
    }
    if (!values.subject) {
      error.subject = "Subject is required";
    }
    if (!values.message) {
      error.message = "Message is required";
    }
    if (!values.email) {
      error.email = "Email is required";
    } else if (!emailRegex.test(values.email)) {
      error.email = "This is not a valid email format";
    }
    if (!values.phone) {
      error.phone = "Phone no. is required";
    } else if (!phoneRegex.test(values.phone) || values.phone.length !== 10) {
      error.phone = "Please enter a 10-digit phone no.";
    }
    return error;
  };

  const contactHandler = async (e) => {
    e.preventDefault();
    const validate = validateForm(data);
    setFormErrors(validate);
    if (!!Object.keys(validate).length) {
      setFormErrors(validate);
      return;
    }
    setIsSubmit(true);

    const response = await axios.post(API_URL + "/user/dashboard/contactUs", {
      name: data.name,
      email: data.email,
      phone: data.phone,
      subject: data.subject,
      message: data.message,
    });
    swal("Success!", response.data.message, "success");
    // const newData = { ...data, id: new Date().getTime().toString() };
    setData({ name: "", email: "", subject: "", phone: "", message: "" });
  };

  return (
    <div>
      {/* <BreadCrumb /> */}
      <nav className="breadcrumb">
        <div className="container">
          <div className="row">
            <ul>
              <li>
                <NavLink to="/">
                  Home
                  <span className="ms-3">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/contactus">Contact Us</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="mainContent">
        <div className="container bgContent">
          <div className="container bgContent">
            <div className="row">
              {/* <div className="pageTitle">
              <h1>Products</h1>
              <p>Products</p>
            </div> */}
            </div>
            <p
              style={{
                textAlign: "center",
                padding: "50px",
                position: "relative",
              }}
            >
              <span className="cate-heading">Contact Us</span>
              <img
                src="../images/textbanner.png"
                alt=""
                className="cate-image"
              ></img>
            </p>
          </div>

          <div className="container">
            <div className="row mt-5">
              <div className="responsive-map mt-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12932.857310209667!2d-84.6907735!3d35.8682642!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x885de3e1ed7e5dd1%3A0xd2920919e59c8396!2sPPM%20RACING%20PRODUCTS!5e0!3m2!1sen!2sin!4v1690222057531!5m2!1sen!2sin"
                  width={600}
                  height={450}
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row contactUs">
              <form onSubmit={contactHandler}>
                <h1>get in touch with us</h1>
                <p>877-560-0619</p>
                <p>OR</p>
                <div className="row  mt-5">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Name"
                        value={data.name}
                        onChange={handleChange}
                      />
                      <label htmlFor="floatingInput">
                        Name <span>*</span>
                      </label>
                      <p className={basestyle.error}>{formErrors.name}</p>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="form-floating mb-3">
                      <input
                        name="email"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Email"
                        value={data.email}
                        onChange={handleChange}
                      />
                      <label htmlFor="floatingInput">
                        Email <span>*</span>
                      </label>
                      <p className={basestyle.error}>{formErrors.email}</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        name="subject"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Subject"
                        value={data.subject}
                        onChange={handleChange}
                      />
                      <label htmlFor="floatingInput">
                        Subject <span>*</span>
                      </label>
                      <p className={basestyle.error}>{formErrors.subject}</p>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="form-floating mb-3">
                      <input
                        type="number"
                        name="phone"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Phone Number"
                        value={data.phone}
                        onChange={handleChange}
                      />
                      <label htmlFor="floatingInput">
                        Phone Number <span>*</span>
                      </label>
                      <p className={basestyle.error}>{formErrors.phone}</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating">
                    <textarea
                      placeholder="Enter Your Message"
                      name="message"
                      value={data.message}
                      onChange={handleChange}
                    ></textarea>
                    <p className={basestyle.error}>{formErrors.message}</p>
                  </div>
                  <div className="mt-2">
                    <button className="ppmBtn" id="reset">
                      Send
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
