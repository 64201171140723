import { useState } from "react";
import ClipLoader from 'react-spinners/ClipLoader';

function Spinner() {
  let [loading, setLoading] = useState(false)
  return (
    <div style={{ width: '100px', margin: 'auto', display: 'block' }}>
      <ClipLoader color="#52bfd9" size={100} loading={loading}/>
    </div>
  );
};
export default Spinner;