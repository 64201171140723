import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../constants/constants";

const ChassisMaps = () => {
  const [data, setData] = useState([]);

  const getData = async () => {
    const { data } = await axios.get(
      API_URL + "/admin/uploadfile/getChassisMaps"
    );

    setData(data?.file);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <section>
      <nav className="breadcrumb">
        <div className="container">
          <div className="row">
            <ul>
              <li>
                <Link to="/">
                  Home
                  <span className="ms-3">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </Link>
              </li>
              <li>
                <Link to={{}}>
                  Tech Resources
                  <span className="ms-3">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/chassisMap">Chassis Maps</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="mainContent">
        {/* <div className="container bgContent">
          <div className="row">
            <div className="pageTitle">
              <h1>Chassis Maps</h1>
              <p>Chassis Maps</p>
            </div>
          </div>
        </div> */}
        <div className="container bgContent">
          <div className="row">
            {/* <div className="pageTitle">
              <h1>Products</h1>
              <p>Products</p>
            </div> */}
          </div>
          <p
            style={{
              textAlign: "center",
              padding: "50px",
              position: "relative",
            }}
          >
            <span className="cate-heading">Chassis Maps</span>
            <img
              src="../images/textbanner.png"
              alt=""
              className="cate-image"
            ></img>
          </p>
        </div>
        <div className="container">
          <div className="pdfMain mt-5">
            {data?.map((item, index) => {
              let str = item?.file?.url;

              let flag = str?.includes(".pdf");

              if (flag) {
                return (
                  <div className="m-2" key={index}>
                    <div className="pdfListing">
                      <div className="pdfImage">
                        {/* <img src={item?.file?.url} alt="pdfIcon" /> */}
                        <img src="images/pdfIcon.png" />
                      </div>
                      <div className="pdfbottommapDiv">
                        <p className="pdfTitle">{item.name}</p>
                        <div className="pdfBtnss">
                          <a
                            target="_blank"
                            rel="noopener"
                            href={API_URL + `/` + `${item?.file?.url}`}
                            className="ppmBtn"
                          >
                            View
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="m-2" key={index}>
                    <div className="pdfListing">
                      <div className="pdfImage">
                        {/* <img src={item?.file?.url} alt="pdfIcon" /> */}
                        <img src="images/imageIcon.png" />
                      </div>
                      <div className="pdfbottommapDiv">
                        <p className="pdfTitle">{item.name}</p>
                        <div className="pdfBtnss">
                          <a
                            target="_blank"
                            rel="noopener"
                            href={API_URL + `/` + `${item?.file?.url}`}
                            className="ppmBtn"
                          >
                            View
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChassisMaps;
