import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../constants/constants";


const cartService = {
    getProductFromCart: async () => {
        const token = await JSON.parse(localStorage.getItem('userInfo'));
        const response = await axios.get(API_URL + '/user/dashboard/getProductFromCart',
            {
                headers: { Authorization: token }
            })
            .then((response) => {
                return response.data;
            }).catch((err) => {
                console.log(err)
            })
        return response;
    },

    addProductToCart: async cartDataFromStore => {
        const token = await JSON.parse(localStorage.getItem('userInfo'));
        if(!token){
            toast.error("You have to login first")
            return
        }
        const response = await axios.post(API_URL + '/user/dashboard/addProductToCart',
            cartDataFromStore,
            {
                headers: { Authorization: token },

            })
            .then((response) => {
                return response.data;
            }).catch((err) => {
                console.log(err)
            })
        return response;
    },

    removeProductFromCart: async (removeProduct) => {
        const token = await JSON.parse(localStorage.getItem('userInfo'));
        const response = await axios.post(API_URL + '/user/dashboard/removeProductFromCart',
            removeProduct,
            {
                headers: { Authorization: token }
            })
            .then((response) => {
                return response.data;
            }).catch((err) => {
                console.log(err)
            })
        return response;
    },

    increamentProductQuantityCart: async incProduct => {
        const token = await JSON.parse(localStorage.getItem('userInfo'));
        const response = await axios.post(API_URL + '/user/dashboard/IncProductQuantity', incProduct,
            {
                headers: { Authorization: token }
            })
            .then((response) => {
                return response.data;
            }).catch((err) => {
                console.log(err)
            })
        return response;
    },

    decreamentProductQuantityCart: async (decProduct) => {
        const token = await JSON.parse(localStorage.getItem('userInfo'));
        const response = await axios.post(API_URL + '/user/dashboard/decProductQuantity', decProduct,
            {
                headers: { Authorization: token }
            })
            .then((response) => {
                return response.data;
            }).catch((err) => {
                console.log(err)
            })
        return response;
    },


    removeProductQuantityCart: async () => {
        const token = await JSON.parse(localStorage.getItem('userInfo'));
        const response = await axios.post(API_URL + '/user/dashboard/removeProductFromCart',
            {
                headers: { Authorization: token }
            })
            .then((response) => {
                return response.data;
            }).catch((err) => {
                console.log(err)
            })
        return response;
    },

    checkUserCoupon: async (id) => {
        const token = await JSON.parse(localStorage.getItem('userInfo'));
        const response = await axios.get(API_URL + `/user/dashboard/checkUserCoupon/${id}`,  {
            headers: { Authorization: token }
        })
            .then((response) => {
                return response.data;
            }).catch((err) => {
                console.log(err)
            })
        return response;
    }



}
export default cartService;