import React, { useState, useEffect } from "react";
import basestyle from "../Base.module.css";
import authService from "../../../services/auth.service";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [userDetail, setUpdateDetails] = useState({
    newPassword: "",
    confPassword: "",
  });

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setUpdateDetails({
      ...userDetail,
      [name]: value,
    });
  };
  const resetHandler = (e) => {
    e.preventDefault();
    const validate = validateForm(userDetail);
    if (validate.newPassword || validate.confPassword) {
      setFormErrors(validate);
      return;
    }
    setIsSubmit(true);

    authService
      .resetPassword(userDetail, token)
      .then((res) => {
        if (res.status == 200) {
          swal("Success!", res.message, "success");
          navigate("/");
          setUpdateDetails({
            ...userDetail,
            newPassword: "",
            confPassword: "",
          });
          // dispatch(login(res.userData))
          window.location.reload();
        } else {
          swal("Error!", res.message, "error");
        }
      })
      .catch((err) => {
        swal("Error!", "Something went wrong", "error");
      });
  };
  const validateForm = (values) => {
    const error = {};
    const regex = /^[^\s+@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      error.email = "Email is required";
    } else if (!regex.test(values.email)) {
      error.email = "Please enter a valid email address";
    }
    if (!values.newPassword) {
      error.newPassword = "Password is required";
    } else if (values.newPassword.length < 8) {
      error.newPassword = "Password must be more than 8 characters";
    } else if (values.newPassword.length > 16) {
      error.newPassword = "Password cannot exceed more than 16 characters";
    }
    if (!values.confPassword) {
      error.confPassword = "Confirm Password is required";
    } else if (values.confPassword !== values.newPassword) {
      error.confPassword = "Confirm Password and Password should be same";
    }
    return error;
  };
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors]);

  return (
    <>
      <div className="sdMainContent pt-5">
        <div className="container bgContent">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <img
                className="img-fluid"
                src="../images/forgotPassword.png"
                alt="forgetimage"
              />
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 forgotPassword">
              <div className="row">
                <h1 className="mb-3 text-center">Reset Password</h1>
                {/* <div className="form-floating mb-3">
                                    <input type="text"
                                        className="form-control"
                                        id="floatingInputEmail"
                                        placeholder="name@example.com"
                                        name="email"
                                        onChange={changeHandler}
                                        value={userDetail.email} />
                                    <p className={basestyle.error}>{formErrors.email}</p>

                                    <label htmlFor="floatingInputEmail">Enter Email</label>
                                </div> */}
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingInputPassword"
                    placeholder="name@example.com"
                    name="newPassword"
                    onChange={changeHandler}
                    value={userDetail.newPassword}
                  />
                  <p className={basestyle.error}>{formErrors.newPassword}</p>

                  <label htmlFor="floatingInputPassword">
                    Enter New Password
                  </label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingInputPassword"
                    placeholder="name@example.com"
                    name="confPassword"
                    onChange={changeHandler}
                    value={userDetail.confPassword}
                  />
                  <p className={basestyle.error}>{formErrors.confPassword}</p>
                  <label htmlFor="floatingInputPassword">
                    Confirm Password
                  </label>
                </div>
                <div className="mt-2">
                  <button className="ppmBtn" onClick={resetHandler}>
                    Reset Password
                  </button>
                </div>
              </div>
              <hr className="mt-5" />
              <p className="dText align mt-3">OR</p>
              <div className="row">
                <div className="socialLinks">
                  <div>
                    <img src="../images/facebookFtr.png" />
                  </div>
                  <div>
                    <img src="../images/google.png" />
                  </div>
                  <div>
                    <img src="../images/twitterFtr.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
