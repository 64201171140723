import axios from "axios";
import { API_URL } from "../constants/constants";
import { toast } from "react-toastify";

const wishListService = {
  getProductFromWishlist: async () => {
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    const response = await axios
      .get(API_URL + "/user/dashboard/getProductFromWishList", {
        headers: { Authorization: token },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },

  addProductToWishlist: async (wishlistDataFromStore) => {
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    if (!token) {
      toast.error("You have to login first");
      return;
    }
    const response = await axios
      .post(
        API_URL + "/user/dashboard/addProductToWishList",
        wishlistDataFromStore,
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },

  removeProductFromWishlist: async (removeProduct) => {
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    const response = await axios
      .post(
        API_URL + "/user/dashboard/removeProductFromWishList",
        removeProduct,
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },

  increamentProductQuantityCart: async () => {
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    const response = await axios
      .post(API_URL + "/api/user/dashboard/IncProductQuantity", {
        headers: { Authorization: token },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },

  decreamentProductQuantityCart: async () => {
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    const response = await axios
      .post(API_URL + "/api/user/dashboard/decProductQuantity", {
        headers: { Authorization: token },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },

  removeProductQuantityCart: async () => {
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    const response = await axios
      .post(API_URL + "/api/user/dashboard/removeProductFromCart", {
        headers: { Authorization: token },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  },
};
export default wishListService;
