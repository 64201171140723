import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import productService from "../services/product-catagorie-service";
import brandService from "../services/brand-service";
import { AddCart, AddWishlist } from "../redux/actions/catagoryAction";
import { getAllProducts, getProductById } from "../redux/actions/productAction";
import { getAllBrands, getAllSideBrands } from "../redux/actions/brandAction";
import { getAllCategories } from "../redux/actions/catagoryAction";
import Filter from "../components/filter/Filter";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import cartService from "../services/cart-service";
// import { API_URL } from '../constants/constants';
import DropdownFilter from "../components/filter/DropdownFilter";
import { toast } from "react-toastify";
import wishListService from "../services/wishlist-service";
import { API_URL } from "../constants/constants";
import isUrl from "is-url";
import ReactPaginate from "react-paginate";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

const Allproducts = (props) => {
  const navigate = useNavigate();
  const cartDataStore = props.cartData || [];
  const wishListDataStore = props.wishlistData || [];
  const all_products = props.allproducts?.products || [];
  const prod_count = props?.allproducts?.productsCount || 0;
  const [products, setProducts] = useState([]);
  const [utype, setUtype] = useState("");
  let [loading, setLoading] = useState(true);
  // const [brands, setBrands] = useState([])
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [sidebrands, setSideBrands] = useState([]);
  const dispatch = useDispatch();
  let limit = 24;
  const [currentPage, setCurrent] = useState(1);
  const [pageCount, setpageCount] = useState(0);
  const [numDays, setNumDays] = useState(0);

  let sortingArr = localStorage.getItem("sortingArr");
  let previousPage = localStorage.getItem("secondLastPage");

  const pageCountFn = (data) => {
    setpageCount(data);
  };

  useEffect(() => {
    localStorage.removeItem("sortingArr");
  }, []);
  let currentItems = all_products;
  useEffect(() => {
    let uType = localStorage.getItem("userType");
    setUtype(uType);

    const initialArr = JSON.parse(localStorage.getItem("paginationArr"));

    if (initialArr && initialArr.length > 0) {
      let lastElement = initialArr[initialArr.length - 1];
      let payload = JSON.parse(localStorage.getItem("filteredData"));
      let userId = localStorage.getItem("userId");
      payload.userId = userId;

      setTimeout(async () => {
        const nextProducts = await fetchProducts(lastElement, payload);
        dispatch(getAllProducts(nextProducts));
      }, 1000);
    }
    // productService
    //   .getProducts(products)
    //   .then((res) => {
    //     dispatch(getAllProducts(res));
    //     setProducts(res);
    //     const total = res?.productsCount || 0;
    //     setpageCount(Math.ceil(total / limit));
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    productService
      .getCategories(categories)
      .then((res) => {
        dispatch(getAllCategories(res.categories));
        setCategories(res.categories);
      })
      .catch((err) => {
        console.log(err);
      });

    brandService
      .getBrands(brands)
      .then((res) => {
        dispatch(getAllBrands(res.brands));
        setBrands(res.brands);
      })
      .catch((err) => {
        console.log(err);
      });
    // fetchProducts()

    brandService
      .SideBrands(brands)
      .then((res) => {
        dispatch(getAllSideBrands(res.brands));
        setSideBrands(res.brands);
      })
      .catch((err) => {
        console.log(err);
      });

    // setCurrent(1);

    // localStorage.removeItem("secondLastPage");
  }, []);

  const fetchProducts = async (currentPage, payload) => {
    const res = await axios.post(
      `${API_URL}/admin/product/getProducts?limit=${limit}&page=${currentPage}`,
      payload
    );
    const data = await res?.data;
    return data;
  };

  const handlePageClick = async (data) => {
    let payload = JSON.parse(localStorage.getItem("filteredData"));
    if (sortingArr) {
      if (sortingArr == "3") {
        payload.sortBy = "highToLow";
      } else if (sortingArr == "4") {
        payload.sortBy = "lowToHigh";
      }
    }

    let arr = [1];
    arr.push(data.selected + currentPage);
    const pageNumber = data.selected + currentPage;
    const existArr = JSON.parse(localStorage.getItem("paginationArr"));
    if (existArr) {
      const findIndex = existArr.findIndex((v) => v == pageNumber);
      if (findIndex == -1) {
        existArr.push(data.selected + 1);
        localStorage.setItem("paginationArr", JSON.stringify(existArr));
      } else {
        localStorage.setItem("paginationArr", JSON.stringify(existArr));
      }
    } else {
      localStorage.setItem("paginationArr", JSON.stringify(arr));
    }

    localStorage.setItem("secondLastPage", data.selected + currentPage);
    let userId = localStorage.getItem("userId");
    payload.userId = userId;
    const nextProducts = await fetchProducts(
      data.selected + currentPage,
      payload
    );
    dispatch(getAllProducts(nextProducts));
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    addEventListener("popstate", (e) => {
      (async () => {
        const initialArr = JSON.parse(localStorage.getItem("paginationArr"));
        e.preventDefault();
        if (initialArr.length > 1) {
          navigate("/products");
          setTimeout(async () => {
            let payload = JSON.parse(localStorage.getItem("filteredData"));
            let userId = localStorage.getItem("userId");
            payload.userId = userId;
            const arrLength = initialArr.length;
            const secondLastPage = initialArr[arrLength - 2];
            initialArr.pop();
            localStorage.setItem("secondLastPage", secondLastPage);
            localStorage.setItem("paginationArr", JSON.stringify(initialArr));
            const nextProducts = await fetchProducts(secondLastPage, payload);
            dispatch(getAllProducts(nextProducts));
            window.scrollTo(0, 0);
          }, 500);
        } else {
          localStorage.removeItem("paginationArr");
          localStorage.removeItem("secondLastPage");
          navigate("/");
        }
      })();
    });
  }, [navigate]);

  const AddCartHandler = async (product) => {
    props.AddCart(product);
    const addedProduct = cartDataStore.find(
      (cartRecord) => cartRecord.id === product?._id
    );
    const cartData = [
      {
        productId: addedProduct?.id,
        quantity: addedProduct?.quantity,
      },
    ];
    await cartService
      .addProductToCart(cartData)
      .then((res) => {
        toast.success(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchProductById = (id) => {
    productService
      .getProductsById(id)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getProductById(res.product));
          navigate(`/productDetail/${id}`);
          window.location.reload(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Add wishlist

  const WishlistHandler = async (product) => {
    props.AddWishlist(product);
    const addedWishlistProduct = wishListDataStore.find(
      (wishlistRecord) => wishlistRecord.id === product?._id
    );
    const wishlistData = [
      {
        productId: addedWishlistProduct?.id,
      },
    ];
    await wishListService
      .addProductToWishlist(wishlistData)
      .then((res) => {
        toast.success(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDays = () => {
    brandService
      .getNoofDays()
      .then((res) => {
        setNumDays(res?.newTagDays?.days);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    //Runs only on the first render
    getDays();
  }, []);

  setTimeout(() => {
    setLoading(false);
  }, 4000);

  const date = new Date();

  return (
    <section>
      <nav className="breadcrumb">
        <div className="container">
          <div className="row">
            <ul>
              <li>
                <NavLink to="/">
                  Home
                  <span className="ms-3">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/products">Products</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="mainContent">
        <div className="container bgContent">
          <div className="row">
            <div className="pageTitle">
              <h1>Products </h1>
              <p>Products</p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mt-5">
            <Filter />
            <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <DropdownFilter pageCountFn={pageCountFn} />
              {currentItems.length === 0 ? (
                <>
                  <div
                    style={{ width: "100px", margin: "auto", display: "block" }}
                  >
                    <ClipLoader color="#52bfd9" size={100} loading={loading} />
                  </div>
                  {!loading ? (
                    <>
                      <p>Sorry there are no products to display</p>
                    </>
                  ) : null}
                </>
              ) : (
                <div className="pListingMain">
                  {!!currentItems.length &&
                    currentItems?.map((product, index) => {
                      let oldDate = new Date(product?.createdAt);

                      let Difference_In_Time =
                        date?.getTime() - oldDate?.getTime();

                      // To calculate the no. of days between two dates
                      let Difference_In_Days =
                        Difference_In_Time / (1000 * 3600 * 24);

                      return (
                        <>
                          <div className="productListing" key={index}>
                            <a href={`/productDetail/${product?._id}`}>
                              <div className="plImage">
                                {product?.image[0]?.url ? (
                                  // product.image.map((img, index) => (
                                  //   <>
                                  //     {isUrl(img.url) ? (
                                  //       <img
                                  //         key={index}
                                  //         src={img?.url}
                                  //         width="100%"
                                  //         alt={img?.name}
                                  //       ></img>
                                  //     ) : (
                                  //       <img
                                  //         key={index}
                                  //         src={API_URL + "/" + product?.image[0]?.url}
                                  //         width="100%"
                                  //         alt={img?.name}
                                  //       ></img>
                                  //     )}
                                  //   </>
                                  // ))
                                  <>
                                    <img
                                      src={
                                        isUrl(product?.image[0]?.url)
                                          ? product?.image[0]?.url
                                          : product?.image[0]?.url == null ||
                                            product?.image[0]?.url == "N/A"
                                          ? "./images/dummyProduct.jpg"
                                          : API_URL +
                                            "/" +
                                            product?.image[0]?.url
                                      }
                                      width="100%"
                                      alt={"img?.name"}
                                    ></img>
                                  </>
                                ) : (
                                  <img
                                    src="./images/dummyProduct.jpg"
                                    alt="dummyimage"
                                  ></img>
                                )}
                                {Difference_In_Days < numDays && (
                                  <span>New</span>
                                )}
                              </div>
                            </a>

                            <div className="plInfo">
                              <div className="reviews">
                                {(() => {
                                  const arr = [];
                                  let rating = Math?.ceil(product?.rating);
                                  let remaining = 5 - rating;
                                  for (let j = 0; j < rating; j++) {
                                    arr.push(
                                      <i className="fa-solid fa-star"></i>
                                    );
                                  }
                                  for (let j = 0; j < remaining; j++) {
                                    arr.push(
                                      <i className="fa-regular fa-star"></i>
                                    );
                                  }
                                  return arr;
                                })()}
                              </div>
                              <p>{product?.name}</p>
                              {/* <span>${product.oldPrice ? product.oldPrice : product.price}</span>
                              <p>$ {product?.price}</p> */}
                              <div className="dealsPrice mb-3">
                                <span>
                                  {/* ${product?.oldPrice ? product.oldPrice : product.price} */}
                                  {product?.oldPrice
                                    ? "$" + product?.oldPrice
                                    : null}
                                </span>
                                <span>${product?.price}</span>
                              </div>
                            </div>
                            <div className="plBtns">
                              <button
                                className="atcBtn"
                                onClick={() => AddCartHandler(product)}
                              >
                                Add to Cart
                              </button>
                              <button
                                className="productBtn"
                                onClick={() => WishlistHandler(product)}
                                title="Add to wishlist"
                              >
                                <i className="fa-solid fa-heart"></i>
                              </button>
                              <button
                                className="productBtn"
                                onClick={() => fetchProductById(product._id)}
                                title="Product detail"
                              >
                                <i className="fa-solid fa-eye"></i>
                              </button>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              )}

              {/* <div className="row">
              {/* <div className="row">
              {/* <div className="row">
              {/* <div className="row">
              {/* <div className="row">
              {/* <div className="row">
              {/* <div className="row">
              {/* <div className="row">
              {/* <div className="row">
              {/* <div className="row">
              {/* <div className="row">
              {/* <div className="row">
              {/* <div className="row">
                        </div> */}

              {/* <div className="ppmpagination">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-end">
                    <li className="page-item disabled">
                      <a className="page-link">Previous</a>
                    </li>
                    <li className="page-item"><a className="page-link" href="/#">1</a></li>
                    <li className="page-item"><a className="page-link" href="/#">2</a></li>
                    <li className="page-item"><a className="page-link" href="/#">3</a></li>
                    <li className="page-item">
                      <a className="page-link" href="/#">Next</a>
                    </li>
                  </ul>
                </nav>
              </div> */}
              {currentItems?.length == 0 ? (
                ""
              ) : (
                <>
                  <div className="pagination_space">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      forcePage={
                        previousPage ? previousPage - 1 : currentPage - 1
                      }
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={
                        prod_count
                          ? Math.ceil(prod_count / limit) - 1
                          : pageCount - 1
                      }
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

function mapStateToProps(state) {
  return {
    cartData: state?.catagoryReducer?.Carts,
    wishlistData: state?.catagoryReducer?.Wishlists,
    products: state?.catagoryReducer?.products,
    allproducts: state?.productReducer?.products,
    allCategories: state?.catagoryReducer?.categories,
    allBrands: state?.brandReducer?.brands,
    isLoggedIn: state?.auth?.isLoggedIn,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    AddCart: (product) => dispatch(AddCart(product)),
    AddWishlist: (product) => dispatch(AddWishlist(product)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Allproducts);
