import React, { useEffect, useState } from "react";
import productService from "../services/product-catagorie-service";
import { NavLink, Link } from "react-router-dom";
import { API_URL } from "../constants/constants";
import { toast } from "react-toastify";
import axios from "axios";

const Footer = () => {
  const [categories, setCategories] = useState([]);
  const [email, setEmail] = useState("");
  const [footerBanners, setFooterBanners] = useState([]);

  const getFooterBanners = async () => {
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    const data = await axios.get(
      API_URL + "/admin/admindashboard/getFooterBanners"
    );

    setFooterBanners(data?.data?.footerBanner?.image?.[0]?.url);
  };

  const handleSubmit = (e) => {
    // Prevent the default submit and page reload
    e.preventDefault();
    const emailRegex = /^[^\s+@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!emailRegex.test(email)) {
      toast.error("This is not a valid email");
    } else {
      // Handle validations
      axios
        .post(API_URL + "/user/dashboard/subscribe", { email: email })
        .then((response) => {
          toast.success(response?.data?.message);
          // Handle response
        });
    }
  };

  useEffect(() => {
    getFooterBanners();
  }, []);

  useEffect(() => {
    productService
      .getCategories(categories)
      .then((res) => {
        if (res?.status === 200) {
          // dispatch(getProductById(res.product))
          // navigate(`/productDetail/${id}`)
          if (res?.categories.length == 0) {
            return [];
          } else {
            let arr = res?.categories;

            const chooseRandom = (arr, num = 1) => {
              const res = [];
              for (let i = 0; i < num; ) {
                const random = Math.floor(Math.random() * arr?.length);
                if (res.indexOf(arr[random]) !== -1) {
                  continue;
                }
                res.push(arr[random]);
                i++;
              }
              return res;
            };
            let fiveCat = chooseRandom(arr, 5);
            setCategories(fiveCat);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      {/* ================= */}

      <footer
        className="bgFooter"
        style={{
          backgroundImage: `url(${API_URL + "/" + footerBanners})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 mb-5">
              <Link to="/">
                <div className="logoFtr mb-3">
                  <img
                    src="../images/newppm_logo.svg"
                    style={{ width: "100%" }}
                  />
                </div>
              </Link>
              {/* <div className="socialLinks mb-3">
                <div>
                  <a
                    href="https://www.facebook.com/ppmracingproducts"
                    target="_blank"
                  >
                    <img src="../images/facebookFtr.png" />
                  </a>
                </div>
                <div>
                  <a href="https://www.instagram.com/team_ppm/" target="_blank">
                    <img src="../images/instagramFtr.png" />
                  </a>
                </div>
                <div>
                  <a href="https://twitter.com/Team_PPM" target="_blank">
                    <img src="../images/twitterFtr.png" />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.youtube.com/channel/UCbKyStptRvf3Bq3sRTFyRrA"
                    target="_blank"
                  >
                    <img src="../images/youtubeFtr.png" />
                  </a>
                </div>
              </div> */}
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-3 col-xs-12 titleFtr mb-5">
              <h4 className="mb-4">Company</h4>
              <ul className="links f-20 cstm_txt_align">
                <li className="company">
                  201 S. Wilder Ave. <br />
                  Rockwood, TN. <br />
                  37854.
                </li>
                <li className="company">Mon-Fri: 8:00 AM - 5:00 PM (EST.).</li>
                <li className="company">Saturday- Closed.</li>
                <li className="company">Sunday- Closed.</li>
                {/* <li className="company">
                  M-TH: 8:30 AM - 5:30 PM(CT)
                </li> */}
              </ul>
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-3 col-xs-12 titleFtr mb-5">
              <h4 className="mb-4">Policy</h4>
              <ul className="links f-20 cstm_txt_align">
                <li>
                  <NavLink to="/TermsOfUsage" className="f-20">
                    Terms of Usage
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/privacyPolicy" className="f-20">
                    Privacy Policy
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/shippingReturnPolicy" className="f-20">
                    Shipping & Return Policy
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-3 col-xs-12 titleFtr mb-5">
              <h4 className="mb-4">Contact</h4>
              <ul className="links f-20 cstm_txt_align">
                <li>
                  <span className="company">TollFree</span>
                  <br />
                  <span className="company">(877)560-0619</span>
                </li>
                <li>
                  <span className="company">(865)354-9669</span>
                </li>
                <li>
                  <Link to="/contactus" className="f-20">
                    {" "}
                    <i class="fa-solid fa-envelope"></i>&nbsp;&nbsp; Send a
                    Message
                  </Link>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/ppmracingproducts"
                    target="_blank"
                    className="f-20"
                  >
                    <i class="fa-brands fa-facebook-f"></i>&nbsp;&nbsp;Like Us
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/team_ppm/"
                    target="_blank"
                    className="f-20"
                  >
                    <i class="fa-brands fa-instagram"></i>&nbsp;&nbsp;Like Us
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/Team_PPM"
                    target="_blank"
                    className="f-20"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 1200 1227"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_1_2)">
                        <path
                          d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1_2">
                          <rect width="1200" height="1227" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    &nbsp;&nbsp;Follow Us
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCbKyStptRvf3Bq3sRTFyRrA"
                    target="_blank"
                    className="f-20"
                  >
                    <i class="fa-brands fa-youtube"></i>&nbsp;&nbsp;Subscribe
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 titleFtr mb-3 w-25">
              <h4 className="mb-4 f-20">Subscribe</h4>
              <p className="f-20">
                Subscribe to our newsletter, so that you can be the first to
                know about new offers and promotions.
              </p>
              <form method="post" onSubmit={handleSubmit}>
                <div className="formDetails subInput mb-3 d-flex">
                  <input
                    className="form-control"
                    type="email"
                    name="userEmail"
                    placeholder="Enter Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button className="subBtn">Subscribe</button>
                </div>
              </form>
              <ul className="links">
                <a
                  href="/requestcatalog"
                  style={{ background: "#ed3237", color: "white" }}
                  className="btn btn-lg"
                >
                  Request Catalog
                </a>
              </ul>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="copyright">
              <span>© 2022. All Rights Reserved.</span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
