import { GET_USER_BY_ID, UPDATE_USER } from "../actions/actionTypes";

const initialState = {
  user: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_BY_ID:
      return {
        ...state,
        user: payload,
      };
      case UPDATE_USER:
            return {
                ...state,
                isLoggedIn: true,
                 user: {...payload},
            };
    default:
      return state;
  }
}
