import React, { useEffect, useState } from "react";
import { useNavigate, NavLink, json } from "react-router-dom";
import TotalAmount from "../utils/cartAmountHelper";
import { connect } from "react-redux";
import orderServices from "../services/order-services";
import fedexServices from "../services/fedex-service";
import { toast } from "react-toastify";
import basestyle from "../pages/Auth/Base.module.css";
import PaypalGetway from "../components/PaypalGetway";
import Cities from "../Cities.json";
import CryptoJS from 'crypto-js';

import axios from "axios";
import { API_URL } from "../constants/constants";
import ClipLoader from "react-spinners/ClipLoader";

import {
  SECRET_KEY,
} from "../constants/constants";

const Checkout = (props) => {
  // const navigate = useNavigate();
  let cartData = props.cartProducts;
  const cartProducts = props.cartProducts;
  const [country, setCountry] = useState([]);
  const [myState, setMyState] = useState("");
  const [shipState, setShipState] = useState("");
  const [city, setCity] = useState([]);
  const [shipCity, setShipCity] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [checkAdd, setCheckAdd] = useState(false);
  const [orderId, setOrderId] = useState("");
  // const navigateToPaypal = () => navigate('/paypalGetway')
  const cartDataproduct = props.cartProductData.data || [];
  const getTotal = TotalAmount(cartDataproduct);
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  const [shippingFee, setShippingFee] = useState([]);
  const [shippingError, setShippingError] = useState("");
  const [fedEX, setfedEX] = useState(0);
  const [fedShip, setFedShip] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [taxRate, setTaxRate] = useState(0);
  const [taxShipRate, setTaxShipRate] = useState(0);
  const [fedexPer, setFedexPer] = useState(false);
  let [loading, setLoading] = useState(true);
  const [zipData, setZipData] = useState("");
  const [couponAmount, setCouponAmount] = useState(0);
  const [couponType, setCouponType] = useState("");
  const [selectedFedexValue, setSelectedFedexValue] = useState(null);
  const [taxVal, setTaxVal] = useState(0);

  // const [fedexToken, setFedexToken] = useState("");

  

  const stateUS = Object.keys(Cities)?.sort();
  const shipStateUS = Object.keys(Cities)?.sort();

  

  const onOptionChange = (e) => {
    const value = e.target.dataset.customValue;
    
    setfedEX(e.target.value);
    var tPrice = localStorage.getItem("beWecirPlatot");
    
    // setTotalAmount(tPrice);
    setSelectedFedexValue(value);
  };

  const [userDetail, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    addressLine1: "",
    addressLine2: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    shipAddressLine1: "",
    shipAddressLine2: "",
    shipState: "",
    shipZip: "",
    shipCity: "",
    shipCountry: "",
  });

  
  const onAddCheck = (e) => {
    if (!e.target.checked) {
      setCheckAdd(false);
      setUserDetails({
        ...userDetail,
        shipState: "",
        shipZip: "",
        shipCity: "",
        shipAddressLine1: "",
        shipAddressLine2: "",
      });
      calculateTaxAuto();
    } else {
      setCheckAdd(true);
    }
  };
  let fedExShip;
  useEffect(() => {
    let userId = localStorage.getItem("userId");
    orderServices
      .getUserDetailById(userId)
      .then((res) => {
        if (res?.status === 200) {
          // setUserDetails(res.userDetails)

          const userInfo = res.userDetails;
          // calculateTaxAuto(userInfo[0]?.state);
          if (userInfo) {
            let sortedCities = Cities[userInfo[0]?.state]?.sort();
            let sortedShipCities = Cities[userInfo[0]?.shipState]?.sort();
            setCity(sortedCities);
            setShipCity(sortedShipCities);
          }

          if (checkAdd) {
            setUserDetails({
              firstName: userInfo[0]?.firstName,
              lastName: userInfo[0]?.lastName,
              email: userInfo[0]?.userId?.email,
              phone: userInfo[0]?.phone,
              addressLine1: userInfo[0]?.addressLine1,
              addressLine2: userInfo[0]?.addressLine2,
              state: userInfo[0]?.state,
              city: userInfo[0]?.city,
              zip: userInfo[0]?.zip,
              country: userInfo[0]?.country,
              shipAddressLine1: userInfo[0]?.shipAddressLine1,
              shipAddressLine2: userInfo[0]?.shipAddressLine2,
              shipState: userInfo[0]?.shipState,
              shipCity: userInfo[0]?.shipCity,
              shipZip: userInfo[0]?.shipZip,
              shipCountry: userInfo[0]?.country,
            });
          } else {
            setUserDetails({
              firstName: userInfo[0]?.firstName,
              lastName: userInfo[0]?.lastName,
              email: userInfo[0]?.userId?.email,
              phone: userInfo[0]?.phone,
              addressLine1: userInfo[0]?.addressLine1,
              addressLine2: userInfo[0]?.addressLine2,
              state: userInfo[0]?.state,
              city: userInfo[0]?.city,
              zip: userInfo[0]?.zip,
              country: userInfo[0]?.country,
              shipAddressLine1: "",
              shipAddressLine2: "",
              shipState: "",
              shipCity: "",
              shipZip: "",
              shipCountry: "",
            });
          }
          // zipCodeLookUp();
          // zipCodeLookUpShip();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  
  const zipCodeLookUp = async () => {
    try {
      const zipResponse = await axios.get(
        `https://api.zippopotam.us/us/${userDetail.zip}`,
        true
      );
      let stateCode = zipResponse.data.places[0];

      setMyState(stateCode?.state);
      setZipData(zipResponse.data.places[0]);
      setUserDetails({
        ...userDetail,
        state: stateCode?.state,
        city: stateCode["place name"],
      });
      
    } catch (error) {
       console.log(error);
    }
  };

  const zipCodeLookUpShip = async () => {
    try {
      const zipResponse = await axios.get(
        `https://api.zippopotam.us/us/${userDetail.shipZip}`,
        true
      );
      let stateCodeShip = zipResponse.data.places[0];

      
      setShipState(stateCodeShip?.state);

      setZipData(zipResponse.data.places[0]);
      setUserDetails({
        ...userDetail,
        shipState: stateCodeShip?.state,
        shipCity: stateCodeShip["place name"],
      });
    } catch (error) {
      console.log(error);
    }
  };

  async function calculateTaxAuto() {
    // fetching the tax rate value from the corresponding
    // let stateName = myState ?? userDetail.state;
    let stateZip = userDetail?.zip;
    let stateShipZip = userDetail?.shipZip;
    let state= userDetail?.state
      
      let newState=myState || state
   
    if (stateShipZip?.length == 5 && checkAdd) {
      const url = `${API_URL}/user/dashboard/getStateTax`;
      const data = "test";
      const token = await JSON.parse(localStorage.getItem("userInfo"));
      const response = await axios.post(url, data, {
        headers: { Authorization: token },
      });
      let taxData = response?.data?.stateTaxDetails;

      let taxPercentage = taxData?.filter(function (e) {
        return e?.state.toUpperCase() == shipState.toUpperCase();
      });
      
      let calculatedTax = taxPercentage?.[0]?.tax ?? 0;
      
      if (calculatedTax <= 0) {
        localStorage.removeItem("shipTaxVal");
      }
      setTaxRate(calculatedTax);
    } else if (stateZip?.length == 5 && !checkAdd) {
      
      const url = `${API_URL}/user/dashboard/getStateTax`;
      const data = "test";
      const token = await JSON.parse(localStorage.getItem("userInfo"));
      const response = await axios.post(url, data, {
        headers: { Authorization: token },
      });
      let taxData = await response?.data?.stateTaxDetails;
      
      let taxPercentage = taxData?.filter(function (e) {
        return e?.state.toUpperCase() == newState.toUpperCase();
      });
      let calculatedTax = taxPercentage?.[0]?.tax ?? 0;
      
      if (calculatedTax <= 0) {
        localStorage.removeItem("shipTaxVal");
      }
      setTaxRate(calculatedTax);
    }
  }

  useEffect(() => {
    let zip = userDetail?.zip;

   

    if (zip?.length == 5 && fedexPer) {
      zipCodeLookUp();
    }
   

    calculateTaxAuto();
  }, [userDetail.zip, myState]);

  useEffect(() => {
    let shipZip = userDetail?.shipZip;
    if (shipZip?.length == 5 && fedexPer) {
      zipCodeLookUpShip();
      setCheckAdd(true);
    }
    
    calculateTaxAuto();
  }, [userDetail?.shipZip, shipState]);

  useEffect(() => {
    fedexServices.getFedexStatus().then((res) => {
      setFedexPer(res?.fedexPermission?.isEnable);
    });

    fedexServices
      .getFedexToken()
      .then((res) => {
        if (res?.status === 200) {
          let convertedToken = JSON.parse(res?.fedexApiAuthToken);
          let fedexTokenVal = convertedToken?.access_token;
          
          localStorage.setItem("fedex_token", fedexTokenVal);

          // setFedexToken(fedexTokenVal);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const countries = [{ id: "1", name: "USA" }];

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetail,
      [name]: value,
    });
  };

  const handleCountry = (countryName) => {
    setUserDetails({ ...userDetail, country: countryName });
    setCountry(countryName);
  };

  const handleCity = (cityName) => {
    setUserDetails({ ...userDetail, city: cityName });
  };
  const handleShipCity = (cityName) => {
    setUserDetails({ ...userDetail, shipCity: cityName });
  };

  

  const validateForm = (values) => {
    const error = {};
    const emailRegex = /^[^\s+@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const postalCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    const phoneRegex = /^[0-9\b]+$/;
    if (!values.firstName) {
      error.fname = "First Name is required";
    } else if (!values.firstName.match(/^[a-zA-Z ]*$/)) {
      error.fname = "Please enter valid first name";
    } else if (values.firstName.length > 16) {
      error.fname = "First name value cannot exceed more than 16 characters";
    }
    if (!values.lastName) {
      error.lname = "Last Name is required";
    } else if (!values.lastName.match(/^[a-zA-Z ]*$/)) {
      error.lname = "Please enter valid last name";
    } else if (values.lastName.length > 16) {
      error.lname = "Last name value cannot exceed more than 16 characters";
    }
    if (!values.email) {
      error.email = "Email is required";
    } else if (!emailRegex.test(values.email)) {
      error.email = "This is not a valid email format";
    }

    if (!values.phone) {
      error.phone = "Phone no. is required";
    } else if (!phoneRegex.test(values.phone) || values.phone.length !== 10) {
      error.phone = "Please enter a 10-digit phone no.";
    }
    if (!values.addressLine1) {
      error.addressLine1 = "Address Line1 required";
    }
    // if (!values.country) {
    //     error.country = "Country required";
    // }
    // if (!values.state) {
    //     error.state = "State required";
    // }
    // if (!values.city) {
    //     error.city = "City is required";
    // }
    if (!values.zip) {
      error.zip = "Zip Code required";
    } else if (!postalCodeRegex.test(values.zip)) {
      error.zip = "Please enter a 5-digit zip code.";
    }

    return error;
  };
  let fedExShipfn = () => {
    let zip = userDetail.zip;
    let shipZip = userDetail.shipZip;

   
  

    // return

    if (shipZip?.length == 5 && fedexPer) {
      fedexServices
        .fedexAddessVerification(userDetail, cartData)
        .then((res) => {
         
          if (res?.data?.status === 200) {
            
            setShippingFee(res?.data?.fedexChargesDetails);
            setShippingError("");
          }
          if (res?.data?.status == 500) {
            setShippingFee([]);
            setShippingError(
              "Something went wrong. You may need to enter the correct zip code and wait a few moments."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          setShippingError();
        });
    } else if (zip?.length == 5 && fedexPer) {
      fedexServices
        .fedexAddessVerification(userDetail, cartData)
        .then((res) => {
          
          if (res?.data?.status === 200) {
            
            setShippingFee(res?.data?.fedexChargesDetails);
            setShippingError("");
          }
          if (res?.data?.status == 500) {
            setShippingFee([]);
            setShippingError(
              "Something went wrong. You may need to enter the correct zip code and wait a few moments."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          setShippingError();
        });
    }
  };

  var tAmount;
  const totalPriceFn = (price, percent, fedEX) => {
    

    if (couponType === "Free Shipping") {
      tAmount = (price + Number(percent) + 0).toFixed(2);
      

      localStorage.setItem("beWtnuomAgnippihs", Number(fedEX));
      //  settotalAmount(tAmount)
      
      // if (tAmount < 0) {
      //   localStorage.setItem("beWecirPlatot", 0);
      //   return 0;
      // }

      // Important Code
      // const encrypted = CryptoJS.AES.encrypt(tAmount, SECRET_KEY).toString();
      // Important Code

      localStorage.setItem("beWecirPlatot", tAmount);
      return tAmount;
    } else {
      let netPrice = price - couponAmount < 0 ? 0 : price - couponAmount;

      

      tAmount = (netPrice + Number(percent) + Number(fedEX)).toFixed(2);
      
      localStorage.setItem("beWtnuomAgnippihs", Number(fedEX));
      //  settotalAmount(tAmount)
    
      // if (tAmount < 0) {
      //   localStorage.setItem("beWecirPlatot", 0);
      //   return 0;
      // }
      localStorage.setItem("beWecirPlatot", tAmount);
      return tAmount;
    }
  };

  const shippingAddHandler = async (e) => {
    e.preventDefault();
    let couponId = localStorage.getItem("couponId");
    const validate = validateForm(userDetail);
    setFormErrors(validate);
    if (!!Object.keys(validate).length) {
      setFormErrors(validate);
      return;
    }
    setIsSubmit(true);

    // if (fedexPer && fedShip == 0) {
    //   return toast.error("Please select the Convenience Fee");
    // }

    // setIsMannualSignup(true)
    // return
    orderServices
      .placeOrder({
        ...userDetail,
        totalMrp: Number(getTotal?.price) ?? 0,
        discountOnMrp: 0,
        convenienceFee: Number(fedShip) ?? 0,
        couponDiscount: Number(couponAmount) ?? 0,
        serviceType: selectedFedexValue ?? "N/A",
        couponId: couponId,
        modeOfPayment:"Paypal",
        salesTax: Number(taxVal) ?? 0,
        totalAmount: Number(totalAmount) ?? 0,
      })
      .then((res) => {
        if (res.status === 200) {
          setShowPaymentMethod(true);
          toast.success(res.message);
          setOrderId(res.orderId);
        } else if (res.status === 403) {
          toast.error(res.message);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // //console.log(user);
    }
  }, [formErrors]);

  useEffect(() => {
    setCountry(countries);
    // calculateTaxAuto();
    let camount = localStorage.getItem("camount");
    setCouponAmount(camount);
    const cType = localStorage.getItem("couponType");
  
    setCouponType(cType);
  }, []);

  function percentageTaxValue(tax, mrpValue, totalValue) {
    const salesTax = (tax / totalValue) * mrpValue;
    // setTaxVal(salesTax);
    localStorage.setItem("shipTaxVal", salesTax.toFixed(2));
    return salesTax;
  }

  const handleBlur = () => {
    fedExShipfn();
  };

  useEffect(() => {
    //Runs on every render
    fedExShip = localStorage.getItem("beWtnuomAgnippihs");
    setFedShip(fedExShip);
    var tPrice = localStorage.getItem("beWecirPlatot");
    setTotalAmount(tPrice);
    var shipTaxV = localStorage.getItem("shipTaxVal");
    setTaxVal(shipTaxV);
  });

  useEffect(() => {
    fedExShipfn();
  }, [userDetail.zip, fedexPer, userDetail.shipZip, myState]);


  return (
    <>
      <nav className="breadcrumb">
        <div className="container">
          <div className="row">
            <ul>
              <li>
                <li>
                  <NavLink to="/">
                    Home
                    <span className="ms-3">
                      <i className="fa-solid fa-angle-right"></i>
                    </span>
                  </NavLink>
                </li>
              </li>
              <li>
                <NavLink to="/cart">
                  {" "}
                  Cart
                  <span className="ms-3">
                    <i className="fa-solid fa-angle-right" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/checkout">Checkout</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="sdMainContent">
        <div className="container">
          <div className="row cart gx-5">
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
              <div className="row bgContent shippingDetails">
                <div className="progressBar">
                  <div className="container p-0">
                    <div className="progress-container">
                      <div className="progress" id="progress" />
                      <div className="circle active">
                        <span />
                      </div>
                      <div className="circle">
                        <span />
                      </div>
                    </div>
                    <div className="progressTitle mb-5">
                      <h5 className="active m-0 hTitle1">Shipping Details</h5>
                      <h5 className="m-0 hTitle2">Billing Details</h5>
                    </div>
                  </div>
                </div>
                {!showPaymentMethod ? (
                  <div id="sDetails-">
                    {/* <div className="login mb-3">
                                        <span className="me-2">Already Have An Account?</span>
                                        <a>Click Here To Login</a>
                                    </div> */}
                    <div className="detailsTitle">
                      <p>Personal Details</p>
                      <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="First Name"
                              name="firstName"
                              id="firstName"
                              onChange={changeHandler}
                              value={userDetail.firstName}
                            />
                            <p className={basestyle.error}>
                              {formErrors.fname}
                            </p>
                            <label htmlFor="floatingInput">
                              First Name <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Last Name"
                              name="lastName"
                              id="lastName"
                              onChange={changeHandler}
                              value={userDetail.lastName}
                            />
                            <p className={basestyle.error}>
                              {formErrors.lname}
                            </p>

                            <label htmlFor="floatingInput">
                              Last Name <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="form-floating mb-3">
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email Address"
                              name="email"
                              id="email"
                              onChange={changeHandler}
                              value={userDetail.email}
                            />
                            <p className={basestyle.error}>
                              {formErrors.email}
                            </p>

                            <label htmlFor="floatingInput">
                              Email Address{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="form-floating mb-3">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Phone Number"
                              name="phone"
                              id="phone"
                              onChange={changeHandler}
                              value={userDetail.phone}
                            />
                            <p className={basestyle.error}>
                              {formErrors.phone}
                            </p>

                            <label htmlFor="floatingInput">
                              Phone Number{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailsTitle">
                      <p>Address</p>
                      <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Address Line 1"
                              name="addressLine1"
                              id="addressLine1"
                              onChange={changeHandler}
                              value={userDetail.addressLine1}
                            />
                            <p className={basestyle.error}>
                              {formErrors.addressLine1}
                            </p>
                            <label htmlFor="floatingInput">
                              Address Line 1
                              <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Enter Address Line 2"
                              name="addressLine2"
                              onChange={changeHandler}
                              value={userDetail.addressLine2}
                            />
                            <label htmlFor="floatingInput">
                              Address Line 2
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="form-floating mb-3">
                            <select
                              className="form-select"
                              aria-label="Floating label select example"
                              onChange={(e) => handleCountry(e.target.value)}
                              name="country"
                              id="country"
                            >
                              <option>Select</option>
                              {countries.map((ctr, index) => {
                                return (
                                  <option key={index} value={ctr.name} selected>
                                    {ctr.name}
                                  </option>
                                );
                              })}
                            </select>
                            <label
                              className="dropdownLabel"
                              htmlFor="floatingSelect"
                            >
                              Country
                            </label>
                          </div>
                        </div>

                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Zip Code"
                              name="zip"
                              id="zip"
                              onChange={changeHandler}
                              value={userDetail.zip}
                              onBlur={handleBlur}
                            />
                            <p className={basestyle.error}>{formErrors.zip}</p>
                            <label htmlFor="floatingInput">
                              Zip Code <span style={{ color: "red" }}>*</span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="form-floating mb-3">
                            {/* <select
                              className="form-select"
                              aria-label="Floating label select example"
                              onChange={(e) => handleState(e.target.value)}
                              name="state"
                              id="state"
                            >
                              <option selected="">Select</option>
                              {stateUS && stateUS !== undefined
                                ? stateUS.map((ctr, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={ctr}
                                        selected={ctr === userDetail?.state}
                                      >
                                        {ctr}
                                      </option>
                                    );
                                  })
                                : "No State"}
                            </select> */}
                            <input
                              type="text"
                              className="form-control"
                              value={userDetail.state}
                              name="state"
                              onChange={changeHandler}
                            />
                            <label
                              className="dropdownLabel"
                              htmlFor="floatingSelect"
                            >
                              State
                            </label>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              name="city"
                              value={userDetail.city}
                              onChange={changeHandler}
                            />
                            <label
                              className="dropdownLabel"
                              htmlFor="floatingSelect"
                            >
                              City
                            </label>
                          </div>
                        </div>
                      </div>

                      {/* <button
                        className="btn btn-secondary my-3"
                        onClick={calculateShipping}
                      >
                        Calculate Shipping
                      </button> */}
                      <p style={{ color: "red" }}>{shippingError}</p>
                      <div className="row dAddress">
                        <div className="d-flex align-items-center mb-3">
                          <input
                            className="me-3"
                            type="checkbox"
                            onClick={(e) => onAddCheck(e)}
                          />
                          <span>Ship To A Different Address?</span>
                        </div>
                      </div>
                      <div
                        className={`row newAddress ${checkAdd ? "" : "d-none"}`}
                      >
                        <div className="row">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                name="shipAddressLine1"
                                placeholder="Enter Address Line 1"
                                onChange={changeHandler}
                                value={userDetail.shipAddressLine1}
                              />
                              <label htmlFor="floatingInput">
                                Address Line 1
                              </label>
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                name="shipAddressLine2"
                                placeholder="Enter Address Line 2"
                                onChange={changeHandler}
                                value={userDetail.shipAddressLine2}
                              />
                              <label htmlFor="floatingInput">
                                Address Line 2
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <select
                                className="form-select"
                                aria-label="Floating label select example"
                                onChange={(e) => handleCountry(e.target.value)}
                                name="country"
                                id="country"
                              >
                                <option>Select</option>
                                {countries.map((ctr, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={ctr.name}
                                      selected
                                    >
                                      {ctr.name}
                                    </option>
                                  );
                                })}
                              </select>
                              <label
                                className="dropdownLabel"
                                htmlFor="floatingSelect"
                              >
                                Country
                              </label>
                            </div>
                          </div>

                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                name="shipZip"
                                placeholder="Zip Code"
                                value={userDetail.shipZip}
                                onChange={changeHandler}
                                onBlur={handleBlur}
                              />
                              <label htmlFor="floatingInput">Zip Code</label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                value={userDetail.shipState}
                                name="shipState"
                                onChange={changeHandler}
                              />
                              <label
                                className="dropdownLabel"
                                htmlFor="floatingSelect"
                              >
                                State
                              </label>
                            </div>
                          </div>

                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                value={userDetail.shipCity}
                                onChange={changeHandler}
                                name="shipCity"
                              />
                              <label
                                className="dropdownLabel"
                                htmlFor="floatingSelect"
                              >
                                City
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailsTitle">
                      <p>Order Notes (Optional)</p>
                      <div className="row">
                        <div className="form-floating">
                          <textarea
                            placeholder="Notes about your order, e.g. special notes for delivery."
                            defaultValue={""}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="sdBtns mt-3">
                      <NavLink to="/cart">
                        <span>
                          <i className="fa-solid fa-angle-left" />
                        </span>
                        <span>Return To Cart</span>
                      </NavLink>
                      {fedEX === 0 ? (
                        <>
                          <button
                            className="ppmBtn"
                            id="next"
                            style={{ cursor: " not-allowed" }}
                            title="Please select the shipping fee"
                          >
                            CONTINUE
                            <span className="ms-2">
                              <i className="fa-solid fa-angle-right" />
                            </span>
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="ppmBtn"
                            id="next"
                            onclick="nextPage()"
                            onClick={shippingAddHandler}
                          >
                            CONTINUE
                            <span className="ms-2">
                              <i className="fa-solid fa-angle-right" />
                            </span>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <div id="bDetails-" className="hideHeading">
                    <PaypalGetway
                      orderDetails={userDetail}
                      orderpayment={getTotal}
                      orderID={orderId}
                      cartProducts={cartProducts}
                    />
                  </div>
                )}
              </div>
            </div>
            {!showPaymentMethod && (
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                <div className="row bgContent cmt">
                  <h5 className="pdTitle">YOUR ORDER</h5>
                  <p className="mt-3 p-0">
                    Price Details ({cartDataproduct.length} Items)
                  </p>
                  <div className="pDetailscart p-0">
                    <div>
                      <p>Total MRP</p>
                      <p>$ {getTotal?.price.toFixed(2)}</p>
                    </div>
                    {/* <div>
                      <p>Discount on MRP</p>
                      <p>$ {getTotal?.discountAmount}</p>
                    </div> */}
                    <div>
                      <p>Coupon Discount</p>
                      <p>- $ {couponAmount ?? 0}</p>
                    </div>
                    <div>
                      <p>Total MRP after discount</p>
                      <p>
                        {" "}
                        ${" "}
                        {getTotal?.price - couponAmount < 0
                          ? 0
                          : (getTotal?.price - couponAmount).toFixed(2)}{" "}
                      </p>
                    </div>
                    <div>
                      <p>Coupon Type</p>
                      <p>{couponType ? couponType : "N/A"}</p>
                    </div>
                    {/* <div>
                      <p>Convenience Fee</p>
                      <p>$ 00.00</p>
                    </div> */}
                    {taxRate != 0 ? (
                      <div>
                        <p>Tax Fee</p>
                        <p>
                          ${" "}
                          {percentageTaxValue(
                            // taxShipRate ?? taxRate,
                            taxRate,
                            getTotal?.price,
                            100
                          ).toFixed(2)}
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}

                    {shippingFee?.length == 0 && !fedexPer ? (
                      <>
                        <p style={{ color: "#ed3237" }}>Fedex is disabled</p>
                      </>
                    ) : (
                      <>
                        <div>
                          <p>Shipping Fee</p>
                          <ul className="shipping_fee">
                            {shippingFee?.length == 0 ? (
                              <>
                                {" "}
                                <ClipLoader
                                  color="#52bfd9"
                                  size={50}
                                  loading={loading}
                                />
                              </>
                            ) : (
                              shippingFee?.map((ctr, index) => {
                              

                                if (
                                  ctr.serviceType == "GROUND_HOME_DELIVERY" &&
                                  couponType == "Free Shipping"
                                ) {
                                  
                                  return (
                                    <>
                                      <li key={index}>
                                        <input
                                          type="radio"
                                          value={ctr.charges}
                                          name="shippingFee"
                                          data-custom-value={ctr.serviceType}
                                          onChange={onOptionChange}
                                        />
                                        {ctr.serviceType}: $ {ctr.charges}
                                      </li>
                                    </>
                                  );
                                } else if (couponType != "Free Shipping") {
                                  // console.log(
                                  //   "1057 /////////////////////////////////////"
                                  // );
                                  return (
                                    <>
                                      <li key={index}>
                                        <input
                                          type="radio"
                                          value={ctr.charges}
                                          name="shippingFee"
                                          data-custom-value={ctr.serviceType}
                                          onChange={onOptionChange}
                                        />
                                        {ctr.serviceType}: $ {ctr.charges}
                                      </li>
                                    </>
                                  );
                                }
                              })
                            )}
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                  {fedEX === 0 ? null : (
                    <div className="tAmount p-0">
                      <p className="pt-3">Total Amount</p>
                      <p className="pt-3">
                        ${" "}
                        <>
                          {taxRate == 0
                            ? totalPriceFn(getTotal?.price, 0, fedEX)
                            : totalPriceFn(
                                getTotal?.price,
                                percentageTaxValue(
                                  // taxShipRate ?? taxRate,
                                  taxRate,
                                  getTotal?.price,
                                  100
                                ),
                                fedEX
                              )}
                        </>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade applyCoupon"
        id="orderConfirmationModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="d-flex justify-content-end me-3 mt-3">
              <div
                className="modalClose btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modalBody">
              <div className="container">
                <div className="row text-center justify-content-center">
                  <div className="img-fluid mb-5">
                    <img src="./images/successful.png" alt="image" />
                  </div>
                  <h3>Your Order is complete!</h3>
                  <p>
                    You will be receiving a confirmation email with order
                    details.
                  </p>
                  <button className="ppmBtn mb-3">Explore More Products</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cartProducts: state?.catagoryReducer?.Carts,
    cartProductData: state?.catagoryReducer?.CartData,
  };
};

export default connect(mapStateToProps)(Checkout);
