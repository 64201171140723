import {
    GET_ALL_BRAND_REQUEST, GET_BRAND_BY_ID, GET_ALL_SIDE_BRAND_REQUEST
} from "../actions/actionTypes";

const initialState = {
    brands: [],
    brand: [],
    sideBrands:[]
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_BRAND_REQUEST:
            return {
                ...state,
                brands: payload
            };
            case GET_ALL_SIDE_BRAND_REQUEST:
                return {
                    ...state,
                    sideBrands: payload
                };

        case GET_BRAND_BY_ID:
            return {
                ...state,
                brand: payload
            };
        default:
            return state;
    }
}