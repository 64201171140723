import {
  GET_ALL_PRODUCT_SUCCUSS, GET_PRODUCT_BY_ID
} from "./actionTypes";

export const getAllProducts = (products) => (dispatch) => {
  dispatch({
    type: GET_ALL_PRODUCT_SUCCUSS,
    payload: products,
  });
};

export const getProductById = (product) => (dispatch) => {
  dispatch({
    type: GET_PRODUCT_BY_ID,
    payload: product,
  });
};