import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import brandService from "../services/brand-service";
import { getAllBrands, getBrandById } from "../redux/actions/brandAction";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import image from "../../src/img/dummy.png";
import { API_URL } from "../constants/constants";
import isUrl from "is-url";
import ReactPaginate from "react-paginate";
import axios from "axios";

const Brands = (props) => {
  const [brands, setBrands] = useState([]);
  const [brandId, setBrandId] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let limit = 12;
  const [currentPage, setCurrent] = useState(1);

  // let lastIndex = currentPage * limit;
  // let firstIndex = lastIndex - limit;

  // let currentItems = brands.slice(firstIndex, lastIndex)

  useEffect(() => {
    brandService
      .getBrands(brands)
      .then((res) => {
        dispatch(getAllBrands(res.brands));
        setBrands(res.brands);
        const total = res?.brandsCount || 0;
        setpageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
      });
      
  }, []);

  const fetchBrandById = (id) => {
    brandService
      .getBrandsById(id)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getBrandById(res.brands));
          // setBrands(res)
          navigate(`/brandDetail/${id}`);
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchBrands = async (currentPage) => {
    const res = await axios.get(
      `${API_URL}/admin/brand/getBrands?limit=${limit}&page=${currentPage}`
    );
    const data = await res?.data?.brands;
    return data;
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;

    const nextBrands = await fetchBrands(currentPage);

    setBrands(nextBrands);
    // scroll to the top
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <nav className="breadcrumb">
        <div className="container">
          <div className="row">
            <ul>
              <li>
                <NavLink to="/">
                  Home
                  <span className="ms-3">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/brands">Brands</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <section>
        <div className="mainContent">
          <div className="container bgContent">
            {/* <div className="row">
              <div className="pageTitle">
                <h1>Brands</h1>
                <p>Brands</p>
              </div>
            </div> */}
            <div className="container bgContent">
              <div className="row">
                {/* <div className="pageTitle">
              <h1>Products</h1>
              <p>Products</p>
            </div> */}
              </div>
              <p
                style={{
                  textAlign: "center",
                  padding: "50px",
                  position: "relative",
                }}
              >
                <span className="cate-heading">Brands</span>
                <img
                  src="../images/textbanner.png"
                  alt=""
                  className="cate-image"
                ></img>
              </p>
            </div>
          </div>
          <div className="container">
            <div className="brandMain mt-5">
              {brands?.map((br) => {
                return (
                  <>
                    <div className="brandListing" key={br?._id}>
                      <div className="brandBg">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            fetchBrandById(br._id);
                          }}
                        >
                          <div className="brandImage">
                            {isUrl(br.image.url) ? (
                              <img
                                src={br.image.url}
                                width="100%"
                                alt={br.image.name}
                              ></img>
                            ) : (
                              <img
                                key={br?._id}
                                src={API_URL + "/" + br.image.url}
                                width="100%"
                                alt={br.image.name}
                              ></img>
                            )}
                          </div>
                        </a>
                      </div>
                      <div className="brandBottomDiv">
                        <p className="brandTitle">{br.name}</p>
                        <button
                          className="ppmBtn brandShop brandShop"
                          onClick={() => {
                            fetchBrandById(br._id);
                          }}
                        >
                          SHOP NOW
                        </button>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            {brands?.length == 0 ? (
              ""
            ) : (
              <>
                <div className="pagination_space">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    allBrands: state?.brandReducer?.brands,
    brandById: state?.brandReducer?.brand,
  };
}
export default connect(mapStateToProps)(Brands);
