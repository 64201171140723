import React from 'react'

const ShippingDetails = () => {
  return (
    <>
      <>
        <nav className="breadcrumb">
          <div className="container">
            <div className="row">
              <ul>
                <li>
                  <a href="index.html">
                    Home
                    <span className="ms-3">
                      <i className="fa-solid fa-angle-right" />
                    </span>
                  </a>
                </li>
                <li>
                  <a href="cart.html">
                    Cart
                    <span className="ms-3">
                      <i className="fa-solid fa-angle-right" />
                    </span>
                  </a>
                </li>
                <li>
                  <a href="#">Checkout</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="sdMainContent">
          <div className="container">
            <div className="row cart gx-5">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                <div className="row bgContent shippingDetails">
                  <div className="progressBar">
                    <div className="container p-0">
                      <div className="progress-container">
                        <div className="progress" id="progress" />
                        <div className="circle active">
                          <span />
                        </div>
                        <div className="circle">
                          <span />
                        </div>
                      </div>
                      <div className="progressTitle mb-5">
                        <h5 className="active m-0 hTitle1">Shipping Details</h5>
                        <h5 className="m-0 hTitle2">Billing Details</h5>
                      </div>
                    </div>
                  </div>
                  <div id="sDetails">
                    <div className="login mb-3">
                      <span className="me-2">Already Have An Account?</span>
                      <a>Click Here To Login</a>
                    </div>
                    <div className="detailsTitle">
                      <p>Personal Details</p>
                      <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput"
                              placeholder="First Name"
                            />
                            <label htmlFor="floatingInput">First Name</label>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Last Name"
                            />
                            <label htmlFor="floatingInput">Last Name</label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="form-floating mb-3">
                            <input
                              type="email"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Email Address"
                            />
                            <label htmlFor="floatingInput">Email Address</label>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="form-floating mb-3">
                            <input
                              type="number"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Phone Number"
                            />
                            <label htmlFor="floatingInput">Phone Number</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailsTitle">
                      <p>Address</p>
                      <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Enter Address Line 1"
                            />
                            <label htmlFor="floatingInput">Address Line 1</label>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Enter Address Line 2"
                            />
                            <label htmlFor="floatingInput">Address Line 2</label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="form-floating mb-3">
                            <select
                              className="form-select"
                              id="floatingSelect"
                              aria-label="Floating label select example"
                            >
                              <option selected="">Select</option>
                              <option value={1}>Option 1</option>
                              <option value={2}>Option 2</option>
                              <option value={3}>Option 3</option>
                            </select>
                            <label className="dropdownLabel" htmlFor="floatingSelect">
                              City
                            </label>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="form-floating mb-3">
                            <select
                              className="form-select"
                              id="floatingSelect"
                              aria-label="Floating label select example"
                            >
                              <option selected="">Select</option>
                              <option value={1}>Option 1</option>
                              <option value={2}>Option 2</option>
                              <option value={3}>Option 3</option>
                            </select>
                            <label className="dropdownLabel" htmlFor="floatingSelect">
                              State
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="form-floating mb-3">
                            <select
                              className="form-select"
                              id="floatingSelect"
                              aria-label="Floating label select example"
                            >
                              <option selected="">Select</option>
                              <option value={1}>Option 1</option>
                              <option value={2}>Option 2</option>
                              <option value={3}>Option 3</option>
                            </select>
                            <label className="dropdownLabel" htmlFor="floatingSelect">
                              Country
                            </label>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Zip Code"
                            />
                            <label htmlFor="floatingInput">Zip Code</label>
                          </div>
                        </div>
                      </div>
                      <div className="row dAddress">
                        <div className="d-flex align-items-center mb-3">
                          <input className="me-3" type="checkbox" />
                          <span>Ship To A Different Address?</span>
                        </div>
                      </div>
                      <div className="row d-none newAddress">
                        <div className="row">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="Enter Address Line 1"
                              />
                              <label htmlFor="floatingInput">Address Line 1</label>
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="Enter Address Line 2"
                              />
                              <label htmlFor="floatingInput">Address Line 2</label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <select
                                className="form-select"
                                id="floatingSelect"
                                aria-label="Floating label select example"
                              >
                                <option selected="">Select</option>
                                <option value={1}>Option 1</option>
                                <option value={2}>Option 2</option>
                                <option value={3}>Option 3</option>
                              </select>
                              <label
                                className="dropdownLabel"
                                htmlFor="floatingSelect"
                              >
                                City
                              </label>
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <select
                                className="form-select"
                                id="floatingSelect"
                                aria-label="Floating label select example"
                              >
                                <option selected="">Select</option>
                                <option value={1}>Option 1</option>
                                <option value={2}>Option 2</option>
                                <option value={3}>Option 3</option>
                              </select>
                              <label
                                className="dropdownLabel"
                                htmlFor="floatingSelect"
                              >
                                State
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <select
                                className="form-select"
                                id="floatingSelect"
                                aria-label="Floating label select example"
                              >
                                <option selected="">Select</option>
                                <option value={1}>Option 1</option>
                                <option value={2}>Option 2</option>
                                <option value={3}>Option 3</option>
                              </select>
                              <label
                                className="dropdownLabel"
                                htmlFor="floatingSelect"
                              >
                                Country
                              </label>
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="Zip Code"
                              />
                              <label htmlFor="floatingInput">Zip Code</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detailsTitle">
                      <p>Order Notes (Optional)</p>
                      <div className="row">
                        <div className="form-floating">
                          <textarea
                            placeholder="Notes about your order, e.g. special notes for delivery."
                            defaultValue={""}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="sdBtns mt-3">
                      <a href="cart.html">
                        <span>
                          <i className="fa-solid fa-angle-left" />
                        </span>
                        <span>Return To Cart</span>
                      </a>
                      <button className="ppmBtn" id="next" onclick="nextPage()">
                        CONTINUE
                        <span className="ms-2">
                          <i className="fa-solid fa-angle-right" />
                        </span>
                      </button>
                    </div>
                  </div>
                  <div id="bDetails" className="d-none">
                    <div className="d-flex align-items-start">
                      <div
                        className="nav flex-column nav-pills"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <button
                          className="nav-link active"
                          id="v-pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-home"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-home"
                          aria-selected="true"
                        >
                          <span>
                            <i className="fa-sharp fa-solid fa-credit-card me-3" />
                          </span>
                          <span>Credit/Debit Card</span>
                        </button>
                        <button
                          className="nav-link"
                          id="v-pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-profile"
                          aria-selected="false"
                          disabled=""
                        >
                          <span>
                            <i className="fa-solid fa-wallet me-3" />
                          </span>
                          <span>Paypal</span>
                        </button>
                      </div>
                      <div className="tab-content" id="v-pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="v-pills-home"
                          role="tabpanel"
                          aria-labelledby="v-pills-home-tab"
                          tabIndex={0}
                        >
                          <h5>CREDIT/DEBIT CARD</h5>
                          <p>
                            Please insure your card can be used for online
                            transactions.
                          </p>
                          <div className="row">
                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Name On Card"
                                />
                                <label htmlFor="floatingInput">Name On Card</label>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Card Number"
                                />
                                <label htmlFor="floatingInput">Card Number</label>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Expiry Date (MM/YY)"
                                />
                                <label htmlFor="floatingInput">
                                  Expiry Date (MM/YY)
                                </label>
                              </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="CVV"
                                />
                                <label htmlFor="floatingInput">CVV</label>
                              </div>
                            </div>
                          </div>
                          <div className="row justify-content-end">
                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <button
                                className="ppmBtn mb-3"
                                id="payNow"
                                data-bs-toggle="modal"
                                data-bs-target="#orderConfirmationModal"
                              >
                                PAY NOW
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="v-pills-profile"
                          role="tabpanel"
                          aria-labelledby="v-pills-profile-tab"
                          tabIndex={0}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                <div className="row bgContent cmt">
                  <h5 className="pdTitle">YOUR ORDER</h5>
                  <p className="mt-3 p-0">Price Details (2 Items)</p>
                  <div className="pDetails p-0">
                    <div>
                      <p>Total MRP</p>
                      <p>$ 32.00</p>
                    </div>
                    <div>
                      <p>Discount on MRP</p>
                      <p>$ 06.40</p>
                    </div>
                    <div>
                      <p>Coupon Discount</p>
                      <p>$ 06.40</p>
                    </div>
                    <div>
                      <p>Convenience Fee</p>
                      <p>$ 00.00</p>
                    </div>
                  </div>
                  <div className="tAmount p-0">
                    <p className="pt-3">Total Amount</p>
                    <p className="pt-3">$ 25.60</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

    </>
  )
}

export default ShippingDetails