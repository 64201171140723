import React, { useState, useEffect } from "react";
import authService from "../../services/auth.service";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { getUserById, updateUser } from "../../redux/actions/user";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import orderServices from "../../services/order-services";
import basestyle from "./Base.module.css";
import axios from "axios";
import { API_URL } from "../../constants/constants";
import { toast } from "react-toastify";
import Cities from "../../Cities.json";
const moment = require("moment");

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  addressLine1: "",
  addressLine2: "",
  country: "",
  state: "",
  city: "",
  zip: "",
  shipfirstName: "",
  shiplastName: "",
  shipemail: "",
  shipphone: "",
  shipAddressLine1: "",
  shipAddressLine2: "",
  shipCity: "",
  shipState: "",
  shipCountry: "",
  shipZip: "",
};

const MyAccount = (props) => {
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState(initialState);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const userDetail = props.userDetail || {};

  const [data, setData] = useState([]);
  const [orderDetail, setOrderDetail] = useState([]);
  const [isModal, setIsModal] = useState(false);

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [shipState, setShipState] = useState([]);
  const [shipCity, setShipCity] = useState([]);
  const dispatch = useDispatch();

  const [oldpassword, setOldPasswod] = useState();
  const [password, setPassword] = useState("");

  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const [confirmpassword, setConfirmPassword] = useState();

  const countries = [{ id: "1", name: "USA" }];

  const handleCountry = (countryName) => {
    // setUserDetails({ ...userDetail, country: countryName });
    setCountry({ ...formValue, country: countryName });
  };
  const stateUS = Object.keys(Cities).sort();
  const shipStateUS = Object.keys(Cities).sort();

  const handleState = (stateName) => {
    // setUserDetails({ ...userDetail, state: stateName });
    setState({ ...formValue, state: stateName });
    setFormValue({ ...formValue, state: stateName });
    let sortedCities = Cities[stateName].sort();
    setCity(sortedCities);
  };
  const handleShipState = async (stateName) => {
    setFormValue({ ...formValue, shipState: stateName });
    setShipState({ ...formValue, shipState: stateName });
    let sortedCities = Cities[stateName].sort();
    setShipCity(sortedCities);
  };

  const handleShipCity = (cityName) => {
    setFormValue({ ...formValue, shipCity: cityName });
  };

  const onInputChange = (e) => {
    let { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const orderModal = async (id) => {
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    const {
      data: { order },
    } = await axios.get(API_URL + `/user/dashboard/getOrderById/${id}`, {
      headers: { Authorization: token },
    });
    setOrderDetail(order);
  };
  const handleCity = (cityName) => {
    setFormValue({ ...formValue, city: cityName });
  };

  useEffect(() => {
    setCountry(countries);
    setFormValue({ ...userDetail });
  }, [userDetail]);

  var totalPrice = 0;
  const validateForm = (values) => {
    const error = {};
    const emailRegex = /^[^\s+@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const postalCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    const phoneRegex = /^[0-9\b]+$/;
    if (!values.firstName.trim()) {
      error.fname = "First Name is required";
    } else if (!values.firstName.match(/^[a-zA-Z ]*$/)) {
      error.fname = "Please enter valid first name";
    } else if (values.firstName.length > 16) {
      error.fname =
        "First name value cannot exceed more than 16 characters or remove extra space";
    }
    if (!values.lastName.trim()) {
      error.lname = "Last Name is required";
    } else if (!values.lastName.match(/^[a-zA-Z ]*$/)) {
      error.lname = "Please enter valid last name";
    } else if (values.lastName.length > 16) {
      error.lname =
        "Last name value cannot exceed more than 16 characters or remove extra space";
    }
    if (!values.email) {
      error.email = "Email is required";
    } else if (!emailRegex.test(values.email)) {
      error.email = "This is not a valid email format";
    }

    if (!values.phone) {
      error.phone = "Phone no. is required";
    } else if (!phoneRegex.test(values.phone) || values.phone.length !== 10) {
      error.phone = "Please enter a 10-digit phone no.";
    }

    /*Shipping */

    if (!values?.shipfirstName?.trim()) {
      // error.shipfname = "Ship First Name is required";
    } else if (!values.shipfirstName.match(/^[a-zA-Z ]*$/)) {
      error.shipfname = "Please enter valid Ship first name";
    } else if (values.shipfirstName.length > 16) {
      error.shipfname =
        "Ship First name value cannot exceed more than 16 characters or remove extra space";
    }
    if (!values?.shiplastName?.trim()) {
      // error.shiplname = "Ship Last Name is required";
    } else if (!values.shiplastName.match(/^[a-zA-Z ]*$/)) {
      error.shiplname = "Please enter valid ship last name";
    } else if (values.shiplastName.length > 16) {
      error.shiplname =
        "Ship last name value cannot exceed more than 16 characters or remove extra space";
    }
    if (!values?.shipemail) {
      // error.shipemail = "Ship Email is required";
    } else if (!emailRegex.test(values.shipemail)) {
      error.shipemail = "This is not a valid ship email format";
    }

    if (!values?.shipphone) {
      // error.shipphone = "Ship Phone no. is required";
    } else if (
      !phoneRegex.test(values.shipphone) ||
      values.shipphone.length !== 10
    ) {
      error.shipphone = "Please enter a 10-digit ship phone no.";
    }

    if (!values.addressLine1) {
      error.addressLine1 = "Address Line1 required";
    }
    if (!values.state || values.state == "Select") {
      error.state = "State is required";
    }
    if (!values.city || values.city == "Select") {
      error.city = "City is required";
    }
    // if (!values.country) {
    //     error.country = "Country required";
    // }
    // if (!values.state) {
    //     error.state = "State required";
    // }
    // if (!values.city) {
    //     error.city = "City is required";
    // }
    if (!values.zip) {
      error.zip = "Zip Code required";
    } else if (!postalCodeRegex.test(values.zip)) {
      error.zip = "Please enter a 5-digit zip code.";
    }

    if (!values.shipZip) {
      // error.shipzip = "Ship Zip Code required";
    } else if (!postalCodeRegex.test(values.shipZip)) {
      error.shipZip = "Please enter a 5-digit ship zip code.";
    }

    return error;
  };

  const {
    firstName,
    lastName,
    email,
    phone,
    addressLine1,
    addressLine2,
    zip,
    shipfirstName,
    shiplastName,
    shipemail,
    shipphone,
    shipAddressLine1,
    shipAddressLine2,
    state: usState,
    city: usCity,
    shipState: usShipState,
    shipCity: usShipCity,
    shipCountry,
    shipZip,
  } = formValue;

  const addressHandler = async (e) => {
    e.preventDefault();
    const validate = validateForm(formValue);
    setFormErrors(validate);
    if (!!Object.keys(validate).length) {
      setFormErrors(validate);
      return;
    }
    setIsSubmit(true);
    props.updateUser(formValue);
    authService
      .updateUser(formValue)
      .then((res) => {
        if (res.status == 200) {
          swal("Success!", res.message, "success");
          navigate("/");
        } else if (res.status == 403) {
          swal("Error!", res.message, "error");
        } else {
          swal("Success!", "User updated successfully", "success");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const passwordHandler = async (e) => {
    e.preventDefault();
    let email = props.userDetail.userId.email;
    if (Number(password.length) < 8) {
      // toast.warn("The new password and confirm password does not match");
      swal(
        "Error!",
        "The new password should be atleast 8 characters",
        "error"
      );
    } else if (password != confirmpassword) {
      swal(
        "Error!",
        "The new password and confirm password does not match",
        "error"
      );
    } else {
      // Handle validations
      const token = await JSON.parse(localStorage.getItem("userInfo"));
      let user = { email: email, password: oldpassword, newPassword: password };

      authService
        .changePassword(user)
        .then((res) => {
          if (res.status == 200) {
            swal("Success!", res.message, "success");
            navigate("/");
          } else if (res.status == 403) {
            swal("Error!", res.message.data.message, "error");
          } else {
            swal("Error!", res.message, "error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // const config = {
      //   headers: { Authorization: token }
      // };
      // axios
      //   .post(
      //     "https://ppmracingproducts-qa.chetu.com/api/user/dashboard/updatePassword",
      //     { email, password: oldpassword, newPassword: password },
      //     config
      //   )
      //   .then((response) => {
      //     console.log(response);
      //     // Handle response
      //   }).catch((err)=>{
      //     console.log(err);
      //   })
    }
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors]);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userInfo"));
    if (!token) {
      navigate("/");
      return;
    }

    let userId = localStorage.getItem("userId");
    orderServices
      .getUserDetailById(userId)
      .then((res) => {
        if (res?.status === 200) {
          const userInfo = res.userDetails;

          if (userInfo) {
            let sortedCities = Cities[userInfo[0]?.state]?.sort();
            let sortedShipCities = Cities[userInfo[0]?.shipState]?.sort();
            setCity(sortedCities);
            setShipCity(sortedShipCities);
          }
          dispatch(getUserById(res.userDetails[0]));

          // setUserDetails({

          //   lastName: userInfo[0]?.lastName,
          //   email: userInfo[0]?.email,
          //   phone: userInfo[0]?.phone,
          //   addressLine1: userInfo[0]?.addressLine1,
          //   addressLine2: userInfo[0]?.addressLine2,
          //   state: userInfo[0]?.state,
          //   zip: userInfo[0]?.zip,
          //   country: userInfo[0]?.country,
          // });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleGetOrder = async () => {
    // const token = await JSON.parse(localStorage.getItem('userInfo'));//
    const token = await JSON.parse(localStorage.getItem("userInfo"));
    const { data } = await axios.get(API_URL + "/user/dashboard/getOrder", {
      headers: { Authorization: token },
    });
    setData(data?.order);
  };

  const logout = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("userId");
    localStorage.removeItem("beWecirPlatot");
    localStorage.removeItem("userType");
    localStorage.removeItem("beWtnuomAgnippihs");
    localStorage.removeItem("camount");
    localStorage.removeItem("couponType");
    localStorage.removeItem("couponId");
    localStorage.removeItem("shipTaxVal");
    navigate("/");
    window.location.reload();
  };

  const handleClickShowPassword = (inputValue) => {
    if (inputValue == "oldpassword") {
      setShowPasswordOld(!showPasswordOld);
      if (showPasswordOld === false) {
        $("#fa-eye-slash").show();
        $("#fa-eye").hide();
      }
      if (showPasswordOld === true) {
        $("#fa-eye").show();
        $("#fa-eye-slash").hide();
      }
    } else if (inputValue == "password") {
      setShowPassword(!showPassword);
      if (showPassword === false) {
        $("#fa-eye-slash-new").show();
        $("#fa-eye-new").hide();
      }
      if (showPassword === true) {
        $("#fa-eye-new").show();
        $("#fa-eye-slash-new").hide();
      }
    } else {
      setShowPasswordConfirm(!showPasswordConfirm);
      if (showPasswordConfirm === false) {
        $("#fa-eye-slash-confirm").show();
        $("#fa-eye-confirm").hide();
      }
      if (showPasswordConfirm === true) {
        $("#fa-eye-confirm").show();
        $("#fa-eye-slash-confirm").hide();
      }
    }
    // setShowPassword(!showPassword );
  };

  const hideModal = () => {
    // $('#exampleModalLabel').hide();
    setTimeout(() => window.location.reload(), 1000);
    // window.alert("hi")
  };
  return (
    <>
      <section>
        <nav className="breadcrumb">
          <div className="container">
            <div className="row">
              <ul>
                <li>
                  <NavLink to="/">
                    Home
                    <span className="ms-3">
                      <i className="fa-solid fa-angle-right"></i>
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/my-account">My Account</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="mainContent">
          {/* <div className="container bgContent">
            <div className="row">
              <div className="pageTitle">
                <h1>My Account</h1>
                <p>My Account</p>
              </div>
            </div>
          </div> */}
          <div className="container bgContent">
            <div className="row">
              {/* <div className="pageTitle">
              <h1>Products</h1>
              <p>Products</p>
            </div> */}
            </div>
            <p
              style={{
                textAlign: "center",
                padding: "50px",
                position: "relative",
              }}
            >
              <span className="cate-heading">My Account</span>
              <img
                src="../images/textbanner.png"
                alt=""
                className="cate-image"
              ></img>
            </p>
          </div>
          <div className="container mt-2">
            <div className="row myAccount mb-5">
              <div className="myAccountTabs mt-4">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="dashboard"
                      data-bs-toggle="tab"
                      data-bs-target="#dashboard-pane"
                      type="button"
                      role="tab"
                      aria-controls="dashboard-pane"
                      aria-selected="true"
                    >
                      Dashboard
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="orders"
                      data-bs-toggle="tab"
                      data-bs-target="#orders-pane"
                      type="button"
                      role="tab"
                      aria-controls="orders-pane"
                      aria-selected="false"
                      onClick={handleGetOrder}
                    >
                      Orders
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="address"
                      data-bs-toggle="tab"
                      data-bs-target="#address-pane"
                      type="button"
                      role="tab"
                      aria-controls="address-pane"
                      aria-selected="false"
                    >
                      Address
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="account"
                      data-bs-toggle="tab"
                      data-bs-target="#account-pane"
                      type="button"
                      role="tab"
                      aria-controls="reviews-pane"
                      aria-selected="false"
                    >
                      Account Details
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="dashboard-pane"
                    role="tabpanel"
                    aria-labelledby="dashboard"
                    tabIndex="0"
                  >
                    <div className="row mt-5">
                      <div className="col-12">
                        <p>
                          From your account dashboard you can view your{" "}
                          <b>recent orders,</b> manage your{" "}
                          <b>shipping & billing addresses,</b> and{" "}
                          <b>edit your password and account details.</b>
                        </p>
                        <button className="ppmBtn mt-3" onClick={logout}>
                          LOG OUT
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="orders-pane"
                    role="tabpanel"
                    aria-labelledby="orders"
                    tabIndex={0}
                  >
                    <div className="row mt-5 table-responsive">
                      <table
                        className="table table-striped display nowrap dtr-inline"
                        style={{ width: "100%", position: "relative" }}
                        aria-describedby="example_info"
                      >
                        <thead>
                          <tr>
                            <th>Orders</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Total</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.length > 0 &&
                            data?.map((item, index) => (
                              <tr key={index}>
                                <td>#{++index}</td>
                                <td>
                                  {moment(item.createdAt).format("MMM Do YYYY")}
                                </td>
                                <td>{item?.status}</td>
                                <td>
                                  ${(item?.totalAmount).toFixed(2)} {"   "}(
                                  {item?.products.length} items)
                                </td>
                                <td>
                                  <button
                                    className="ppmBtn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#orderDetailsModal"
                                    onClick={() => orderModal(item._id)}
                                  >
                                    View
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* Addresses Form Opening*/}
                  <div
                    className="tab-pane fade"
                    id="address-pane"
                    role="tabpanel"
                    aria-labelledby="address"
                    tabIndex="0"
                  >
                    <div className="boxTitle mt-5">
                      <label>Billing Address</label>
                      <div className="boxSubTitle">
                        <p>Personal Details</p>
                        <div className="row">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                name="firstName"
                                id="firstName"
                                placeholder="First Name"
                                onChange={onInputChange}
                                value={firstName}
                              />
                              <label htmlFor="floatingInput">
                                First Name
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <p className={basestyle.error}>
                                {formErrors.fname}
                              </p>
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                name="lastName"
                                id="lastName"
                                placeholder="Last Name"
                                onChange={onInputChange}
                                value={lastName}
                              />
                              <label htmlFor="floatingInput">
                                Last Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <p className={basestyle.error}>
                                {formErrors.lname}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                placeholder="Email Address"
                                onChange={onInputChange}
                                value={userDetail?.userId?.email}
                              />
                              <label htmlFor="floatingInput">
                                Email Address
                              </label>
                              <p className={basestyle.error}>
                                {formErrors.email}
                              </p>
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type="number"
                                className="form-control"
                                id="phone"
                                name="phone"
                                placeholder="Phone Number"
                                onChange={onInputChange}
                                value={phone}
                              />
                              <label htmlFor="floatingInput">
                                Phone Number{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <p className={basestyle.error}>
                                {formErrors.phone}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="boxSubTitle">
                        <p>Address</p>
                        <div className="row">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="addressLine1"
                                name="addressLine1"
                                placeholder="Enter Address Line 1"
                                value={addressLine1}
                                onChange={onInputChange}
                              />
                              <label htmlFor="floatingInput">
                                Address Line 1{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <p className={basestyle.error}>
                                {formErrors.addressLine1}
                              </p>
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="addressLine2"
                                name="addressLine2"
                                placeholder="Enter Address Line 2"
                                value={addressLine2}
                                onChange={onInputChange}
                              />
                              <label htmlFor="floatingInput">
                                Address Line 2
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <select
                                className="form-select"
                                aria-label="Floating label select example"
                                value={usState}
                                onChange={(e) => handleState(e.target.value)}
                                name="state"
                                id="state"
                              >
                                <option>Select</option>
                                {stateUS && stateUS !== undefined
                                  ? stateUS.map((ctr, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={ctr}
                                          selected={ctr === usState}
                                        >
                                          {ctr}
                                        </option>
                                      );
                                    })
                                  : "No State"}
                              </select>
                              <label
                                className="dropdownLabel"
                                htmlFor="floatingSelect"
                              >
                                State
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <p className={basestyle.error}>
                                {formErrors.state}
                              </p>
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <select
                                className="form-select"
                                aria-label="Floating label select example"
                                onChange={(e) => handleCity(e.target.value)}
                                value={usCity}
                                name="city"
                                id="city"
                              >
                                <option>Select</option>
                                {city && city !== undefined
                                  ? city?.map((ctr, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={ctr}
                                          selected={ctr == usCity}
                                        >
                                          {ctr}
                                        </option>
                                      );
                                    })
                                  : "No City"}
                              </select>
                              <label
                                className="dropdownLabel"
                                htmlFor="floatingSelect"
                              >
                                City
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <p className={basestyle.error}>
                                {formErrors.city}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <select
                                className="form-select"
                                aria-label="Floating label select example"
                                onChange={(e) => handleCountry(e.target.value)}
                                name="country"
                                id="country"
                                value="USA"
                              >
                                <option>Select</option>
                                {countries.map((ctr, index) => {
                                  return (
                                    <option key={index} value={ctr.name}>
                                      {ctr.name}
                                    </option>
                                  );
                                })}
                              </select>
                              <label
                                className="dropdownLabel"
                                htmlFor="floatingSelect"
                              >
                                Country
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <p className={basestyle.error}>
                                {formErrors.country}
                              </p>
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="zip"
                                name="zip"
                                placeholder="Zip Code"
                                value={zip}
                                onChange={onInputChange}
                              />
                              <label htmlFor="floatingInput">
                                Zip Code <span style={{ color: "red" }}>*</span>
                              </label>
                              <p className={basestyle.error}>
                                {formErrors.zip}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="boxTitle mt-5">
                      <label>Shipping Address</label>
                      <div className="boxSubTitle">
                        <p>Personal Details</p>
                        <div className="row">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                name="shipfirstName"
                                id="shipfirstName"
                                placeholder="Shipper First Name"
                                value={shipfirstName}
                                onChange={onInputChange}
                              />
                              <label htmlFor="floatingInput">First Name</label>
                              <p className={basestyle.error}>
                                {formErrors.shipfname}
                              </p>
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                name="shiplastName"
                                id="shiplastName"
                                placeholder="Last Name"
                                value={shiplastName}
                                onChange={onInputChange}
                              />
                              <label htmlFor="floatingInput">Last Name</label>
                              <p className={basestyle.error}>
                                {formErrors.shiplname}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type="email"
                                className="form-control"
                                id="shipemail"
                                name="shipemail"
                                placeholder="Email Address"
                                value={shipemail}
                                onChange={onInputChange}
                              />
                              <label htmlFor="floatingInput">
                                Email Address
                              </label>
                              <p className={basestyle.error}>
                                {formErrors.shipemail}
                              </p>
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type="number"
                                className="form-control"
                                id="shipphone"
                                name="shipphone"
                                placeholder="Phone Number"
                                value={shipphone}
                                onChange={onInputChange}
                              />
                              <label htmlFor="floatingInput">
                                Phone Number
                              </label>
                              <p className={basestyle.error}>
                                {formErrors.shipphone}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="boxSubTitle">
                        <p>Address</p>
                        <div className="row">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="shipAddressLine1"
                                name="shipAddressLine1"
                                placeholder="Enter Address Line 1"
                                value={shipAddressLine1}
                                onChange={onInputChange}
                              />
                              <label htmlFor="floatingInput">
                                Address Line 1
                              </label>
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="shipAddressLine2"
                                name="shipAddressLine2"
                                placeholder="Enter Address Line 2"
                                value={shipAddressLine2}
                                onChange={onInputChange}
                              />
                              <label htmlFor="floatingInput">
                                Address Line 2
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <select
                                className="form-select"
                                aria-label="Floating label select example"
                                onChange={(e) =>
                                  handleShipState(e.target.value)
                                }
                                name="shipState"
                                id="shipState"
                              >
                                <option>Select</option>
                                {shipStateUS && shipStateUS !== undefined
                                  ? shipStateUS.map((ctr, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={ctr}
                                          selected={ctr === usShipState}
                                        >
                                          {ctr}
                                        </option>
                                      );
                                    })
                                  : "No State"}
                              </select>
                              <label
                                className="dropdownLabel"
                                htmlFor="floatingSelect"
                              >
                                State
                              </label>
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <select
                                className="form-select"
                                aria-label="Floating label select example"
                                onChange={(e) => handleShipCity(e.target.value)}
                                name="shipCity"
                                id="shipCity"
                              >
                                <option>Select</option>
                                {shipCity && shipCity !== undefined
                                  ? shipCity?.map((ctr, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={ctr}
                                          selected={ctr == usShipCity}
                                        >
                                          {ctr}
                                        </option>
                                      );
                                    })
                                  : "No City"}
                              </select>
                              <label
                                className="dropdownLabel"
                                htmlFor="floatingSelect"
                              >
                                City
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <select
                                className="form-select"
                                aria-label="Floating label select example"
                                onChange={(e) => handleCountry(e.target.value)}
                                name="shipCountry"
                                id="shipCountry"
                                value={"USA"}
                              >
                                <option>Select</option>
                                {countries.map((ctr, index) => {
                                  return (
                                    <option key={index} value={ctr.name}>
                                      {ctr.name}
                                    </option>
                                  );
                                })}
                              </select>
                              <label
                                className="dropdownLabel"
                                htmlFor="floatingSelect"
                              >
                                Country
                              </label>
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="shipZip"
                                placeholder="Zip Code"
                                name="shipZip"
                                value={shipZip}
                                onChange={onInputChange}
                              />
                              <label htmlFor="floatingInput">Zip Code</label>
                              <p className={basestyle.error}>
                                {formErrors.shipZip}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      <button className="ppmBtn mb-3" onClick={addressHandler}>
                        UPDATE USER
                      </button>
                    </div>
                  </div>
                  {/* Address Form Closing */}
                  <div
                    className="tab-pane fade"
                    id="account-pane"
                    role="tabpanel"
                    aria-labelledby="account"
                    tabIndex="0"
                  >
                    <div className="row mt-5">
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="First Name"
                            value={userDetail.firstName}
                            disabled
                          />
                          <label htmlFor="floatingInput">First Name</label>
                        </div>
                      </div>
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="Last Name"
                            value={userDetail.lastName}
                            disabled
                          />
                          <label htmlFor="floatingInput">Last Name</label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            className="form-control"
                            id="floatingInput"
                            placeholder="Email Address"
                            value={userDetail.userId?.email}
                            disabled
                          />
                          <label htmlFor="floatingInput">Email Address</label>
                        </div>
                      </div>
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="form-floating mb-3">
                          <input
                            type="number"
                            className="form-control"
                            id="floatingInput"
                            placeholder="Phone Number"
                            value={userDetail.phone}
                            disabled
                          />
                          <label htmlFor="floatingInput">Phone Number</label>
                        </div>
                      </div>
                    </div>
                    {userDetail.userId?.userType ? (
                      <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="form-floating mb-3">
                            <input
                              type="email"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Email Address"
                              value={userDetail.userId?.userType}
                              disabled
                            />
                            <label htmlFor="floatingInput">User Type</label>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="boxTitle mt-5">
                      <label>Change Password</label>
                      <div className="boxSubTitle">
                        <div className="row">
                          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type={showPasswordOld ? "text" : "password"}
                                className="form-control"
                                id="oldpassword"
                                placeholder="Current Password"
                                value={oldpassword?.trim()}
                                name="oldpassword"
                                onChange={(e) => setOldPasswod(e.target.value)}
                              />
                              <label htmlFor="floatingInput">
                                Current Password
                              </label>
                              <span
                                onClick={() =>
                                  handleClickShowPassword("oldpassword")
                                }
                              >
                                <span
                                  className="fa-solid fa-eye"
                                  id="fa-eye"
                                ></span>
                                <span
                                  className="fa-solid fa-eye-slash"
                                  id="fa-eye-slash"
                                  style={{ display: "none" }}
                                ></span>
                              </span>
                            </div>
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type={showPassword ? "text" : "password"}
                                className="form-control"
                                id="password"
                                placeholder="New Password"
                                name="password"
                                value={password?.trim()}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              <label htmlFor="floatingInput">
                                New Password
                              </label>
                              <span
                                onClick={() =>
                                  handleClickShowPassword("password")
                                }
                              >
                                <span
                                  className="fa-solid fa-eye"
                                  id="fa-eye-new"
                                ></span>
                                <span
                                  className="fa-solid fa-eye-slash"
                                  id="fa-eye-slash-new"
                                  style={{ display: "none" }}
                                ></span>
                              </span>
                            </div>
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type={showPasswordConfirm ? "text" : "password"}
                                className="form-control"
                                id="confirmpassword"
                                placeholder="Confirm New Password"
                                name="confirmpassword"
                                value={confirmpassword?.trim()}
                                onChange={(e) =>
                                  setConfirmPassword(e.target.value)
                                }
                              />
                              <label htmlFor="floatingInput">
                                Confirm New Password
                              </label>
                              <span
                                onClick={() =>
                                  handleClickShowPassword("confirmpassword")
                                }
                              >
                                <span
                                  className="fa-solid fa-eye"
                                  id="fa-eye-confirm"
                                ></span>
                                <span
                                  className="fa-solid fa-eye-slash"
                                  id="fa-eye-slash-confirm"
                                  style={{ display: "none" }}
                                ></span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      <button className="ppmBtn mb-3" onClick={passwordHandler}>
                        Reset Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Modal */}
      {true ? (
        <>
          {/* <h1>hi aha,</h1> */}
          <div
            className="modal fade applyCoupon"
            id="orderDetailsModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modalHeader">
                  <p className="modal-title fs-5" id="exampleModalLabel">
                    ORDER DETAILS
                  </p>
                  <div
                    className="modalClose btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modalBody">
                  <p>
                    Order <b>#{orderDetail?._id}</b> was placed on{" "}
                    <b>
                      {moment(orderDetail?.createdAt).format("MMMM Do YYYY")}
                    </b>{" "}
                    and is currently <b>{orderDetail?.status}.</b>
                  </p>
                  <div className="row">
                    <div className="col-12">
                      {orderDetail?.products?.map((product) => {
                        totalPrice +=
                          product?.productId?.price * product?.quantity;
                        return (
                          <div className="cartPListing">
                            <div className="cartPImage me-5 position-relative">
                              <Link
                                to={`/productDetail/${product?.productId?._id}`}
                                onClick={hideModal}
                              >
                                {product?.productId.image?.url ? (
                                  <>
                                    <img
                                      src={
                                        API_URL +
                                        "/" +
                                        product?.productId.image?.url
                                      }
                                      height={100}
                                      width={100}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src="./images/dummyProduct.jpg"
                                      alt="dummy-image"
                                      height={100}
                                      width={100}
                                    ></img>
                                  </>
                                )}
                              </Link>
                            </div>
                            <div className="productDetails">
                              <h6>{product?.productId?.name?.slice(0, 40)}</h6>
                              <div className="price">
                                <span className="me-3">
                                  $ {product?.productId?.price}
                                </span>
                                {/* <span className="me-3">$ 32.00</span> */}
                                {/* <span>30% Off</span> */}
                              </div>
                              <div className="qty justify-content-start">
                                <span>QTY :</span>
                                <span>{product?.quantity}</span>
                              </div>
                            </div>
                            <Link
                              to={`/productDetail/${product.productId._id}`}
                              onClick={hideModal}
                              className="provide-rating"
                            >
                              Provide Rating
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mt-3">
                      <p className="mt-3 p-0 ftb">
                        Price Details ({orderDetail?.products?.length} Items)
                      </p>
                      <div className="pDetails p-0">
                        <div>
                          <p>
                            <b>Total MRP</b>{" "}
                          </p>{" "}
                          <p>
                            <span>$</span>
                            {orderDetail?.totalMrp?.toFixed(2)}
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>Coupon discount</b>
                          </p>{" "}
                          <p>
                            <span>$</span>
                            {orderDetail?.couponDiscount}
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>Tax Amount</b>
                          </p>{" "}
                          <p>
                            <span>$</span>
                            {orderDetail?.salesTax?.toFixed(2)}
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>Convenience Fee</b>
                          </p>
                          <p>
                            <span>$</span>
                            {orderDetail?.convenienceFee?.toFixed(2)}
                          </p>
                        </div>
                      </div>
                      <div className="tAmount p-0 ftb">
                        <p className="pt-3">Total Amount</p>
                        <p className="pt-3">
                          <span>$</span>
                          {orderDetail?.totalAmount?.toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

function mapStateToProps(state) {
  return {
    isLoggedIn: state?.auth?.isLoggedIn,
    userDetail: state?.userReducer?.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateUser: (user) => dispatch(updateUser(user)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
