import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AddCart, AddWishlist } from "../redux/actions/catagoryAction";
import { getBrandById } from "../redux/actions/brandAction";
import cartService from "../services/cart-service";
import productService from "../services/product-catagorie-service";
import { toast } from "react-toastify";
import { getProductById } from "../redux/actions/productAction";
import brandService from "../services/brand-service";
import wishListService from "../services/wishlist-service";
import { API_URL } from "../constants/constants";
import isUrl from "is-url";
import ReactPaginate from "react-paginate";
import axios from "axios";

const BrandDetail = (props) => {
  const [pageCount, setpageCount] = useState(0);
  const [text, setText] = useState("");
  const brandDetail = props.brandById || [];
  const [brands, setBrands] = useState([]);
  const [numDays, setNumDays] = useState(0);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartDataStore = props.cartData || [];
  const wishListDataStore = props.wishlistData || [];

  let previousPage = localStorage.getItem("secondLastPageBrand");
  const fetchBrandById = (id) => {
    brandService
      .getBrandsById(id)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getBrandById(res.brands));
          // setBrands(res)
          navigate(`/brandDetail/${id}`);
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let limit = 12;
  const [currentPage, setCurrent] = useState(1);

  const AddCartHandler = async (product) => {
    props.AddCart(product);
    const addedProduct = cartDataStore.find(
      (cartRecord) => cartRecord.id === product?._id
    );
    const cartData = [
      {
        productId: addedProduct?.id,
        quantity: addedProduct?.quantity,
      },
    ];
    await cartService
      .addProductToCart(cartData)
      .then((res) => {
        toast.success(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //Add wishlist

  const WishlistHandler = async (product) => {
    props.AddWishlist(product);
    const addedWishlistProduct = wishListDataStore.find(
      (wishlistRecord) => wishlistRecord.id === product?._id
    );
    const wishlistData = [
      {
        productId: addedWishlistProduct?.id,
      },
    ];
    await wishListService
      .addProductToWishlist(wishlistData)
      .then((res) => {
        toast.success(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchProductById = (id) => {
    localStorage.setItem("isBrandVisited", true);
    productService
      .getProductsById(id)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getProductById(res.product));
          navigate(`/productDetail/${id}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const initialArr = JSON.parse(localStorage.getItem("paginationArrBrand"));

    if (initialArr && initialArr.length > 0) {
      let lastElement = initialArr[initialArr.length - 1];
      setTimeout(async () => {
        const nextBrands = await fetchBrandProductsOnPrevious(lastElement);
        const total = nextBrands?.productsCount;
        setpageCount(Math.ceil(total / limit));
        setBrands(nextBrands.products);
        localStorage.setItem("isBrandVisited", false);
      }, 1000);
    } else {
      productService
        .getProductsByBrandId(id)
        .then((res) => {
          if (res.status == 200) {
            const total = res?.productsCount;
            setpageCount(Math.ceil(total / limit));
            // dispatch(getBrandById(res.products));
            setBrands(res.products);
          } else {
            setText(res?.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    brandService
      .getBrandsById(id)
      .then((res) => {
        if (res === 200) {
          dispatch(getBrandById(res.brands));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const fetchBrandProducts = async (currentPage) => {
    const userId = localStorage.getItem("userId");
    const res = await axios.post(
      `${API_URL}/admin/product/getProductsByBrand/${id}?limit=${limit}&page=${currentPage}`,
      { userId: userId }
    );
    const data = await res?.data?.products;
    return data;
  };

  const fetchBrandProductsOnPrevious = async (currentPage) => {
    const res = await axios.post(
      `${API_URL}/admin/product/getProductsByBrand/${id}?limit=${limit}&page=${currentPage}`
    );
    const data = await res?.data;
    return data;
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;

    localStorage.setItem("isBrandVisited", false);
    let arr = [1];
    arr.push(data.selected + 1);
    const pageNumber = data.selected + 1;
    const existArr = JSON.parse(localStorage.getItem("paginationArrBrand"));
    if (existArr) {
      const findIndex = existArr.findIndex((v) => v == pageNumber);
      if (findIndex == -1) {
        existArr.push(data.selected + 1);
        localStorage.setItem("paginationArrBrand", JSON.stringify(existArr));
      } else {
        localStorage.setItem("paginationArrBrand", JSON.stringify(existArr));
      }
    } else {
      localStorage.setItem("paginationArrBrand", JSON.stringify(arr));
    }
    localStorage.setItem("secondLastPageBrand", data.selected + 1);

    const nextBrands = await fetchBrandProducts(currentPage);

    setBrands(nextBrands);
    // scroll to the top
    window.scrollTo(0, 0);
  };

  //useEffect page hold

  useEffect(() => {
    addEventListener("popstate", (e) => {
      (async () => {
        const initialArr = JSON.parse(
          localStorage.getItem("paginationArrBrand")
        );
        e.preventDefault();
        const isBrandVisited = localStorage.getItem("isBrandVisited");
        if (isBrandVisited === "false") {
          if (initialArr.length > 1) {
            navigate(`/brandDetail/${id}`);
            setTimeout(async () => {
              const arrLength = initialArr.length;
              const secondLastPage = initialArr[arrLength - 2];
              initialArr.pop();
              localStorage.setItem("secondLastPageBrand", secondLastPage);
              localStorage.setItem(
                "paginationArrBrand",
                JSON.stringify(initialArr)
              );
              const nextBrands = await fetchBrandProducts(secondLastPage);

              setBrands(nextBrands);
              window.scrollTo(0, 0);
            }, 500);
          } else {
            localStorage.removeItem("paginationArrBrand");
            localStorage.removeItem("secondLastPageBrand");
            localStorage.removeItem("isBrandVisited");
            navigate("/");
          }
        }
      })();
    });
  }, [id]);

  const getDays = () => {
    brandService
      .getNoofDays()
      .then((res) => {
        setNumDays(res?.newTagDays?.days);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    //Runs only on the first render
    fetchBrandById(id);

    getDays();
  }, []);
  const date = new Date();
  return (
    <section>
      <nav className="breadcrumb">
        <div className="container">
          <div className="row">
            <ul>
              <li>
                <NavLink to="/">
                  Home
                  <span className="ms-3">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/brands">
                  Brands
                  <span className="ms-3">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </NavLink>
              </li>
              {/* <li><a href="#">Brand Name</a></li> */}
            </ul>
          </div>
        </div>
      </nav>
      <div className="sdMainContent">
        <div className="container bgContent">
          <div className="row">
            {!!brandDetail &&
              brandDetail.map((br, index) => {
                return (
                  <>
                    <div className="brandDetails text-center" key={index}>
                      <div className="brandsImage">
                        {isUrl(br.image.url) ? (
                          <img
                            key={br.id}
                            src={br.image.url}
                            width="100%"
                            alt={br.image.name}
                          ></img>
                        ) : (
                          <img
                            key={br.id}
                            src={API_URL + "/" + br.image.url}
                            width="100%"
                            alt={br.image.name}
                          ></img>
                        )}
                      </div>
                      <h5>{br.name}</h5>
                      <p>{br.description}</p>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
        <div className="container-fluid p-0">
          <div className="brandsBanner brandbanner1">
            {/* <p>This Week Only!</p>
              <p>20% Off Interior Parts!</p>
              <p>Wide Range of Breaks Car & It’s Security</p>
              <button className="ppmBtn">SHOP NOW</button> */}
            {brandDetail[0]?.banner?.url ? (
              <>
                {" "}
                <img
                  // key={br.id}
                  src={API_URL + "/" + brandDetail[0]?.banner?.url}
                  width="100%"
                  height="300px"
                  // alt={br.image.name}
                ></img>
              </>
            ) : (
              <>
                <img src="../images/brandsBanner.png" alt="dummyimage"></img>
              </>
            )}
          </div>
        </div>
        <div className="container">
          <div className="row"></div>
          <div className="bListingMain mt-5 p-3">
            {!!brands?.length &&
              brands?.map((product, index) => {
                let oldDate = new Date(product?.createdAt);

                let Difference_In_Time = date?.getTime() - oldDate?.getTime();

                // To calculate the no. of days between two dates
                let Difference_In_Days =
                  Difference_In_Time / (1000 * 3600 * 24);
                return (
                  <>
                    <div className="productListing" key={index}>
                      <a href={`/productDetail/${product?._id}`}>
                        <div className="plImage">
                          {product?.image[0]?.url ? (
                            // product.image.map((img) => (
                            //   <>
                            //     {isUrl(img.url) ? (
                            //       <img
                            //         key={img.id}
                            //         src={img?.url}
                            //         width="100%"
                            //         alt="Product image coming soon"
                            //       ></img>
                            //     ) : (
                            //       <img
                            //         key={img.id}
                            //         src={API_URL + "/" + img?.url}
                            //         width="100%"
                            //         alt="Product image coming soon"
                            //       ></img>
                            //     )}
                            //   </>
                            // ))
                            <>
                              <img
                                src={
                                  isUrl(product?.image[0]?.url)
                                    ? product?.image[0]?.url
                                    : product?.image[0]?.url == null ||
                                      product?.image[0]?.url == "N/A"
                                    ? "./images/dummyProduct.jpg"
                                    : API_URL + "/" + product?.image[0]?.url
                                }
                                width="100%"
                                alt="Product image coming soon"
                              ></img>
                            </>
                          ) : (
                            <img
                              src="../images/dummyProduct.jpg"
                              alt="Product image coming soon"
                            ></img>
                          )}
                          {Difference_In_Days < numDays && <span>New</span>}
                          {/* <span>New</span> */}
                        </div>
                      </a>

                      <div className="plInfo">
                        <div className="reviews">
                          {(() => {
                            const arr = [];
                            let rating = Math?.ceil(product?.rating);
                            let remaining = 5 - rating;
                            for (let j = 0; j < rating; j++) {
                              arr.push(<i className="fa-solid fa-star"></i>);
                            }
                            for (let j = 0; j < remaining; j++) {
                              arr.push(<i className="fa-regular fa-star"></i>);
                            }
                            return arr;
                          })()}
                        </div>
                        <p>{product.name}</p>
                        <div className="dealsPrice mb-3">
                          <span>
                            {/* ${product?.oldPrice ? product.oldPrice : product.price} */}
                            {product?.oldPrice ? "$" + product?.oldPrice : null}
                          </span>
                          <span>${product?.price}</span>
                        </div>
                      </div>
                      <div className="plBtns">
                        <button
                          className="atcBtn"
                          onClick={() => AddCartHandler(product)}
                        >
                          Add to Cart
                        </button>
                        <button
                          className="productBtn"
                          onClick={() => WishlistHandler(product)}
                          title="Add to wishlist"
                        >
                          <i className="fa-solid fa-heart"></i>
                        </button>
                        <button
                          className="productBtn"
                          onClick={() => fetchProductById(product._id)}
                          title="Product detail"
                        >
                          <i className="fa-solid fa-eye"></i>
                        </button>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
          <div class="h2">
            {brands?.length == 0 ? (
              <>
                <p>{text}</p>
              </>
            ) : (
              <></>
            )}
          </div>
          <>
            <div className="pagination_space">
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={pageCount}
                forcePage={previousPage ? previousPage - 1 : currentPage - 1}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </>
        </div>
      </div>
    </section>
  );
};
function mapStateToProps(state) {
  return {
    brandById: state?.brandReducer?.brand,
    cartData: state?.catagoryReducer?.Carts,
    wishlistData: state?.catagoryReducer?.Wishlists,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    AddCart: (product) => dispatch(AddCart(product)),
    AddWishlist: (product) => dispatch(AddWishlist(product)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(BrandDetail);
