import React from "react";
import { NavLink } from "react-router-dom";

const About = () => {
  return (
    <>
      <nav className="breadcrumb">
        <div className="container">
          <div className="row">
            <ul>
              <li>
                <NavLink to="/">
                  Home
                  <span className="ms-3">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/about-us">About Us</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="mainContent">
        <div className="container bgContent">
          <div className="row align-items-center flexColR">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="pageTitle">
                <h1>About Us</h1>
                <p>About Us</p>
              </div>
              <p className="mt-5 txtJustify">
                We at PPM would like to introduce ourselves to you, the
                customer. PPM is a family-owned business with new state of the
                art machining equipment. We also have several years of
                experience in a broad range or racing divisions. We have raced
                everything from four cylinder to both dirt and asphalt late
                models. PPM is dedicated to success and innovation. We provide
                to you, our customer, open communications, quality services,
                competitive prices and reliable delivery. Along with a
                continuing development of racing parts. We are a company driven
                to constantly improve customer service in every way. Only by
                communicating clearly and fully understanding the needs of our
                customer and racers can we accomplish this goal. At PPM our goal
                is to produce high quality parts at a reasonable price. But, we
                recognize that quality is always first, and it always will be on
                our list. Here at PPM we have many years of racing experience,
                but that alone is not enough to build the right parts. That is
                why we listen to, and pay attention to, the racer, car builders,
                and suppliers alike to produce the best parts for their needs.
                Also, at any time you have a comment or question feel free to
                contact us, we are eager to hear from you. Over the years racing
                has become more competitive, therefore pushing drivers to drive
                harder. This, combined with today’s suspension technology,
                pushes cars and parts to the limit. That is why we are
                continually researching and developing new and better parts to
                better meet the needs of competitive racing. This on track
                experience is where most design changes take place. We will
                continue to keep our involvement with car builders, racers, and
                suppliers to insure that we deliver the best quality parts, that
                meet their needs. Finally, we would like to thank, first of all,
                our Lord and Saviour Jesus Christ, for dying for our sins, and
                his blessings he has bestowed on us. We would also like to thank
                our family and friends, for all their support through the years.
                We would also, like to thank our customers for their business.
                We look forward, and are eager to work with you in the future.</p>
                <p>Sincerely</p>
                <h3>R. Hamby</h3>
                 <h4>Owner</h4>
              
              {/* <p className="mt-5 txtJustify">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. <br />
                <br />
                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita
                kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
                Lorem ipsum dolor sit amet.
              </p>
              <div className="aboutInfo">
                <div className="info">
                  {" "}
                  <p>12</p> <p>Years Experience</p>{" "}
                </div>
                <div className="info">
                  {" "}
                  <p>20K</p> <p>Happy Customers</p>{" "}
                </div>
                <div className="info">
                  {" "}
                  <p>100%</p> <p>Clients Satisfaction</p>{" "}
                </div>
              </div> */}
            </div>
            {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <img className="aboutImg img-fluid" src="images/about.png" />
            </div> */}
          </div>
          {/*  <div className="row align-items-center mt-5">
           <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <img className="img-fluid aboutImg2" src="images/vision.png" />
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div className="pageTitle">
                <h1>Our Vision</h1>
                <p>Our Vision</p>
              </div>
              <p className="mt-5 txtJustify">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. <br />
                <br />
                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita
                kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
                Lorem ipsum dolor sit amet.
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default About;
