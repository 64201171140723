import { logDOM } from "@testing-library/react";
import {
  SEARCH_PRODUCT_BY_CATEGORY,
  GET_ALL_CATEGORIES,
  // GET_PRODUCT_BY_CATEGORY_ID,
  GET_NUMBER_CART,
  ADD_CART,
  ADD_WISHLIST,
  DELETE_CART,
  DELETE_WISHLIST,
  WISHLIST_DATA_REQUEST,
  WISHLIST_DATA_RESPONSE,
  INCREASE_QUANTITY,
  DECREASE_QUANTITY,
  CART_DATA_REQUEST,
  CART_DATA_RESPONSE,
  GET_SUBCATEGORY_BY_CATEGORY_ID,
  EMPTY_CART,
} from "../actions/actionTypes";

const initialState = {
  products: [],
  categories: [],
  Carts: [],
  numberCart: 0,
  CartData: [],
  WishlistData: [],
  Wishlists: [],
  numberWishlist: 0,
  subCategories: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_PRODUCT_BY_CATEGORY:
      return {
        ...state,
        products: payload,
      };

    case GET_ALL_CATEGORIES:
      return {
        ...state,
        categories: payload,
      };

    case GET_SUBCATEGORY_BY_CATEGORY_ID:
      return {
        ...state,
        subCategories: payload,
      };

    case GET_NUMBER_CART:
      return {
        ...state,
      };

    case ADD_CART:
      if (state.numberCart === 0) {
        let cart = {
          id: action.payload._id,
          quantity: 1,
          name: action.payload.name,
          price: action.payload.price,
          discountAmount: action.payload.discountAmount,
          discountedPrice: action.payload.discountedPrice,
          discountPercent: action.payload.discountPercent,
          description: action.payload.description,
          image: action.payload.image,
          brand: action.payload.brand,
          yellowDealerPrice: action.payload.yellowDealerPrice,
          blueDealerPrice: action.payload?.blueDealerPrice,
          height: action.payload?.height || 1,
          width: action.payload?.width || 1,
          length: action.payload?.length || 1,
          weight: action.payload?.weight || 0.1,
          quantityOnHand: action?.payload?.quantityOnHand,
        };

        state.Carts.push(cart);
      } else {
        let check = false;
        state.Carts.map((item, key) => {
          if (item.id === action.payload._id) {
            state.Carts[key].quantity = action?.payload?.quantity
              ? action?.payload?.quantity
              : state.Carts[key].quantity + 1;
            check = true;
          }
        });
        if (!check) {
          let _cart = {
            id: action.payload._id,
            quantity: 1,
            name: action.payload.name,
            price: action.payload.price,
            discountAmount: action.payload.discountAmount,
            discountedPrice: action.payload.discountedPrice,
            discountPercent: action.payload.discountPercent,
            description: action.payload.description,
            image: action.payload.image,
            brand: action.payload.brand,
            yellowDealerPrice: action.payload.yellowDealerPrice,
            blueDealerPrice: action.payload?.blueDealerPrice,
            height: action.payload?.height || 1,
            width: action.payload?.width || 1,
            length: action.payload?.length || 1,
            weight: action.payload?.weight || 0.1,
            quantityOnHand: action?.payload?.quantityOnHand,
          };

          state.Carts.push(_cart);
        }
      }
      return {
        ...state,
        numberCart: state.numberCart + 1,
      };
    case INCREASE_QUANTITY:
      // state.Carts[action.payload].quantity++;
      const qty = state.Carts[action.payload]?.quantity || 0;
      if (qty > 0) {
        state.numberCart--;
        state.Carts[action.payload].quantity++;
      }
      return {
        ...state,
        numberCart: state.numberCart + 1,
      };

    case DECREASE_QUANTITY:
      const quantity = state.Carts[action.payload]?.quantity || 0;
      if (quantity > 0) {
        state.numberCart--;
        state.Carts[action.payload].quantity--;
      }

      return {
        ...state,
      };
    case DELETE_CART:
      const selectedProduct =
        state.Carts?.find(({ id }) => id === action.payload?._id) || {};
      const selectedProductQuantity = selectedProduct?.quantity || 0;
      const numberCart = state.numberCart - selectedProductQuantity;
      return {
        ...state,
        numberCart: state.numberCart,
        Carts: state.Carts.filter((item) => {
          return item.id !== action.payload?._id;
        }),
      };
    case EMPTY_CART:
      return {
        ...state,
        CartData: {
          isLoading: false,
          data: 0 || [],
        },
      };

    case CART_DATA_REQUEST:
      return {
        ...state,
        CartData: {
          isLoading: true,
          data: state.CartData?.data || [],
        },
      };
    case CART_DATA_RESPONSE:
      return {
        ...state,
        Carts: payload,
        CartData: {
          isLoading: false,
          data: payload,
        },
      };
    case ADD_WISHLIST:
      let wishlist = {
        id: action.payload._id,
        name: action.payload.name,
        price: action.payload.price,
        image: action.payload.image,
        brand: action.payload.brand,
      };

      state.Wishlists.push(wishlist);
      return {
        ...state,
        numberWishlist: state.numberWishlist + 1,
      };
    case WISHLIST_DATA_REQUEST:
      return {
        ...state,
        WishlistData: {
          isLoading: true,
          data: state.WishlistData?.data || [],
        },
      };
    case WISHLIST_DATA_RESPONSE:
      return {
        ...state,
        Wishlists: payload ?? [],
        WishlistData: {
          isLoading: false,
          data: payload ?? [],
        },
      };
    case DELETE_WISHLIST:
      const selectedWishlist =
        state.Wishlists?.find(({ id }) => id === action.payload?._id) || {};
      // const selectedProductQuantity = selectedProduct?.quantity || 0;
      const numberWishlist = state.numberWishlist - 1;
      return {
        ...state,
        numberWishlist: numberWishlist,
        Wishlists: state.Wishlists.filter((item) => {
          return item.id !== action.payload?._id;
        }),
      };
    default:
      return state;
  }
}
