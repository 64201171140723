import axios from "axios";
// import { useHistory } from "react-router-dom";
import { API_URL } from '../constants/constants'


// const API_URL = "https://ppmracingproducts-qa.chetu.com/api/user";


// const getCurrentUser = () => {
//   return JSON.parse(localStorage.getItem("user"));
// };
// const token = await JSON.parse(localStorage.getItem('userInfo'));
// // const config = {
//   headers: { Authorization: token }
// };


const authService = {
  login: async ({ email, password }) => {
    const response = await axios
      .post(API_URL + "/user/login/verifyLogin", {
        email,
        password,
      }) //promise
      .then((response) => {
        if (response.data.userData) {
          localStorage.setItem("userId", response.data.userData.userId);
          localStorage.setItem("userInfo", JSON.stringify(response.data.userData.token));
        }
        return response.data;
      }).catch((err) => {
        console.log(err);
      })
    return response;
  },

  signup: async (user) => {  //user object
    const formData = new FormData();
    formData.append('firstName', user?.firstName);
    formData.append('lastName', user?.lastName);
    formData.append('email', user?.email);
    formData.append('password', user?.password);
    formData.append('phone', user?.phone);
    formData.append('addressLine1', user?.addressLine1);
    formData.append('addressLine2', user?.addressLine2);
    formData.append('country', user?.country);
    formData.append('state', user?.state);
    formData.append('city', user?.city);
    formData.append('zip', user?.zip);
    formData.append('file', user?.file);
    formData.append('isMannualSignup', user?.isMannualSignup);

    const response = await axios
      .post(API_URL + "/user/signup/signUp", formData)
      .then((response) => {
        return response.data;
      }).catch((err) => {
        console.log(err);
      })
    return response;
  },

  logout: () => {
    localStorage.removeItem("userInfo");
    window.location.reload()
  },

  verifyMail: async (email) => {
    const response = await axios
      .post(API_URL + `/user/login/verifyEmail`, {
        email,
      })
      .then((response) => {
        return response.data;
      }).catch((err) => {
        console.log(err);
      })
    return response;
  },

  resetPassword: async ({ newPassword }, token) => {
    const response = await axios
      .post(API_URL + `/user/login/forgotPassword/${token}`, {
        newPassword,
        token
      })
      .then((response) => {
        return response.data;
      }).catch((err) => {
        console.log(err);
      })
    return response;
  },

  changePassword: async ({ email, password,newPassword }) => {
    const token = await JSON.parse(localStorage.getItem('userInfo'));
    const response = await axios
      .post(API_URL + `/user/dashboard/updatePassword`, {
        email,
        password,
        newPassword
      },{
        headers: { Authorization: token }
    }
      )
      .then((response) => {
        return response.data;
      }).catch((err) => {
        console.log(err);
      })
    return response;
  },

  updateUser: async (formValue) => {
     const token = await JSON.parse(localStorage.getItem('userInfo'));
    const response = await axios
      .post(API_URL + `/user/dashboard/updateUserDetails`, formValue,{
        headers: { Authorization: token }
    }
      )
      .then((response) => {
        return response.data;
      }).catch((err) => {
        console.log(err);
      })
    return response;
  },

};

export default authService;