import React, { useEffect } from "react";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Catagory from "./pages/Products";
import Cart from "./pages/Cart";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Login from "./pages/Auth/Login/Login";
import Register from "./pages/Auth/Registration/Register";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Products from "./pages/Products";
import Contact from "./pages/Contact";
import PageNotFound from "./components/PageNotFound";
import Allproducts from "./pages/Allproducts";
import ResetPassword from "./pages/Auth/ResetPassword/ResetPassword";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
import About from "./pages/About";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ShippingReturnPolicy from "./pages/ShippingReturnPolicy";
import RequestCatalog from "./pages/RequestCatalog";
import DistributorTools from "./pages/DistributorTools";
import TechResources from "./pages/TechResources/TechResources";
import Whitepages from "./pages/TechResources/Whitepages";
import ChassisMaps from "./pages/TechResources/ChassisMaps";
import ClickableSchmatics from "./pages/TechResources/ClickableSchmatics";
import Brands from "./pages/Brands";
import ProductListing from "./pages/ProductListing";
import ProductDetail from "./pages/ProductDetail";
import VerifyEmail from "./pages/VerifyEmail";
import CategoryCatalog from "./pages/CategoryCatalog";
import BrandDetail from "./pages/BrandDetail";
import Videos from "./pages/TechResources/Videos";
import Checkout from "./pages/Checkout";
import Wishlist from "./pages/Wishlist";
import Faq from "./pages/Faq";
import MyAccount from "./pages/Auth/MyAccount";
import Filter from "./components/filter/Filter";
import BrandFilter from "./components/filter/BrandFilter";
import CategoryFilter from "./components/filter/BrandFilter";
import Promos from "./pages/Promos";
import DropdownFilter from "./components/filter/DropdownFilter";
import ShippingDetails from "./pages/ShippingDetails";
import PaypalGetway from "./components/PaypalGetway";
import CategoryDetail from "./pages/CategoryDetail";
import ContactForm from "./pages/ContactForm";
import SubCategoryDetails from "./pages/SubCategoryDetails";

const App = () => {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/catagories" element={<Catagory />} />
          <Route path="/catagory-catalog" element={<CategoryCatalog />} />
          <Route path="/contactus" element={<Contact />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/brands" element={<Brands />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route
            path="/shippingReturnPolicy"
            element={<ShippingReturnPolicy />}
          />
          <Route path="/requestcatalog" element={<RequestCatalog />} />
          <Route path="/productCatagory" element={<Products />} />
          <Route path="/productListing" element={<ProductListing />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/productDetail/:id" element={<ProductDetail />} />
          <Route path="/brandDetail/:id" element={<BrandDetail />} />
          <Route path="/categoryDetail/:id" element={<CategoryDetail />} />
          <Route
            path="/subCategoryDetail/:id"
            element={<SubCategoryDetails />}
          />
          <Route path="/products" element={<Allproducts />} />
          <Route path="/distributorTools" element={<DistributorTools />} />
          <Route path="/techResources" element={<TechResources />} />
          <Route path="/chassisMap" element={<ChassisMaps />} />
          <Route path="/whitepages" element={<Whitepages />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/clickableSchmatics" element={<ClickableSchmatics />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/resetPassword/:token" element={<ResetPassword />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/my-account" element={<MyAccount />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/filter" element={<Filter />} />
          <Route path="/brandFilter" element={<BrandFilter />} />
          <Route path="/categoryFilter" element={<CategoryFilter />} />
          <Route path="/TermsOfUsage" element={<Faq />} />
          <Route path="/promos" element={<Promos />} />
          <Route path="/dropDownFilter" element={<DropdownFilter />} />
          <Route path="/shippingDetails" element={<ShippingDetails />} />
          <Route path="/verifyEmail/:token" element={<VerifyEmail />} />
          <Route path="/paypalGetway" element={<PaypalGetway />}></Route>
          <Route path="/conform" element={<ContactForm />}></Route>
        </Routes>
        <Footer />
      </Router>
      <ToastContainer />
    </div>
  );
};

export default App;
