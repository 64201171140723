import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../constants/constants";

const Videos = () => {
  const [data, setData] = useState([]);
  const getData = async () => {
    const { data } = await axios.get(
      API_URL + "/admin/uploadfile/getHowToAndVideos"
    );
    //  let videoData=data?.PDF
    //   const filteredData= videoData.filter((elem)=>{
    //     return elem.type == "video"
    //   })

    let videoData = data?.videos;
    const filteredData = videoData.filter((elem) => {
      return elem.url.includes("youtube");
    });

    setData(filteredData);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <section>
      <nav className="breadcrumb">
        <div className="container">
          <div className="row">
            <ul>
              <li>
                <Link to="/">
                  Home
                  <span className="ms-3">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </Link>
              </li>
              <li>
                <Link to={{}}>
                  Tech Resources
                  <span className="ms-3">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/videos">How To's & Videos</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="mainContent">
        {/* <div className="container bgContent">
          <div className="row">
            <div className="pageTitle">
              <h1>How To's & Videos</h1>
              <p>How To's & Videos</p>
            </div>
          </div>
        </div> */}
        <div className="container bgContent">
          <div className="row">
            {/* <div className="pageTitle">
              <h1>Products</h1>
              <p>Products</p>
            </div> */}
          </div>
          <p
            style={{
              textAlign: "center",
              padding: "50px",
              position: "relative",
            }}
          >
            <span className="cate-heading">How To's & Videos</span>
            <img
              src="../images/textbanner.png"
              alt=""
              className="cate-image"
            ></img>
          </p>
        </div>
        <div className="container">
          <div className="row justify-content-end">
            {/* <div className="filterVideo form-floating mb-3">
              <select
                className="form-select"
                id="floatingSelect"
                aria-label="Floating label select example"
              >
                <option selected="">Select</option>
                <option value="1">Fuel Filter Log Assembly</option>
                <option value="2">Randy does 2600 Birdcages</option>
                <option value="3">Upper Control Arms</option>
                <option value="4">Fuel Filter Log Assembly</option>
                <option value="5">Randy does 2600 Birdcages</option>
                <option value="6">Upper Control Arms</option>
                <option value="7">Fuel Filter Log Assembly</option>
                <option value="8">Randy does 2600 Birdcages</option>
                <option value="9">Upper Control Arms</option>
                <option value="10">Fuel Filter Log Assembly</option>
                <option value="11">Randy does 2600 Birdcages</option>
                <option value="12">Upper Control Arms</option>
              </select>
              <label className="dropdownLabel" for="floatingSelect">
                How To's & Videos
              </label>
            </div> */}
          </div>
          <div className="row pdfMain">
            {data?.map((item, index) => (
              <>
                {!item.url.includes("youtube") ? (
                  <div className="videoListing mt-3" key={index}>
                    <iframe
                      src={item.url}
                      title={item?.name}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                      alt={item?.name}
                    ></iframe>
                    <p>{item?.name}</p>
                  </div>
                ) : (
                  <div className="videoListing mt-3" key={index}>
                    {/* <iframe
                src={item.url}
                title={item?.name}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                alt="videoIcon"
                name="iframe_video1"
              ></iframe> */}
                    <a href={item?.url} target="iframe_video1">
                      <img src="images/dealsOfferImage1.png" />
                    </a>
                    <p>{item?.name}</p>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Videos;
