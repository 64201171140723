import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../constants/constants";

const ClickableSchmatics = () => {
  const [data, setData] = useState([]);

  const getData = async () => {
    const { data } = await axios.get(
      API_URL + "/admin/uploadfile/getClickableSchematics"
    );
    setData(data?.PDF);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <section>
      <nav className="breadcrumb">
        <div className="container">
          <div className="row">
            <ul>
              <li>
                <Link to="/">
                  Home
                  <span className="ms-3">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </Link>
              </li>
              <li>
                <Link to={{}}>
                  Tech Resources
                  <span className="ms-3">
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/clickableSchmatics">Clickable Schematics</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="mainContent">
        {/* <div className="container bgContent">
          <div className="row">
            <div className="pageTitle">
              <h1>Clickable Schematics</h1>
              <p>Clickable Schematics</p>
            </div>
          </div>
        </div> */}
        <div className="container bgContent">
          <div className="row">
            {/* <div className="pageTitle">
              <h1>Products</h1>
              <p>Products</p>
            </div> */}
          </div>
          <p
            style={{
              textAlign: "center",
              padding: "50px",
              position: "relative",
            }}
          >
            <span className="cate-heading">Clickable Schmatics</span>
            <img
              src="../images/textbanner.png"
              alt=""
              className="cate-image"
            ></img>
          </p>
        </div>
        <div className="container">
          <div className="pdfMain m-2">
            {data?.map((item, index) => {
              let str = item?.file?.url;

              let flag = str?.includes(".pdf");

              console.log(flag);
              if (!flag) {
                return (
                  <div className="pdfListing m-2" key={index}>
                    <div className="pdfImage">
                      <img src="images/imageIcon.png" alt="pdfIcon" />
                    </div>
                    <div className="pdfbottommapDiv">
                      <p className="pdfTitle">{item.name}</p>
                      <div className="pdfBtnss">
                        <a
                          target="_blank"
                          rel="noopener"
                          href={API_URL + `/` + `${item?.file?.url}`}
                          className="ppmBtn"
                        >
                          View
                        </a>
                        {/* <a download={API_URL+ `/` +`${item?.file?.url}`+`.pdf`} className="ppmBtn" >Download</a> */}
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="pdfListing m-2" key={index}>
                    <div className="pdfImage">
                      <img src="images/pdfIcon.png" alt="pdfIcon" />
                    </div>
                    <div className="pdfbottommapDiv">
                      <p className="pdfTitle">{item.name}</p>
                      <div className="pdfBtnss">
                        <a
                          target="_blank"
                          rel="noopener"
                          href={API_URL + `/` + `${item?.file?.url}`}
                          className="ppmBtn"
                        >
                          View
                        </a>
                        {/* <a download={API_URL+ `/` +`${item?.file?.url}`+`.pdf`} className="ppmBtn" >Download</a> */}
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClickableSchmatics;
