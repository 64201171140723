
import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import image from '../../src/img/dummy.png';

// const API = "https://api.pujakaitem.com/api/products"

// const API = "https://PpmRacingProducts-QA.chetu.com"

const Products = ({ product }) => {
   

    return (
        <div className='container' id="container" >
            <NavLink className="productCard" to='singleproduct/'>
                {product.image.url ? <img src={`https://PpmRacingProducts-QA.chetu.com/${product.image.url}`} alt={product.image}></img> : <img src={image} alt="dummy-image"></img>}
                <p>{product.name}</p>
                <span>{'Price: ' + product.price}</span>
                <span>{'Discount: ' + product.discountAmount}</span>
            </NavLink>
            {/* {product.map((pr) => {
                return (
                    <Link className="productCard" key={pr.id}>
                        <img src={pr.image} />
                        <p>{pr.name}</p>
                    </Link>
                )
            })} */}
        </div>
    )
}

export default Products