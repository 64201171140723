// export const BASIC_URL = "http://localhost:3000";

// export const API_URL = "https://ppmracingproducts-qa.chetu.com/api";

export const grant_type = "client_credentials";
export const client_id = "l7d363d9f4db84472baaa67e7c7194aa9f";
export const client_secret = "2e0f97679de6409094e884fc51953a57";

//  export const API_URL = "https://uat-api.ppmracingproducts.com";
 export const API_URL = "https://prod-api.ppmracingproducts.com";

export const SANDBOX_PAYPAL =
  "AWvsvpl7iXlR9pHvt4HvMNDFYO1JWYu16yA89CWy7le-PKMHD0TfuSDQCL1GWlVsZLFUqV9n_2TUN5tP";
  
export const LIVE_PAYPAL =
  "AXuBiiZkqdFSbp4-NzsR9JupwfpKzYfj_TR7Y35ksTQUgS92e6b4fQcEuyDTi014t_SsozeuxntBGBOd";
export const ENV = "PROD";
export const SECRET_KEY = "ppmracingproducts-retl";
//DEV || PROD

export const CITY = "Rockwood";
export const STATE = "TN";
export const POSTALCODE = "37854";
export const COUNTRYCODE = "US";
export const PERSONNAME = "Rodney Hamby";
export const PHONENUMBER = "8775600619";
export const COMPANYNAME = "PPMRACING PRODUCTS";
export const STREETLINES = "201 S Wilder Ave";
export const WEIGHTUNIT = "LB";
export const HEIGHTUNIT = "IN";
export const ACCOUNTNUMBER = "112375120";
