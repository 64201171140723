import {
    GET_ALL_BRAND_REQUEST, GET_ALL_SIDE_BRAND_REQUEST, GET_BRAND_BY_ID,
  } from "./actionTypes";
  
  export const getAllBrands = (brands) => (dispatch) => {
    dispatch({
      type: GET_ALL_BRAND_REQUEST,
      payload: brands,
    });
  };
  
  export const getAllSideBrands = (brands) => (dispatch) => {
    dispatch({
      type: GET_ALL_SIDE_BRAND_REQUEST,
      payload: brands,
    });
  };

  export const getBrandById = (brand) => (dispatch) => {
    dispatch({
      type: GET_BRAND_BY_ID,
      payload: brand,
    });
  };