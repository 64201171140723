import React, { useEffect } from "react";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useState } from "react";
import TotalAmount from "../utils/cartAmountHelper";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import orderServices from "../services/order-services";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import StateCode from "../../src/StateCode.json";

import { emptyCart } from "../redux/actions/catagoryAction";

import {
  CITY,
  STATE,
  POSTALCODE,
  COUNTRYCODE,
  STREETLINES,
  COMPANYNAME,
  PHONENUMBER,
  PERSONNAME,
  WEIGHTUNIT,
  HEIGHTUNIT,
  SANDBOX_PAYPAL,
  LIVE_PAYPAL,
  ENV,
} from "../constants/constants";

const PaypalGetway = (props) => {
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [payId, setPayId] = useState("");
  const cartDataproduct = props.cartProductData.data || [];
  const getTotal = TotalAmount(cartDataproduct);
  const { orderDetails, orderpayment, orderID } = props;
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = () => {
    setShowModal(false);
    navigate("/");
  };
  // const handleShow = () => setShowModal(true);

  let productData = props;

  const responseData = productData?.cartData?.map((item) => {
    return {
      groupPackageCount: item.quantity,
      weight: {
        value: item?.weight || 0.1,
        units: "LB",
      },
      dimensions: {
        length: item?.length || 1,
        width: item?.width || 1,
        height: item?.height || 1,
        units: "IN",
      },
    };
  });

  const {
    firstName,
    lastName,
    email,
    phone,
    addressLine1,
    addressLine2,
    state,
    city,
    country,
    zip,
  } = orderDetails;
  const totalPrice = localStorage.getItem("beWecirPlatot");

  let stateCodes = Object.keys(StateCode).filter(
    (key) => StateCode[key] === state
  );
  stateCodes = stateCodes.length ? stateCodes[0] : "";

  const key = ENV === "DEV" ? SANDBOX_PAYPAL : LIVE_PAYPAL;

  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Order Placed successfully!!",
            amount: {
              currency_code: "USD",
              value: totalPrice,
            },
          },
        ],
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((paypalId) => {
        localStorage.setItem("payid", paypalId);
        setPayId(paypalId);
        return paypalId;
      });
  };

  const onApprove = async (data, actions) => {
    return actions.order.capture().then(function (details) {
      if (details.status === "COMPLETED") {
        let pid = localStorage.getItem("payid");

        const bodyParameters = {
          orderId: orderID,
          status: "confirmed",
          paypalTransactionId: details?.id,
          labelResponseOptions: "URL_ONLY",
          requestedShipment: {
            shipper: {
              contact: {
                personName: PERSONNAME,
                phoneNumber: PHONENUMBER,
                companyName: COMPANYNAME,
              },
              address: {
                streetLines: [STREETLINES],
                city: CITY,
                stateOrProvinceCode: STATE,
                postalCode: POSTALCODE,
                countryCode: COUNTRYCODE,
              },
            },
            recipients: [
              {
                contact: {
                  personName: `${firstName} ${lastName}`,
                  phoneNumber: `${phone}`,
                },
                address: {
                  streetLines: [`${addressLine1} ${addressLine2}`],
                  city: `${city}`,
                  stateOrProvinceCode: stateCodes,
                  postalCode: `${zip}`,
                  countryCode: "US",
                },
              },
            ],
            shipDatestamp: "2023-06-22",
            serviceType: "FEDEX_GROUND",
            packagingType: "YOUR_PACKAGING",
            pickupType: "USE_SCHEDULED_PICKUP",
            blockInsightVisibility: false,
            shippingChargesPayment: {
              paymentType: "SENDER",
            },
            labelSpecification: {
              imageType: "ZPLII",
              labelStockType: "STOCK_4X6",
            },
            requestedPackageLineItems: [
              {
                weight: {
                  value: 1,
                  units: "LB",
                },
                dimensions: {
                  length: 2,
                  width: 2,
                  height: 2,
                  units: "IN",
                },
              },
            ],
          },
          accountNumber: {
            value: "112375120",
          },
        };
        // return;

        orderServices
          .getOrderStatus(bodyParameters)
          .then((res) => {
            if (res.status === 200) {
              localStorage.removeItem("beWtnuomAgnippihs");
              localStorage.removeItem("beWecirPlatot");
              localStorage.removeItem("camount");
              localStorage.removeItem("couponType");
              localStorage.removeItem("couponId");
              toast.success(res.message);
              setShowModal(true);
            } else if (res.status === 403) {
              toast.error(res.message);
            } else if (res.status === 401) {
              toast.error(res.message);
            } else {
              toast.error(res.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      const { payer } = details;
      // const name = details.payer.name.given_name;
      // alert(`Transaction completed by ${name}`);
      setSuccess(true);
    });
  };

  const onError = (data, actions) => {
    setErrorMessage("An error occured with your payment");
  };
  return (
    <div>
      <PayPalScriptProvider
        options={{
          "client-id": key,
        }}
      >
        {show ? (
          <PayPalButtons
            style={{ layout: "vertical" }}
            createOrder={createOrder}
            onApprove={onApprove}
            onError={onError}
          />
        ) : null}
        <PayPalButtons
          style={{ layout: "vertical" }}
          createOrder={createOrder}
          onApprove={onApprove}
          onError={onError}
        />
        {success ? (
          <>
            {toast.success(
              "Your Payment has been done successfully please check email"
            )}
            {props.emptyCart()}
            {navigate("/")}
          </>
        ) : (
          <h2>payment is pending</h2>
        )}
      </PayPalScriptProvider>
      {/* <button
                className="ppmBtn mb-3"
                id="payNow"
                data-bs-toggle="modal"
                data-bs-target="#orderConfirmationModal"

            // onClick={modalOpen()}
            >
                PAY NOW
            </button> */}

      {/* <div
                className="modal fade applyCoupon show"
                id="orderConfirmationModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="d-flex justify-content-end me-3 mt-3">
                            <div
                                className="modalClose btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modalBody">
                            <div className="container">
                                <div className="row text-center justify-content-center">
                                    <div className="img-fluid mb-5">
                                        <img src="./images/successful.png" alt='image' />
                                    </div>
                                    <h3>Your Order is complete!</h3>
                                    <p>
                                        You will be receiving a confirmation email with order details.
                                    </p>
                                    <button className="ppmBtn mb-3">Explore More Products</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      {/* <Button variant="primary" onClick={handleShow}>
                Launch static backdrop modal
            </Button> */}
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Your Order has completed!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You will be receiving a confirmation email with order details
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    isLoggedIn: state?.auth?.isLoggedIn,
    cartProducts: state?.catagoryReducer?.Carts,
    cartProductData: state?.catagoryReducer?.CartData,
    cartData: state?.catagoryReducer?.CartData?.data,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    emptyCart: () => dispatch(emptyCart()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaypalGetway);
