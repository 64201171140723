import {
    GET_ALL_PRODUCT_SUCCUSS, GET_PRODUCT_BY_ID
} from "../actions/actionTypes";

const initialState = {
    products: [],
    product: []
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_PRODUCT_SUCCUSS:
            return {
                ...state,
                products: payload
            };

        case GET_PRODUCT_BY_ID:
            return {
                ...state,
                product: payload
            };
        default:
            return state;
    }
}