import React, { useState, useEffect } from "react";
import productService from "../../services/product-catagorie-service";
import { useDispatch } from "react-redux";
import { getAllProducts } from "../../redux/actions/productAction";
import { API_URL } from "../../constants/constants";
import axios from "axios";
const DropdownFilter = ({pageCountFn}) => {
  const [filterDropdown, setFilterDropdown] = useState("");
  const dispatch = useDispatch();
  const filterdAPI = () => {};

  const handleFilter = async (x) => {
    let payload = JSON.parse(localStorage.getItem("filteredData"));
    pageCountFn(1)
    let limit = 24;
    if (!x) {
      localStorage.setItem("sortingArr", "");
    }
    if (x == "4") {
      localStorage.setItem("sortingArr", x);
      payload.sortBy = "lowToHigh";

      const res = await axios.post(
        `${API_URL}/admin/product/getProducts?limit=${limit}&page=1`,
        payload
      );
      const data = await res?.data;
      dispatch(getAllProducts(data));
    } else if (x == "3") {
      localStorage.setItem("sortingArr", x);
      payload.sortBy = "highToLow";

      const res = await axios.post(
        `${API_URL}/admin/product/getProducts?limit=${limit}&page=1`,
        payload
      );
      const data = await res?.data;
      dispatch(getAllProducts(data));
    }
  };

  //   useEffect(() => {
  //     filterdAPI()
  //   }, [])

  return (
    <>
      <div className="row justify-content-end">
        <div className="form-floating sortListing mb-5">
          <select
            className="form-select"
            id="floatingSelect"
            name="filter"
            aria-label="Floating label select example"
            onChange={(e) => handleFilter(e.target.value)}
          >
            <option defaultValue="1"></option>
            <option value="3">Price High to Low</option>
            <option value="4">Price Low to High</option>
          </select>
          <label className="dropdownLabel" htmlFor="floatingSelect">
            Sort By
          </label>
        </div>
      </div>
    </>
  );
};

export default DropdownFilter;
