import React from 'react'
import { Link } from 'react-router-dom'

const TechResources = () => {
    return (
        <div>
            <nav className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <ul>
                            <li><Link to='/'>Home<span className="ms-3"><i className="fa-solid fa-angle-right"></i></span></Link></li>
                            <li><Link to='/techResources'>Tech Resources<span className="ms-3"><i className="fa-solid fa-angle-right"></i></span></Link></li>
                        </ul>
                    </div>
                </div>
            </nav>

            TechResources</div>
    )
}

export default TechResources