import React from 'react'

const Promos = () => {
    return (
        <>
            <div className="mainContent">
                <div className="container bgContent">
                    <div className="row">
                        <div className="pageTitle">
                            <h1>Promos</h1>
                            <p>Promos</p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row promos mt-5">
                        <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8 col-sm-8 col-xs-12">
                            <div className="promosLft">
                                <div className="d-flex justify-content-between align-items-center mb-5">
                                    <div className="promosDetails">
                                        <h2>UP TO 70% OFF</h2>
                                        <p>Save up to 70% off on Christmas purchases at Amazon</p>
                                    </div>
                                    <button className="ppmBtn promosBtn">PPM60</button>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mb-5">
                                    <div className="promosDetails">
                                        <h2>UP TO 60% OFF</h2>
                                        <p>Save up to 60% off on Christmas purchases at Amazon</p>
                                    </div>
                                    <button className="ppmBtn promosBtn">PPM60</button>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mb-5">
                                    <div className="promosDetails">
                                        <h2>UP TO 50% OFF</h2>
                                        <p>Save up to 50% off on Christmas purchases at Amazon</p>
                                    </div>
                                    <button className="ppmBtn promosBtn">PPM60</button>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mb-5">
                                    <div className="promosDetails">
                                        <h2>UP TO 40% OFF</h2>
                                        <p>Save up to 40% off on Christmas purchases at Amazon</p>
                                    </div>
                                    <button className="ppmBtn promosBtn">PPM60</button>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mb-5">
                                    <div className="promosDetails">
                                        <h2>UP TO 30% OFF</h2>
                                        <p>Save up to 30% off on Christmas purchases at Amazon</p>
                                    </div>
                                    <button className="ppmBtn promosBtn">PPM60</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-12">
                            <div className="promosRgt">
                                <div className="sideNav">
                                    <p>Product categories</p>
                                    <ul>
                                        <li>
                                            <a
                                                href="#"
                                                className="d-flex justify-content-between align-items-center"
                                            >
                                                <p>Category 1</p>
                                                <p>(2)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                className="d-flex justify-content-between align-items-center"
                                            >
                                                <p>Category 2</p>
                                                <p>(5)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                className="d-flex justify-content-between align-items-center"
                                            >
                                                <p>Category 3</p>
                                                <p>(1)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                className="d-flex justify-content-between align-items-center"
                                            >
                                                <p>Category 4</p>
                                                <p>(3)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                className="d-flex justify-content-between align-items-center"
                                            >
                                                <p>Category 5</p>
                                                <p>(6)</p>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#"
                                                className="d-flex justify-content-between align-items-center"
                                            >
                                                <p>Category 6</p>
                                                <p>(4)</p>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="sideNav">
                                    <p>Brands</p>
                                    <a href="#" className="promoBrand">
                                        <div className="promoBrandImg">
                                            <img src="./images/promobrand.png" />
                                        </div>
                                        <p>Bert Transmission</p>
                                    </a>
                                    <a href="#" className="promoBrand">
                                        <div className="promoBrandImg">
                                            <img src="./images/promobrand.png" />
                                        </div>
                                        <p>Bert Transmission</p>
                                    </a>
                                    <a href="#" className="promoBrand">
                                        <div className="promoBrandImg">
                                            <img src="./images/promobrand.png" />
                                        </div>
                                        <p>Bert Transmission</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Promos