import React, { useState } from "react";
import { useEffect } from "react";
import {
  AddCart,
  DeleteCart,
  getCartData,
  cartDataResponse,
  AddWishlist,
} from "../redux/actions/catagoryAction";
import { connect } from "react-redux";
import productService from "../services/product-catagorie-service";
import { getProductById, getAllProducts } from "../redux/actions/productAction";
import { useDispatch } from "react-redux";
import {
  NavLink,
  useParams,
  useNavigate,
  Link,
  useLocation,
} from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import cartService from "../services/cart-service";
import isUrl from "is-url";
import { API_URL } from "../constants/constants";
// import { getProductById } from "../redux/actions/productAction"
import wishListService from "../services/wishlist-service";
import axios from "axios";
import moment from "moment";
// import AnchorLink from "react-anchor-link-smooth-scroll";
import Zoom from "react-img-zoom";

const ProductDetail = (props) => {
  const { id } = useParams();
  const cartDataStore = props.cartData || [];
  const [count, setCount] = useState(1);
  const [similarProd, setSimilarProd] = useState([]);
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0);
  const [products, setProducts] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [utype, setUtype] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [selectedImage, setSelectedImage] = useState("");

  // const [shouldReload, setShouldReload] = useState(true);

  const location = useLocation();

  const parts = location.pathname.split("/").filter((part) => part !== "");

  const dispatch = useDispatch();
  const productById = props.productById || [];
  const wishListDataStore = props.wishlistData || [];

  const navigate = useNavigate();

  let proId = productById?._id;

  useEffect(() => {
    let imagePath = productById?.image?.[0]?.url;
    imagePath = imagePath?.trim();
    let outputStr = imagePath?.replace(/ /g, "%20");
    setSelectedImage(outputStr);
  }, [productById]);

  function increment() {
    setCount(count + 1);
  }

  function decrement() {
    setCount(count > 1 ? count - 1 : 1);
  }

  function myRating(x) {
    setRating(x);
  }
  async function getReview() {
    const data = await axios.get(API_URL + "/user/dashboard/getProductRating");
  }

  const submitReview = async () => {
    // window.alert("hi gtakm je")
    if (!rating && !review) {
      return toast.error("Please write some review or give rating");
    } else {
      const url = `${API_URL}/user/dashboard/reviewProduct`;
      const payLoad = {
        productId: proId,
        reviews: review,
        rating: rating,
      };
      const token = await JSON.parse(localStorage.getItem("userInfo"));

      if (!token) {
        toast.error("Please login first to submit the review.");
      }
      const { data } = await axios.post(url, payLoad, {
        headers: { Authorization: token },
      });
      setReview("");
      // toast.success()
      if (data?.status == 200) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        swal("Success!", data?.message, "success");
      } else if (data?.status == 400) {
        swal("Info", data?.message);
      }
    }
  };

  const handleMessageChange = (event) => {
    // 👇️ access textarea value
    setReview(event.target.value);
  };

  const getCartData = async () => {
    props.getCartData();
    await cartService
      .getProductFromCart()
      .then((response) => {
        if (response.status === 200) {
          const responseData = response.cart.map((item) => {
            return {
              id: item.productId,
              name: item.productName,
              brand: item.brand,
              description: item.description,
              discountAmount: item.discountAmount,
              discountPercent: item.discountPercent,
              discountedPrice: item.discountedPrice,
              image: item.image,
              price: item.price,
              quantity: item.quantity,
              // quantityOnHand:item
            };
          });
          props.cartDataResponse(responseData);
        }
      })
      .catch((error) => console.log(error));
  };

  const AddCartHandler = async (product) => {
    productService
      .getProductsByCategory(id)
      .then((res) => {
        dispatch(getAllProducts(res.products));
        setProducts(res.products);
        const total = 60;
        setpageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
      });
    const addedProduct = cartDataStore.find(
      (cartRecord) => cartRecord.id === product?._id
    );
    const productId = addedProduct?.id || product?._id;
    const productQuantity = addedProduct?.quantity || 0;
    if (count) {
      const cartData = [
        {
          productId: productId,
          quantity: count,
        },
      ];
      props.AddCart({ ...product, quantity: count });
      await cartService
        .addProductToCart(cartData)
        .then((res) => {
          getCartData();
          toast.success(res.message);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      removeProductFromCart(product);
    }
  };

  const AddCartHandlerSimilar = async (product) => {
    props.AddCart(product);
    const addedProduct = cartDataStore.find(
      (cartRecord) => cartRecord.id === product?._id
    );
    const cartData = [
      {
        productId: addedProduct?.id,
        quantity: addedProduct?.quantity,
      },
    ];
    await cartService
      .addProductToCart(cartData)
      .then((res) => {
        toast.success(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchProductById = (id) => {
    productService
      .getProductsById(id)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getProductById(res.product));
          navigate(`/productDetail/${id}`);
          window.location.reload(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    //Runs only on the first render
  }, [imageUrl]);

  //Add wishlist

  const WishlistHandler = async (product) => {
    props.AddWishlist(product);
    const addedWishlistProduct = wishListDataStore.find(
      (wishlistRecord) => wishlistRecord.id === product?._id
    );
    const wishlistData = [
      {
        productId: addedWishlistProduct?.id,
      },
    ];
    await wishListService
      .addProductToWishlist(wishlistData)
      .then((res) => {
        toast.success(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const removeProductFromCart = async (product) => {
    const removeProduct = {
      productId: product._id,
    };
    await cartService
      .removeProductFromCart(removeProduct)
      .then((res) => {
        props.DeleteCart(product);
        toast.warn(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Add wishlist similar

  const WishlistHandlerSimilar = async (product) => {
    props.AddWishlist(product);
    const addedWishlistProduct = wishListDataStore.find(
      (wishlistRecord) => wishlistRecord.id === product?._id
    );
    const wishlistData = [
      {
        productId: addedWishlistProduct?.id,
      },
    ];
    await wishListService
      .addProductToWishlist(wishlistData)
      .then((res) => {
        toast.success(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const similarProducts = async (categoryId) => {
    if (categoryId) {
      productService
        .getProductsByCategory(categoryId)
        .then((res) => {
          dispatch(getAllProducts(res.products));
          setProducts(res.products);
          setSimilarProd(res.products);
          //  const total = 60;
          // setpageCount(Math.ceil(total / limit));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    // scroll to the top
    window.scrollTo(0, 0);
    let uType = localStorage.getItem("userType");

    // fetchProductById(parts[1]);

    setUtype(uType);
    setCategoryId(productById?.categoryId);
    setImageUrl(productById?.image?.[0]?.url);
    setSelectedImage(productById?.image?.[0]?.url);
    const addedProduct = cartDataStore.find(
      (cartRecord) => cartRecord.id === id
    );
    setCount(addedProduct?.quantity || 1);
    similarProducts(categoryId);
    productService
      .getProductsById(id)
      .then((res) => {
        setCategoryId(res?.product?.categoryId);
        if (res.status === 200) {
          dispatch(getProductById(res.product));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [categoryId, imageUrl, parts[1]]);

  useEffect(() => {
    getReview();
    // imageZoom("myimage", "myresult");
  }, []);

  // useEffect(() => {
  //   // Use history.push() to navigate to the specified route

  //   navigate('/agam');

  // }, [navigate]);

  /***
   * custom fuction for product image zoom out
   */

  $(".img_producto_container")
    // tile mouse actions
    .on("mouseover", function () {
      $(this)
        .children(".img_producto")
        .css({ transform: "scale(" + $(this).attr("data-scale") + ")" });
    })
    .on("mouseout", function () {
      $(this).children(".img_producto").css({ transform: "scale(1)" });
    })
    .on("mousemove", function (e) {
      $(this)
        .children(".img_producto")
        .css({
          "transform-origin":
            ((e.pageX - $(this).offset().left) / $(this).width()) * 100 +
            "% " +
            ((e.pageY - $(this).offset().top) / $(this).height()) * 100 +
            "%",
        });
    });

  const [imgIndex, setImgIndex] = useState(0);
  const hanldeImageChange = (url) => {
    setSelectedImage(url.split("key_")[0]);
    setImgIndex(url.split("key_")[1]);
  };
  return (
    <>
      <section>
        <nav className="breadcrumb">
          <div className="container">
            <div className="row">
              <ul>
                <li>
                  <NavLink to="/">
                    Home
                    <span className="ms-3">
                      <i className="fa-solid fa-angle-right"></i>
                    </span>
                  </NavLink>
                </li>
                <li>
                  <a href="#">Product Name</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="mainContent">
          <div className="container">
            <div className="row mt-5">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-6 col-sm-12 col-xs-12 img-zoom-container">
                <div className="productDetailImage">
                  <section className="product-page row">
                    <div className="thumbnails">
                      {productById?.image?.[0]?.url ? (
                        productById?.image?.map((img, key) => {
                          return (
                            <>
                              <div
                                class={
                                  key == imgIndex ? "thumb active" : "thumb"
                                }
                              >
                                {isUrl(img.url) ? (
                                  <img
                                    key={img.id}
                                    src={img.url}
                                    alt={img.name}
                                  ></img>
                                ) : (
                                  <img
                                    key={img.id}
                                    src={API_URL + "/" + img.url}
                                    alt={img.name}
                                    onClick={() =>
                                      hanldeImageChange(img.url + "key_" + key)
                                    }
                                  ></img>
                                )}
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <div class="thumb">
                          <img
                            src="../images/dummyProduct.jpg"
                            alt="thumb-air-force-left-side"
                          />
                        </div>
                      )}
                    </div>
                    <div className="car">
                      {selectedImage ? (
                        // productById?.image?.map((img) => {

                        //   return (
                        <>
                          {isUrl(selectedImage) ? (
                            <>
                              <Zoom
                                img={selectedImage}
                                zoomScale={1.5}
                                height={500}
                                width={400}
                                transitionTime={0.5}
                                style={{ backgroundSize: "contain" }}
                              />
                            </>
                          ) : (
                            <>
                              <div className="customCursor">
                                {/* <Zoom
                                  img={
                                    API_URL +
                                    "/" +
                                    selectedImage
                                  }
                                  className='zoomClass'
                                  zoomScale={2}
                                  height={300}
                                  width={600}
                                  transitionTime={0.9}
                                /> */}
                                <div
                                  class="img_producto_container"
                                  data-scale="1.6"
                                >
                                  <a
                                    className="dslc-lightbox-image img_producto"
                                    href={API_URL + "/" + selectedImage}
                                    target="_self"
                                    style={{
                                      backgroundImage: `url(${
                                        API_URL + "/" + selectedImage
                                      })`,
                                    }}
                                  ></a>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        //   );
                        // })
                        <div class="agam">
                          <img
                            src="../images/dummyProduct.jpg"
                            alt="thumb-air-force-left-side"
                          />
                        </div>
                      )}
                      {/* <span className="zoom">
                         <div class="agam">
                           
                            <img
                              src="../images/img2.jpg"
                              alt="thumb-air-force-left-side"
                            />
                          </div>
                      </span> */}
                    </div>
                    {/* <div className="img-display1" style={{ display: "none" }}>
                      <video id="videoid" controls>
                        <source
                          src="https://www.w3schools.com/html/mov_bbb.mp4"
                          type="video/mp4"
                        />
                      </video>
                    </div> */}
                  </section>
                </div>
                {/* <div id="myresult" class="img-zoom-result"></div> */}
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <div className="productDetails">
                  <h4>{productById.name}</h4>
                  <div className="pReviews">
                    <div className="ratings me-3">
                      {(() => {
                        const arr = [];
                        let rating = Math?.ceil(productById?.rating);
                        console.log();
                        let remaining = 5 - rating;
                        for (let j = 1; j <= rating; j++) {
                          arr.push(<i className="fa-solid fa-star"></i>);
                        }
                        for (let j = 1; j <= remaining; j++) {
                          arr.push(<i className="fa-regular fa-star"></i>);
                        }
                        return arr;
                      })()}
                      {/* <i class="fa-regular fa-star"></i> */}
                    </div>

                    {productById?.rating > 0 ? (
                      <p
                        className="me-3"
                        style={{ fontWeight: "500", color: "grey" }}
                      >
                        {productById?.reviews?.length} Reviews
                      </p>
                    ) : (
                      <></>
                    )}

                    {/* <AnchorLink
                      href="#textReview"
                      className="me-3"
                      style={{
                        textDecoration: "underLine",
                        fontWeight: "bold",
                      }}
                    >
                      Write a review
                    </AnchorLink> */}
                  </div>
                  <div className="price">
                    <span className="me-3">
                      $ {productById?.price}
                      {/* {utype == "yellow"
                        ? productById?.yellowDealerPrice
                        : utype == "blue"
                        ? productById.blueDealerPrice
                        : productById.discountedPrice} */}
                    </span>
                    {productById.discountedPrice != 0 ? (
                      <span className="me-3">
                        ${productById?.oldPrice ?? productById?.price}{" "}
                      </span>
                    ) : (
                      <></>
                    )}
                    {productById.discountPercent != 0 ? (
                      <span>{productById.discountPercent}% Off</span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="qty">
                    <span>QTY :</span>
                    <section className="container">
                      <div className="row">
                        <div className="qtyCount">
                          <button
                            className="minus"
                            id="minus"
                            onClick={decrement}
                          >
                            -
                          </button>
                          <input
                            className="count"
                            id="count"
                            type="number"
                            value={count}
                          />
                          <button
                            className="plus"
                            id="plus"
                            onClick={increment}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div className="pDetails mt-3">
                    <div className="d-flex align-items-center justify-content-start">
                      <img src="../images/rArrow.png" />
                      <span className="ms-3">
                        <Link to={`/brandDetail/${productById.brandId}`}>
                          {productById?.brand ? productById.brand : ""}
                        </Link>
                      </span>
                    </div>
                    {/* <div className="d-flex align-items-center"><img src="../images/rArrow.png" /><span className="ms-3">Lorem Ipsum is simply dummy text.</span></div>
                    <div className="d-flex align-items-center"><img src="../images/rArrow.png" /><span className="ms-3">Lorem Ipsum is simply dummy text.</span></div> */}
                  </div>
                  <div className="pBtns mt-2">
                    <button
                      className="ppmBtn me-3"
                      onClick={() => AddCartHandler(productById)}
                    >
                      ADD TO CART
                    </button>
                    <button
                      className="wishlistBtn"
                      onClick={() => WishlistHandler(productById)}
                      title="Add to wishlist"
                    >
                      <i className="fa-regular fa-heart"></i>
                    </button>
                  </div>
                  {/* <div className="mDetails mt-5"> */}
                  <div className="mt-5" style={{ lineHeight: "30px" }}>
                    <div className="me-3">
                      <span>SKU: </span>
                      <span>&nbsp;&nbsp; {productById.sku}</span>
                    </div>
                    {productById?.category ? (
                      <>
                        {" "}
                        <div className="me-3">
                          <span>Category: </span>
                          <Link
                            to={`/categoryDetail/${productById.categoryId}`}
                          >
                            &nbsp;&nbsp; {productById?.category}
                          </Link>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="me-3">
                      <span> Height(in inches) : </span>
                      <span>
                        &nbsp;&nbsp; {productById.height ?? "Not found"}
                      </span>
                    </div>
                    <div className="me-3">
                      <span>Length (in inches): </span>
                      <span>
                        &nbsp;&nbsp; {productById.length ?? "Not found"}
                      </span>
                    </div>
                    <div className="me-3">
                      <span>Width (in inches): </span>
                      <span>
                        &nbsp;&nbsp; {productById.width ?? "Not found"}
                      </span>
                    </div>
                    <div className="me-3">
                      <span>Weight (in pound): </span>
                      <span>
                        &nbsp;&nbsp; {productById.weight ?? "Not found"}
                      </span>
                    </div>

                    {productById?.quantityOnHand > 0 ? (
                      <>
                        {" "}
                        <div className="me-3">
                          <span>Quantity on hand: </span>

                          <span>&nbsp;&nbsp; {productById.quantityOnHand}</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <p style={{ color: "red" }}>Out of stock</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="productDetailsTabs mt-4" id="home_section">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="description"
                      data-bs-toggle="tab"
                      data-bs-target="#description-pane"
                      type="button"
                      role="tab"
                      aria-controls="description-pane"
                      aria-selected="true"
                    >
                      Description
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="specs"
                      data-bs-toggle="tab"
                      data-bs-target="#specs-pane"
                      type="button"
                      role="tab"
                      aria-controls="specs-pane"
                      aria-selected="false"
                    >
                      Specification
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="reviews"
                      data-bs-toggle="tab"
                      data-bs-target="#reviews-pane"
                      type="button"
                      role="tab"
                      aria-controls="reviews-pane"
                      aria-selected="false"
                    >
                      Reviews ({productById?.reviews?.length})
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="description-pane"
                    role="tabpanel"
                    aria-labelledby="description"
                    tabIndex="0"
                  >
                    {productById?.description ? (
                      <>
                        {" "}
                        <>
                          <div className="row mt-3">
                            <p>{productById?.description}</p>
                          </div>
                        </>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div
                    className="tab-pane fade"
                    id="specs-pane"
                    role="tabpanel"
                    aria-labelledby="specs"
                    tabIndex="0"
                  >
                    {" "}
                    {/* <p style={{ marginTop: "20px", marginBottom: "10px" }}>
                      {productById?.specs}
                    </p> */}
                    {productById?.specsPdf != "null" &&
                    productById?.specsPdf != null ? (
                      <>
                        <a
                          href={API_URL + `/` + `${productById?.specsPdf}`}
                          target="_blank"
                        >
                          <img
                            src="../images/pdf.png"
                            alt=""
                            width="50"
                            height="50"
                            target="_blank"
                          />
                        </a>
                      </>
                    ) : (
                      <>
                        <p>There is no specs available</p>
                      </>
                    )}
                  </div>

                  <div
                    className="tab-pane fade"
                    id="reviews-pane"
                    role="tabpanel"
                    aria-labelledby="reviews"
                    tabIndex="0"
                  >
                    <div className="row mt-3" id="things">
                      {productById?.reviews == 0 ? (
                        <>
                          <div
                            className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12"
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                          >
                            <p>No reviews yet</p>
                          </div>
                        </>
                      ) : (
                        <>
                          {productById?.reviews?.map((elem) => {
                            return (
                              <>
                                <div
                                  className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12"
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <div className="reviewsLeft">
                                    <div className="rRatingsTop mt-3">
                                      {elem?.rating > 0 ? (
                                        <div className="rRatings">
                                          <span className="me-1">
                                            {elem?.rating}
                                          </span>
                                          <span>
                                            <i className="fa-solid fa-star"></i>
                                          </span>
                                        </div>
                                      ) : null}

                                      <p>
                                        {moment(elem?.createdOn).fromNow()} by{" "}
                                        {elem?.name}{" "}
                                      </p>
                                    </div>
                                    <p className="mt-3">{elem?.reviews} </p>
                                    {/* <a>Read More</a> */}
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" id="textReview">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                {productById?.reviews == 0 ? (
                  <p className="mb-3 mt-3">
                    Be the first to review “{productById?.name}”
                  </p>
                ) : null}

                {rating === 0 ? (
                  <>
                    <div className="yrRating mb-3">
                      <span className="me-3">Your Rating:</span>
                      <div className="me-3" style={{ color: "red" }}>
                        <span onClick={() => myRating(1)}>
                          <i className="fa-solid fa-star"></i>
                        </span>
                        <span onClick={() => myRating(2)}>
                          <i className="fa-solid fa-star"></i>
                        </span>
                        <span onClick={() => myRating(3)}>
                          <i className="fa-solid fa-star"></i>
                        </span>
                        <span onClick={() => myRating(4)}>
                          <i className="fa-solid fa-star"></i>
                        </span>
                        <span onClick={() => myRating(5)}>
                          <i className="fa-solid fa-star"></i>
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {(() => {
                      const arr = [];
                      // let remaining = 5 - rating;
                      for (let i = 1; i <= rating; i++) {
                        arr.push(
                          <span
                            style={{ color: "#FFC109" }}
                            onClick={() => myRating(i)}
                          >
                            <i className="fa-solid fa-star"></i>
                          </span>
                        );
                      }
                      for (let i = rating; i < 5; i++) {
                        arr.push(
                          <span onClick={() => myRating(i)}>
                            <i className="fa-solid fa-star"></i>
                          </span>
                        );
                      }
                      return arr;
                    })()}
                  </>
                )}

                <div className="row">
                  <div className="form-floating">
                    <textarea
                      name="review"
                      placeholder="Enter Your Review"
                      value={review}
                      onChange={handleMessageChange}
                    ></textarea>
                  </div>
                </div>
                {/* <div className="row mt-3">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="Name"
                              />
                              <label htmlFor="floatingInput">Name</label>
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-floating mb-3">
                              <input
                                type="email"
                                className="form-control"
                                id="floatingInput"
                                placeholder="Email Address"
                              />
                              <label htmlFor="floatingInput">
                                Email Address
                              </label>
                            </div>
                          </div>
                        </div> */}
                <div className="row">
                  {/* <div className="d-flex align-items-center">
                            <input className="me-3" type="checkbox" />
                            <span>
                              Save my name, email, and website in this browser
                              htmlFor the next time I comment.
                            </span>
                          </div> */}
                </div>
                <div className="row mt-3 justify-content-end px-2">
                  <button className="ppmBtn" onClick={() => submitReview()}>
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container newArrivals mb5">
            <div className="row">
              <div className="productListing">
                <div className="row">
                  {similarProd?.length != 0 ? (
                    <div className="naTitle">
                      <span>Similar Products</span>
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {similarProd?.slice(0, 3).map((item, index) => (
                    <>
                      <div class="col-md-5 col-lg-3 mt-3" key={index}>
                        <a href={`/productDetail/${item?._id}`}>
                          <div className="plImage">
                            <img
                              // src={API_URL + "/" + item?.image?.[0]?.url}
                              src={
                                isUrl(item?.image[0]?.url)
                                  ? item?.image[0]?.url
                                  : item?.image[0]?.url == null ||
                                    item?.image[0]?.url == "N/A"
                                  ? "../images/dummyProduct.jpg"
                                  : API_URL + "/" + item?.image[0]?.url
                              }
                            />
                            <span>New</span>
                          </div>
                        </a>

                        <div className="plInfo">
                          <div className="reviews">
                            {(() => {
                              const arr = [];
                              let rating = Math?.ceil(item?.rating);
                              let remaining = 5 - rating;
                              for (let j = 0; j < rating; j++) {
                                arr.push(<i className="fa-solid fa-star"></i>);
                              }
                              for (let j = 0; j < remaining; j++) {
                                arr.push(
                                  <i className="fa-regular fa-star"></i>
                                );
                              }
                              return arr;
                            })()}
                          </div>
                          <p>{item?.name}</p>
                          {/* <span className="me-3">$ {item?.price}</span>
                          <span
                            className="me-3"
                            style={{ textDecoration: "line-through" }}
                          >
                            ${item?.oldPrice ?? item?.price}{" "}
                          </span> */}

                          <div className="dealsPrice mb-3">
                            <span>
                              {/* ${product?.oldPrice ? product.oldPrice : product.price} */}
                              {item?.oldPrice ? "$" + item?.oldPrice : null}
                            </span>
                            <span>${item?.price}</span>
                          </div>
                        </div>
                        <div className="plBtns">
                          <button
                            className="atcBtn"
                            onClick={() => AddCartHandlerSimilar(item)}
                          >
                            Add to Cart
                          </button>
                          <button
                            className="productBtn"
                            onClick={() => WishlistHandlerSimilar(item)}
                            title="Add to wishlist"
                          >
                            <i className="fa-solid fa-heart"></i>
                          </button>
                          <button
                            className="productBtn"
                            onClick={() => fetchProductById(item._id)}
                            title="Product detail"
                          >
                            <i className="fa-solid fa-eye"></i>
                          </button>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
function mapStateToProps(state) {
  return {
    productById: state?.productReducer?.product,
    isLoggedIn: state?.auth?.isLoggedIn,
    cartData: state?.catagoryReducer?.Carts,
    wishlistData: state?.catagoryReducer?.Wishlists,
    products: state?.catagoryReducer?.products,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    AddCart: (product) => dispatch(AddCart(product)),
    AddWishlist: (product) => dispatch(AddWishlist(product)),
    DeleteCart: (product) => dispatch(DeleteCart(product)),
    getCartData: () => dispatch(getCartData()),
    cartDataResponse: (data) => dispatch(cartDataResponse(data)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
