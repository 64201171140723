import React, { useEffect, useState } from "react";
import productService from "../../services/product-catagorie-service";
import BrandFilter from "./BrandFilter";
import CategoryFilter from "./CategoryFilter";
import { useDispatch } from "react-redux";
import { getAllProducts } from "../../redux/actions/productAction";
import "./PriceRange.css";
import ReactSlider from "react-slider";
import $ from "jquery";
import SubCategoryFilter from "./SubCategoryFilter";
import { useParams } from "react-router-dom";

const Filter = (props) => {
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(10000);

  const dispatch = useDispatch();

  const { id } = useParams();

  let message = window.location.href;

  var n = message.includes("categoryDetail");

  useEffect(() => {
    setBrandData([]);
  }, [props.filterTrue]);

  const clearFields = () => {
    setBrandData([]);
    setCategoryData([]);
    setSubCategoryData([]);
    setMax(10000);
    setMin(0);
    $("input[type=checkbox]").prop("checked", false);
    let filteredData = {
      categoryId: [],
      subCategoryId: [],
      brandId: [],
      minPrice: "",
      maxPrice: "",
    };

    if (!n) {
      localStorage.setItem("filteredData", JSON.stringify(filteredData));
      productService
        .searchProductByName(filteredData)
        .then((response) => {
          // const { data } = response;
          const products = response || [];
          if (response.status === 200) {
            dispatch(getAllProducts(products));
          } else if (response.status === 404) {
            dispatch(getAllProducts(products));
          }
        })
        .catch((error) => console.log(error));

      $("#ReactSliderShow").hide();
      $("#ReactSliderHide").show();
      window.location.reload();
    } else {
      localStorage.setItem("filteredData", JSON.stringify(filteredData));

      productService
        .searchProductByCategoryFilter(filteredData)
        .then((response) => {
          // const { data } = response;

          const products = response || [];
          if (response.status === 200) {
            dispatch(getAllProducts(products));
          } else if (response.status === 404) {
            dispatch(getAllProducts(products));
          }
        })
        .catch((error) => console.log(error));

      $("#ReactSliderShow").hide();
      $("#ReactSliderHide").show();

      window.location.reload();
    }
  };

  const filterdAPI = () => {
    let filteredData = {
      categoryId: categoryData,
      subCategoryId: subCategoryData,
      brandId: brandData,
      minPrice: min,
      maxPrice: max,
    };

    if (!n) {
      localStorage.setItem("filteredData", JSON.stringify(filteredData));
      productService
        .searchProductByName(filteredData)
        .then((response) => {
          // const { data } = response;

          const products = response || [];
          if (response.status === 200) {
            dispatch(getAllProducts(products));
          } else if (response.status === 404) {
            dispatch(getAllProducts(products));
          }
        })
        .catch((error) => console.log(error));
    } else {
      localStorage.setItem("filteredData", JSON.stringify(filteredData));

      filteredData = { ...filteredData, categoryId: [id] };

      productService
        .searchProductByCategoryFilter(filteredData)
        .then((response) => {
          // const { data } = response;

          const products = response || [];
          if (response.status === 200) {
            dispatch(getAllProducts(products));
          } else if (response.status === 404) {
            dispatch(getAllProducts(products));
          }
        })
        .catch((error) => console.log(error));
    }
  };
  useEffect(() => {
    filterdAPI();
  }, [brandData, categoryData, subCategoryData, min, max]);

  const onBrandChecked = (e) => {
    const brData = e.target.value;
    if (!e.target.checked) {
      const removedCheck = brandData.filter((find) => find !== brData);
      setBrandData([...removedCheck]);
    } else {
      setBrandData([...brandData, brData]);
    }
  };

  const onCategoryChecked = (e) => {
    const catData = e.target.value;
    if (!e.target.checked) {
      const removedCheck = categoryData.filter((find) => find !== catData);
      setCategoryData([...removedCheck]);
    } else {
      setCategoryData([...categoryData, catData]);
    }
  };

  const onSubCategoryChecked = (e) => {
    const catData = e.target.value;
    if (!e.target.checked) {
      const removedCheck = subCategoryData.filter((find) => find !== catData);
      setSubCategoryData([...removedCheck]);
    } else {
      setSubCategoryData([...subCategoryData, catData]);
    }
  };

  return (
    <>
      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
        <div className="filterProduct">
          <p>Filters</p>
          <p
            onClick={clearFields}
            style={{ cursor: "pointer", textDecoration: "underLine" }}
          >
            Clear All{" "}
          </p>
        </div>
        <section>
          {!n && <CategoryFilter onCategoryChecked={onCategoryChecked} />}

          <BrandFilter onBrandChecked={onBrandChecked} />

          {n && (
            <SubCategoryFilter onSubCategoryChecked={onSubCategoryChecked} />
          )}
          <div className="leftSideFilter">
            <div className="filterTitle">
              <p>price range</p>
            </div>
            <div className="price-range mt-2" id="ReactSliderShow">
              <ReactSlider
                defaultValue={[min, max]}
                className="slider"
                trackClassName="tracker"
                min={0}
                max={10000}
                minDistance={50}
                step={100}
                withTracks={true}
                pearling={true}
                renderThumb={(props) => {
                  return <div {...props} className="thumb-priceRang"></div>;
                }}
                renderTrack={(props) => {
                  return <div {...props} className="track"></div>;
                }}
                onChange={([min, max]) => {
                  setMin(min);
                  setMax(max);
                }}
              />
            </div>
            <div
              className="price-range mt-2"
              id="ReactSliderHide"
              style={{ display: "none" }}
            >
              <ReactSlider
                defaultValue={[min, max]}
                className="slider"
                trackClassName="tracker"
                min={0}
                max={10000}
                minDistance={50}
                step={100}
                withTracks={true}
                pearling={true}
                renderThumb={(props) => {
                  return <div {...props} className="thumb-priceRang"></div>;
                }}
                renderTrack={(props) => {
                  return <div {...props} className="track"></div>;
                }}
                onChange={([min, max]) => {
                  setMin(min);
                  setMax(max);
                }}
              />
            </div>
            <div className="values-wrapper">
              <p>
                Min Price:
                <span id="weight">${min}</span>
              </p>
              <p>
                Max Price:
                <span id="weight">${max}</span>
              </p>
            </div>
          </div>
          <div className="leftSideFilter">
            {/* <div className="filterTitle dAlign">
              <p>discount range</p>
            </div>
            <div className="filterCategories">
              <input className="me-3" type="checkbox" />
              <label>10% and above</label>
            </div>
            <div className="filterCategories">
              <input className="me-3" type="checkbox" />
              <label>20% and above</label>
            </div>
            <div className="filterCategories">
              <input className="me-3" type="checkbox" />
              <label>30% and above</label>
            </div> */}
          </div>
        </section>
      </div>
    </>
  );
};

export default Filter;
