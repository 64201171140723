import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate, NavLink, Link } from "react-router-dom";
import wishListService from "../services/wishlist-service";
import { useDispatch } from "react-redux";
import {
  getWishlistData,
  wishlistDataResponse,
  DeleteWishlist,
  AddCart,
} from "../redux/actions/catagoryAction";
import cartService from "../services/cart-service";
import { toast } from "react-toastify";
import { API_URL } from "../constants/constants";

const Wishlist = (props) => {
  const navigate = useNavigate();
  // const navigateTo = () => navigate('/checkout')
  const [wishlistProducts, setwishlistProducts] = useState([]);
  const cartDataStore = props.cartData || [];
  let productsFromWishlist = props.wishlistProducts || [];
  const token = JSON.parse(localStorage.getItem("userInfo"));
  if (!token) {
    productsFromWishlist = 0;
  }

  const dispatch = useDispatch();

  const refSubmit = useRef();

  const AddCartHandler = async (product) => {
    props.AddCart(product);
    const addedProduct = cartDataStore.find(
      (cartRecord) => cartRecord.id == product?._id
    );

    const cartData = [
      {
        productId: addedProduct?.id,
        quantity: addedProduct?.quantity,
      },
    ];
    refSubmit.current.setAttribute("disabled", true);
    await cartService
      .addProductToCart(cartData)
      .then((res) => {
        toast.success(res.message);
      })
      .catch((err) => {
        console.log(err);
      });

    const removeProduct = {
      productId: product._id,
    };
    await wishListService
      .removeProductFromWishlist(removeProduct)
      .then((res) => {
        getWishlistData();
        props.DeleteWishlist(product);
        // toast.warn(res.message)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getWishlistData = async () => {
    props.getWishlistData();
    await wishListService
      .getProductFromWishlist()
      .then((response) => {
        if (response.status === 200) {
          const responseData = response?.wishlist?.map((item) => {
            return {
              _id: item.productId,
              name: item.productName,
              brand: item.brand,
              image: item.image,
              price: item.price,
            };
          });
          props.wishlistDataResponse(responseData);
        }
      })
      .catch((error) => console.log(error));
  };
  const removeProductFromWishlist = async (product) => {
    const removeProduct = {
      productId: product._id,
    };
    await wishListService
      .removeProductFromWishlist(removeProduct)
      .then((res) => {
        getWishlistData();
        props.DeleteWishlist(product);
        toast.warn(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getWishlistData();
  }, []);

  return (
    <>
      <section>
        <nav className="breadcrumb">
          <div className="container">
            <div className="row">
              <ul>
                <li>
                  <li>
                    <NavLink to="/">
                      Home
                      <span className="ms-3">
                        <i className="fa-solid fa-angle-right"></i>
                      </span>
                    </NavLink>
                  </li>
                </li>
                <li>
                  <li>
                    <NavLink to="/wishlist">Wishlist</NavLink>
                  </li>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="mainContent">
          {/* <div className="container bgContent">
            <div className="row">
              <div className="pageTitle">
                <h1>Wishlist</h1>
                <p>Wishlist</p>
              </div>
            </div>
          </div> */}

          <div className="container bgContent">
            <div className="row">
              {/* <div className="pageTitle">
              <h1>Products</h1>
              <p>Products</p>
            </div> */}
            </div>
            <p
              style={{
                textAlign: "center",
                padding: "50px",
                position: "relative",
              }}
            >
              <span className="cate-heading">Wishlist</span>
              <img
                src="../images/textbanner.png"
                alt=""
                className="cate-image"
              ></img>
            </p>
          </div>

          <div className="container mt-5">
            {productsFromWishlist?.length === 0 && token ? (
              <>
                <div className="ShopNOw">
                  <p style={{ margin: "10px", fontSize: "15px" }}>
                    No items in the Wishlist{" "}
                  </p>
                  <Link
                    style={{ padding: "10px" }}
                    className="btn btn-secondary"
                    to="/products"
                  >
                    Shop Now
                  </Link>
                </div>
              </>
            ) : null}

            {!token ? (
              <>
                <div className="wishlistContent">
                  {" "}
                  <Link
                    // style={{ marginLeft: "30px" }}
                    className="btn btn-secondary"
                    to="/"
                  >
                    Home page
                  </Link>
                  <p className="mb-0">
                    Please login to add products in the wishlist or view
                    wishlist
                  </p>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="wishlistMain">
              {!!productsFromWishlist &&
                productsFromWishlist.map((wishlistProduct) => {
                  return (
                    <>
                      <div className="bwishlist">
                        <div className="plImage">
                          {wishlistProduct?.image?.length ? (
                            wishlistProduct.image.map((img) => (
                              <img
                                key={img.id}
                                src={API_URL + "/" + img?.url}
                                width="100%"
                                height="100%"
                                alt={img.name}
                              ></img>
                            ))
                          ) : (
                            <img
                              src="../images/productListing.png"
                              width="100%"
                              height="100%"
                              alt="dummyimage"
                            ></img>
                          )}
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              removeProductFromWishlist(wishlistProduct)
                            }
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </span>
                        </div>
                        <div className="plInfo">
                          <p>{wishlistProduct.productName}</p>
                          <p>$ {wishlistProduct.price}</p>
                        </div>
                        <div className="plBtns">
                          <button
                            className="ppmBtn"
                            onClick={() => AddCartHandler(wishlistProduct)}
                            ref={refSubmit}
                          >
                            Move To Cart
                          </button>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    isLoggedIn: state?.auth?.isLoggedIn,
    cartData: state?.catagoryReducer?.Carts,
    wishlistProducts: state?.catagoryReducer?.Wishlists,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    AddCart: (product) => dispatch(AddCart(product)),
    getWishlistData: () => dispatch(getWishlistData()),
    wishlistDataResponse: (data) => dispatch(wishlistDataResponse(data)),
    DeleteWishlist: (product) => dispatch(DeleteWishlist(product)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);
