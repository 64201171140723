import { GET_USER_BY_ID, UPDATE_USER } from "./actionTypes";

export const getUserById = (user) => (dispatch) => {
  dispatch({
    type: GET_USER_BY_ID,
    payload: user,
  });
};
export function updateUser(payload) {
  return {
    type: UPDATE_USER,
    payload,
  };
}
