import {
  LOGIN_SUCCESS,

} from "./actionTypes";

export const login = (user) => (dispatch) => {
  dispatch({
    type: LOGIN_SUCCESS,
    payload: { user },
  });
};